import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import useSWR from "swr";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";
import { useParams } from "react-router-dom";

import {
  getAuthToken,
  checkExpiredAuthToken,
  singleDetailsBoxStyle,
} from "../utils";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import NumberFormat from "react-number-format";
import dayjs from "dayjs";
import AccountInformation from "../components/UserDetailsComponents/AccountInformation";
import WalletInformation from "../components/UserDetailsComponents/WalletInformation";
import PlayerInformation from "../components/UserDetailsComponents/PlayerInformation";

function DueRemissions() {
  //   const { userId } = useParams();

  //   const url = `${process.env.API_URL}user/fetch-user/${userId}`;

  //   const fetcher = () => {
  //     checkExpiredAuthToken();
  //     const token = getAuthToken();
  //     return axios
  //       .get(url, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       })
  //       .then((res) => {
  //         // console.log(res?.data?.data?.data);
  //         return res?.data?.data?.data;
  //       });
  //   };
  //   const { data, error } = useSWR(url, fetcher);

  //   if (error)
  //     return (
  //       <FullScreenError
  //         title="Sorry, we can't retrieve the requested data this moment"
  //         subtitle={error.message}
  //       />
  //     );

  //   if (!data) return <FullScreenLoader />;

  //   console.log("dddddddddddddddd", data);
  return (
    <AdminLayout>
      <MetaDecorator title={`Due Remissions- Admin`} />
      <Container maxWidth="xl">
        <PageHeading title="Due Remissions" />
        {/* <Grid container spacing={2}>
          <Grid item xs={12} md={7} lg={8}>
            <PlayerInformation data={data} />
          </Grid>

          <Grid item xs={12} md={5} lg={4}>
            <WalletInformation data={data} />
            <AccountInformation data={data} />
          </Grid>
        </Grid> */}
      </Container>
    </AdminLayout>
  );
}

export default DueRemissions;
