import React from "react";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";
import dayjs from "dayjs";
import useSWR, { mutate } from "swr";
import { getAuthToken, checkExpiredAuthToken } from "../utils";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import ClearFilters from "../components/ClearFilters/ClearFilters";
import TicketsAnalytics from "../components/TicketsComponent/TicketsAnalytics";
import BookedCodesTable from "../components/BookedCodes/BookedCodesTable";
import PreviewBookedCodeModal from "../components/BookedCodes/PreviewBookedCodeModal";
import BookedCodesFilter from "../components/BookedCodes/BookedCodesFilter";

function BookCodes() {
  const [status, setStatus] = React.useState("");
  const [gameTitle, setGameTitle] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [isFiltering, setIsFiltering] = React.useState(false);
  const [hasFiltered, setHasFiltered] = React.useState(false);
  const [selectedTicket, setSelectedTicket] = React.useState({});
  const [openPreviewTicket, setOpenPreviewTicket] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(false);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [minAmount, setMinAmount] = React.useState(0);
  const [maxAmount, setMaxAmount] = React.useState(0);

  let filterURL = `${process.env.API_URL}game/fetch-saved-tickets?limit=${rowsPerPage}&page=${page}`;

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const rowsPerPageOptions = [20, 50, 100, 200];
  // SessionStorage Variable Declarations

  let sStatus = "status";
  let sTicketName = "ticketName";
  let sStartDate = "startDate";
  let sEndDate = "endDate";
  let sMinAmount = "minAmount";
  let sMaxAmount = "maxAmount";

  // change methods for search filters
  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
    sessionStorage.setItem(sStatus, e.target.value);
  };

  const handleChangeGameTitle = (e) => {
    setGameTitle(e.target.value);
    sessionStorage.setItem(sTicketName, e.target.value);
  };

  const handleChangeStartDate = (value) => {
    setStartDate(value);
    sessionStorage.setItem(sStartDate, value);
  };
  const handleChangeEndDate = (value) => {
    setEndDate(value);
    sessionStorage.setItem(sEndDate, value);
  };

  const handleChangeMinimumAmount = (e) => {
    setMinAmount(e.target.value);
    sessionStorage.setItem(sMinAmount, e.target.value);
  };

  const handleChangeMaximumAmount = (e) => {
    setMaxAmount(e.target.value);
    sessionStorage.setItem(sMaxAmount, e.target.value);
  };

  const handleClearSessionStorage = () => {
    sessionStorage.removeItem(sStatus);
    sessionStorage.removeItem(sTicketName);
    sessionStorage.removeItem(sStartDate);
    sessionStorage.removeItem(sEndDate);
    sessionStorage.removeItem(sMinAmount);
    sessionStorage.removeItem(sMaxAmount);
  };

  const handleSetSessionStorage = () => {
    let ssStatus = sessionStorage.getItem(sStatus);
    if (ssStatus) {
      setStatus(ssStatus);
      setHasFiltered(true);
    }
    let ssTicketName = sessionStorage.getItem(sTicketName);
    if (ssTicketName) {
      setGameTitle(ssTicketName);
      setHasFiltered(true);
    }

    let ssStartDate = sessionStorage.getItem(sStartDate);
    if (ssStartDate) {
      setStartDate(ssStartDate);
      setHasFiltered(true);
    }
    let ssEndDate = sessionStorage.getItem(sEndDate);
    if (ssEndDate) {
      setEndDate(ssEndDate);
      setHasFiltered(true);
    }
    let ssMinAmount = sessionStorage.getItem(sMinAmount);
    if (ssMinAmount) {
      setMinAmount(ssMinAmount);
      setHasFiltered(true);
    }
    let ssMaxAmount = sessionStorage.getItem(sMaxAmount);
    if (ssMaxAmount) {
      setMaxAmount(ssMaxAmount);
      setHasFiltered(true);
    }
    // Updating URL

    if (status !== "") {
      if (!filterURL.includes(sStatus)) {
        filterURL = filterURL + `&status=${status}`;
      }
    }

    if (minAmount) {
      if (!filterURL.includes(sMinAmount)) {
        filterURL = filterURL + `&minAmount=${minAmount}`;
      }
    }
    if (maxAmount) {
      if (!filterURL.includes(sMaxAmount)) {
        filterURL = filterURL + `&maxAmount=${maxAmount}`;
      }
    }
    if (gameTitle) {
      if (!filterURL.includes(sTicketName)) {
        filterURL = filterURL + `&search=${gameTitle}`;
      }
    }
    if (startDate) {
      if (!filterURL.includes(sStartDate)) {
        filterURL =
          filterURL + `&startDate=${dayjs(startDate).format("DD/MM/YYYY")}`;
      }
    }
    if (endDate) {
      if (!filterURL.includes(sEndDate)) {
        filterURL =
          filterURL + `&endDate=${dayjs(endDate).format("DD/MM/YYYY")}`;
      }
    }
  };

  const handleChangeFilter = (event) => setShowFilter(event.target.checked);

  React.useEffect(() => {
    handleSetSessionStorage();

    return () => {
      handleClearSessionStorage();
    };
  }, []);

  const handleSelectTicket = (ticket) => setSelectedTicket(ticket);
  const handleClosePreviewTicket = () => setOpenPreviewTicket(false);
  const handleOpenPreviewTicket = () => setOpenPreviewTicket(true);

  const url = `${process.env.API_URL}game/fetch-saved-tickets?limit=${rowsPerPage}&page=${page}`;

  const handleResetFields = () => {
    handleClearSessionStorage();
    setStatus("");
    setGameTitle("");
    setStartDate(null);
    setEndDate(null);
    setMinAmount(0);
    setMaxAmount(0);
  };

  const fetcher = () => {
    handleSetSessionStorage();
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data);
        return res?.data?.data;
      });
  };

  const handleFilterSubmit = () =>
    mutate(filterURL, async () => {
      try {
        handleSetSessionStorage();
        checkExpiredAuthToken();
        const token = getAuthToken();
        setPage(1);
        setIsFiltering(true);
        const res = await axios.get(filterURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setIsFiltering(false);
        setHasFiltered(true);
        // await mutate(filterURL, res.data?.data);
        return res.data?.data;
      } catch (err) {
        setIsFiltering(false);
        return err;
      }
    });

  const handleChangePage = (event, newPage) => {
    setIsFiltering(true);
    handleSetSessionStorage();
    setPage(newPage + 1);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsFiltering(false);
        mutate(filterURL, res?.data?.data);
      })
      .catch((err) => {
        setIsFiltering(false);
        console.log(err);
      });
  };
  const handleCancelFilters = () =>
    mutate(url, async () => {
      try {
        handleResetFields();
        setHasFiltered(false);
        checkExpiredAuthToken();
        const token = getAuthToken();
        let res = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        return res.data?.data;
      } catch (error) {
        console.log(err);
      }
    });

  const { data, error } = useSWR(filterURL, fetcher, {
    // revalidateIfStale: false,
    // revalidateOnMount: false,
    dedupingInterval: 70000,
  });

  if (error)
    return (
      <FullScreenError
        title="Sorry, we cannot retrieve data at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  return (
    <AdminLayout>
      <MetaDecorator title="Booked Codes Table - Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Booked Codes" />
        {openPreviewTicket ? (
          <PreviewBookedCodeModal
            openDialog={openPreviewTicket}
            selectedTicket={selectedTicket}
            handleCloseDialog={handleClosePreviewTicket}
          />
        ) : null}
        {/* <TicketsAnalytics /> */}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <>
            {hasFiltered && <ClearFilters handleClick={handleCancelFilters} />}
          </>
          <FormControlLabel
            sx={{ marginLeft: "auto" }}
            control={
              <Switch
                color="success"
                checked={showFilter}
                onChange={handleChangeFilter}
                inputProps={{ "aria-label": "Show Filters" }}
              />
            }
            label="Show Filters"
          />
        </Box>
        {showFilter && (
          <BookedCodesFilter
            status={status}
            gameTitle={gameTitle}
            startDate={startDate}
            endDate={endDate}
            minAmount={minAmount}
            maxAmount={maxAmount}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
            handleChangeMinimumAmount={handleChangeMinimumAmount}
            handleChangeMaximumAmount={handleChangeMaximumAmount}
            handleChangeStatus={handleChangeStatus}
            handleChangeGameTitle={handleChangeGameTitle}
            handleFilterSubmit={handleFilterSubmit}
          />
        )}

        <BookedCodesTable
          data={data?.data}
          selectedTicket={selectedTicket}
          hasFiltered={hasFiltered}
          isFiltering={isFiltering}
          handleChangePage={handleChangePage}
          handleSelectTicket={handleSelectTicket}
          handleOpenPreviewTicket={handleOpenPreviewTicket}
          page={page}
          totalCount={data?.totalCount}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Container>
    </AdminLayout>
  );
}

export default BookCodes;
