import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import useSWR from "swr";
import axios from "axios";
import { MdOutlineAdminPanelSettings, MdThumbUp } from "react-icons/md";
import { AiFillDislike } from "react-icons/ai";
import { RiDeleteBackFill } from "react-icons/ri";
import { checkExpiredAuthToken, getAuthToken } from "../../utils";
import "../../styles/analytics.scss";
import HalfErrorScreen from "../HalfErrorScreen/HalfErrorScreen";
import AnalyticsCard from "../AnalyticsCard/AnalyticsCard";

const url = `${process.env.API_URL}admin/analytics`;

function AdminsAnalytics() {
  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };

  const { data, error } = useSWR(url, fetcher, {});

  if (error)
    return (
      <HalfErrorScreen
        title=" Sorry, admins analytics data cannot be retrieved at this momment"
        subtitle={error.message}
      />
    );

  if (!data) return null;
  return (
    <Box mb={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={3}>
          <AnalyticsCard
            mainNumber={data && data?.totalAdmins}
            icon={<MdOutlineAdminPanelSettings className="cardIcon" />}
            text={data?.totalAdmins <= 1 ? "Admin" : "Total Admins"}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <AnalyticsCard
            mainNumber={data && data?.suspendedAdmins}
            icon={<AiFillDislike className="cardIcon" />}
            text={
              data && data?.suspendedAdmins <= 1
                ? "Suspended Admin"
                : "Suspended Admins"
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <AnalyticsCard
            mainNumber={data && data?.totalAdmins - data?.suspendedAdmins}
            icon={<MdThumbUp className="cardIcon" />}
            text={
              data && data?.totalAdmins - data?.suspendedAdmins <= 1
                ? "Active Admin"
                : "Active Admins"
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <AnalyticsCard
            error={true}
            mainNumber={data && data?.deletedAdmins}
            icon={<RiDeleteBackFill className="errorCardIcon" />}
            text={
              data && data?.deletedAdmins <= 1
                ? "Deleted Admin"
                : "Deleted Admins"
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default AdminsAnalytics;
