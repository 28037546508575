import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import { Formik, Form } from "formik";
import * as yup from "yup";
import axios from "axios";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import { labelStyles, getAuthToken, checkExpiredAuthToken } from "../utils";
import { AppContext } from "../context";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";

let initialValues = {
  name: "",
};
let clientSchema = yup.object().shape({
  name: yup.string().required().label("Name"),
});

function EditClient() {
  const { handleOpenSnackbar } = React.useContext(AppContext);
  const { clientId } = useParams();

  const adminURL = `${process.env.API_URL}tenant/fetch-tenant/${clientId}`;

  const fetchInitialData = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(adminURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.data?.data);
        return res?.data?.data?.data;
      });
  };

  const { data, error } = useSWR(adminURL, fetchInitialData);

  if (data) {
    initialValues.name = data?.name;
  }

  if (error)
    return (
      <FullScreenError
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  const handleSubmit = (values, helpers) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    axios
      .put(`${process.env.API_URL}tenant/update-tenant/${clientId}`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        helpers.resetForm({});
        helpers.setSubmitting(false);
        handleOpenSnackbar({
          message: "Client Details Updated Successfully",
          severity: "success",
        });
      })
      .catch((err) => {
        console.log(err?.response?.data?.responsemessage?.msg);
        helpers.setSubmitting(false);
        if (err?.response?.data?.responsemessage?.msg) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage?.msg,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, Client Could Not Be Updated",
          severity: "error",
        });
      });
  };
  return (
    <AdminLayout>
      <MetaDecorator title="Update Client - Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Update Client Details" />

        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={clientSchema}
            enableReinitialize={true}
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={(values, helpers) => handleSubmit(values, helpers)}
          >
            {({
              handleChange,
              handleBlur,
              touched,
              errors,
              setFieldValue,
              isSubmitting,
              values,
            }) => (
              <Form noValidate>
                <Box my={1}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel variant="outlined" sx={labelStyles}>
                      Enter the Name
                    </InputLabel>
                    <TextField
                      error={touched.name && errors.name}
                      value={values.name}
                      name="name"
                      variant="outlined"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.name && errors.name}
                    />
                  </FormControl>
                </Box>
                {/* <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStyles}>
                        Enter the last name
                      </InputLabel>
                      <TextField
                        value={values.lastname}
                        error={touched.lastname && errors.lastname}
                        name="lastname"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.lastname && errors.lastname}
                      />
                    </FormControl>
                  </Box> */}

                <br />
                <Button
                  startIcon={
                    isSubmitting && (
                      <CircularProgress
                        style={{ color: "white" }}
                        size={"1rem"}
                      />
                    )
                  }
                  type="submit"
                  size="large"
                  variant="contained"
                  color="success"
                >
                  {isSubmitting ? "Submitting" : " Update Client"}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </AdminLayout>
  );
}

export default EditClient;
