import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Chip from "@mui/material/Chip";
import {
  boldTextCalculationStyles,
  getTransactionSum,
  menuItemsStyles,
} from "../../../utils";
import dayjs from "dayjs";
import TableLoader from "../../TableLoader/TableLoader";
import NoDataAvailable from "../../EmptyTable/EmptyTable";
import { useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";
import TableCSVButton from "../../TableCSVButton/TableCSVButton";

function TransactionsTable({
  totalCount,
  isFiltering,
  handleChangePage,
  page,
  data,
  hasFiltered,
  handleSelectTransaction,
  handleOpenReverseFundsDialog,
  rowsPerPage,
  rowsPerPageOptions,
  handleChangeRowsPerPage,
  handleOpenDialog,
}) {
  const [selectedId, setSelectedId] = React.useState(""); // For user ID when the more icon is clicked
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();

  const open = Boolean(anchorEl);

  const handleClick = (event, transaction) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(transaction?.transactionId);
    handleSelectTransaction(transaction);
  };
  const renderStatus = (gameStatus) => {
    if (gameStatus === "success") {
      return <Chip color="success" label={gameStatus} size="small" />;
    }
    if (gameStatus === "reversed") {
      return <Chip color="warning" label={gameStatus} size="small" />;
    }

    return <Chip color="error" label={gameStatus} size="small" />;
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedId("");
  };
  const handleViewTransaction = () => {
    return history.push(`transactions/transaction-details/${selectedId}`);
  };

  const headCells = [
    "Player",
    "Transaction ID",
    "Amount",
    "Transaction Type",
    "Reference ID",
    "Provider",
    "Date Created",
    "Status",
    "Actions",
  ];
  const headers = [
    "Player",
    "Transaction ID",
    "Amount",
    "Transaction Type",
    "Reference ID",
    "Provider",
    "Date Created",
    "Status",
  ];
  let rows = [];
  if (data?.length > 0) {
    data?.forEach((item) => {
      rows.push([
        `${item?.User?.firstname} ${item?.User?.lastname}`,
        item?.transactionId,
        item?.amount,
        item?.transactionType,
        item?.referenceId,
        item?.provider,
        item?.createdAt,
        item?.status,
      ]);
    });
    rows = [headers, ...rows];
  }
  return isFiltering ? (
    <TableLoader />
  ) : (
    <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
      {data?.length > 0 ? (
        <TableCSVButton data={rows} filename="transactions.csv" />
      ) : null}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="recent users table">
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell key={item} component="th" sx={{ fontWeight: 600 }}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.length > 0 &&
              data?.map((row) => (
                <TableRow key={row?.transactionId}>
                  <TableCell>
                    {row?.User?.firstname} &nbsp; {row?.User?.lastname}
                  </TableCell>
                  <TableCell>{row?.transactionId}</TableCell>
                  <TableCell>
                    <NumberFormat
                      value={row?.amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"NGN"}
                      renderText={(value, props) => (
                        <Typography align="left" {...props}>
                          {value}
                        </Typography>
                      )}
                    />
                  </TableCell>
                  <TableCell>{row?.transactionType}</TableCell>
                  <TableCell>{row?.referenceId}</TableCell>
                  <TableCell>{row?.provider}</TableCell>
                  <TableCell style={{ width: 160 }}>
                    {dayjs(row.createdAt).format("hh:mm, MMM D YYYY")}
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                    {renderStatus(row.status)}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => handleClick(e, row)}>
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      PaperProps={{
                        elevation: 1,
                        sx: { boxShadow: "2px 2px 4px #eee" },
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => handleOpenDialog()}
                        sx={menuItemsStyles}
                      >
                        Preview Transaction
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => handleOpenReverseFundsDialog()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Reverse Transaction
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => handleViewTransaction()}
                        sx={menuItemsStyles}
                      >
                        View Transaction
                      </MenuItem>
                      <Divider />
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            {data && data?.length > 0 && (
              <TableRow>
                <TableCell sx={boldTextCalculationStyles} colSpan={2}>
                  Total
                </TableCell>

                <TableCell align="right">
                  <NumberFormat
                    value={getTransactionSum(data)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"NGN"}
                    renderText={(value, props) => (
                      <Typography
                        sx={boldTextCalculationStyles}
                        align="left"
                        {...props}
                      >
                        {value}
                      </Typography>
                    )}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

export default TransactionsTable;
