import React from "react";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
// import axios from "axios"
// import useSWR from "swr"
import { labelStyles } from "../../utils";

function PayoutsFilter({
  transactionName,
  transactionType,
  status,
  startDate,
  endDate,
  minAmount,
  maxAmount,
  handleChangeStartDate,
  handleChangeEndDate,
  handleChangeMinimumBal,
  handleChangeMaximumBal,
  handleChangeStatus,
  handleChangeTicketName,
  handleChangeTransactionType,
  handleFiterSubmit,
}) {
  return (
    <Box mb={3}>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={12} sm={5} md={3}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Enter Transaction ID
            </InputLabel>
            <TextField
              variant="outlined"
              size="small"
              value={transactionName}
              fullWidth
              onChange={(e) => handleChangeTicketName(e)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Minimum Transaction Amount
            </InputLabel>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              value={minAmount}
              fullWidth
              onChange={(e) => handleChangeMinimumBal(e)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Maximum Transaction Amount
            </InputLabel>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              value={maxAmount}
              fullWidth
              onChange={(e) => handleChangeMaximumBal(e)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5} md={3}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Status
            </InputLabel>
            <Select
              labelId="statusSelect"
              variant="outlined"
              id="statusSelect"
              value={status}
              onChange={(e) => handleChangeStatus(e)}
            >
              <MenuItem value={""}>Select</MenuItem>
              <MenuItem value="success">Success</MenuItem>
              <MenuItem value="failed">Failed</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="roleSelect" sx={labelStyles}>
              Transaction Type
            </InputLabel>
            <Select
              labelId="roleSelect"
              variant="outlined"
              id="roleSelect"
              value={transactionType}
              onChange={(e) => handleChangeTransactionType(e)}
            >
              <MenuItem value="">Select</MenuItem>
              <MenuItem value="charge">Charge</MenuItem>
              <MenuItem value="deposit">Deposit</MenuItem>
              <MenuItem value="withdrawal">Withdrawal</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Date Created (From)
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                size="small"
                value={startDate}
                onChange={(value) => {
                  handleChangeStartDate(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth size="small" />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Date Created ( To )
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                size="small"
                // label="Date Joined"
                value={endDate}
                onChange={(value) => {
                  handleChangeEndDate(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth size="small" />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2} md={3}>
          <Button
            size="large"
            variant="contained"
            endIcon={<SearchIcon />}
            color="success"
            onClick={() => handleFiterSubmit()}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PayoutsFilter;
