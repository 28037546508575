import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { formatGameDate } from "../../utils";

function GameInformation({ data, itemBoxStyle }) {
  return (
    <Grid item xs={12} md={5}>
      <Typography gutterBottom variant="h6" sx={{ fontWeight: 500 }}>
        Game Information
      </Typography>
      <Box py={2}>
        <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
          <Typography color="text.secondary">Name:</Typography>
          <Typography align="left">{data?.Game?.name}</Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
          <Typography color="text.secondary">Category:</Typography>
          <Typography align="left">{data?.Game?.lotteryName}</Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
          <Typography color="text.secondary">Start Time:</Typography>
          <Typography align="left">
            {formatGameDate(data?.Game?.startTime, true)}
          </Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
          <Typography color="text.secondary">End Time:</Typography>
          <Typography align="left">
            {formatGameDate(data?.Game?.endTime, true)}
          </Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
          <Typography color="text.secondary">Draw Method:</Typography>
          <Typography align="left">{data?.Game?.drawMethod}</Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
          <Typography color="text.secondary">Weekday:</Typography>
          <Typography align="left">{data?.Game?.weekday}</Typography>
        </Box>
        <Box mb={1} pb={1} pr={2}>
          <Typography color="text.secondary">Description:</Typography>
          <Typography align="left">{data?.Game?.description}</Typography>
        </Box>

        <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
          <Typography color="text.secondary">Date Created:</Typography>
          <Typography align="left">
            {dayjs(data?.Game?.createdAt).format("D MMM YYYY, hh:ss a")}
          </Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={itemBoxStyle}>
          <Typography color="text.secondary">Date Last Updated:</Typography>
          <Typography align="left">
            {dayjs(data?.Game?.updatedAt).format("D MMM YYYY, hh:ss a")}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
}

export default GameInformation;
