import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircularProgress from "@mui/material/CircularProgress";
import { Formik, Form } from "formik";
import * as yup from "yup";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { useParams } from "react-router-dom";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import { labelStyles, getAuthToken, checkExpiredAuthToken } from "../utils";
import { AppContext } from "../context";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import FormLoader from "../components/FormLoader/FormLoader";

let initialValues = {
  games: [],
};
let clientSchema = yup.object().shape({
  games: yup.array().required().label("Games"),
});

function AttachClientGames() {
  const [searchField, setsearchField] = useState("");
  const [gamesArray, setGamesArray] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [hasSearchedGames, setHasSearchedGames] = useState(false);
  const { handleOpenSnackbar } = useContext(AppContext);
  const { clientId } = useParams();

  const url = `${
    process.env.API_URL
  }game/fetch-games?limit=${100}&search=${searchField}`;
  const clientUrl = `${process.env.API_URL}tenant/fetch-single-tenant/${clientId}`;

  const fetchInitialData = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(clientUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.data?.data);
        return res?.data?.data?.data;
      });
  };

  const { data, error } = useSWR(clientUrl, fetchInitialData);

  if (data) {
    // initialValues.name = data?.name;
  }

  if (error)
    return (
      <FullScreenError
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  const handleSubmit = (values, helpers) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    values.tenantId = parseInt(clientId);
    axios
      .post(`${process.env.API_URL}tenant/associate-games`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        // console.log(res.data);
        helpers.resetForm({});
        helpers.setSubmitting(false);
        await mutate(clientUrl);
        handleOpenSnackbar({
          message: "Client Details Updated Successfully",
          severity: "success",
        });
      })
      .catch((err) => {
        console.log(err?.response?.data?.responsemessage?.msg);
        helpers.setSubmitting(false);
        if (err?.response?.data?.responsemessage?.msg) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage?.msg,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, Game Could Not Be Added",
          severity: "error",
        });
      });
  };
  const searchGamesData = async () => {
    try {
      setIsSearching(true);
      checkExpiredAuthToken();
      const token = getAuthToken();

      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setGamesArray(res?.data?.data?.data);
      setIsSearching(false);
      setHasSearchedGames(true);
      // console.log("ff", gamesArray);
    } catch (err) {
      setIsSearching(false);
      return err;
    }
  };
  return (
    <AdminLayout>
      <MetaDecorator title="Attach Games To Client - Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Attach Games To Client" />

        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={clientSchema}
            enableReinitialize={true}
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={(values, helpers) => handleSubmit(values, helpers)}
          >
            {({
              handleChange,
              handleBlur,
              touched,
              errors,
              setFieldValue,
              isSubmitting,
              values,
            }) => (
              <Form noValidate>
                <Box my={2}>
                  <Typography gutterBottom>
                    Search for a game or click on the search button without any
                    text to get the most recent 100 games, select a game from
                    the result and complete filling the form, and click on the
                    Add Bonus button
                  </Typography>
                  <Box mt={1} mb={2}>
                    {data?.Games && data?.Games?.length > 0 ? (
                      <Box>
                        <Typography fontWeight={500} gutterBottom>
                          Attached games are:
                        </Typography>
                        <Stack spacing={2} direction={"row"}>
                          {data?.Games?.map((item) => (
                            <Chip
                              label={item?.name}
                              key={item?.gameId}
                              variant="filled"
                              color="success"
                            />
                          ))}
                        </Stack>
                      </Box>
                    ) : (
                      <Typography gutterBottom>
                        There is no attached game currently to this client
                      </Typography>
                    )}
                  </Box>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={10}>
                      <TextField
                        variant="outlined"
                        placeholder="Enter Game Title"
                        size="small"
                        fullWidth
                        onChange={(e) => setsearchField(e?.target?.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button
                        size="large"
                        color="success"
                        disabled={isSearching}
                        variant="contained"
                        type="button"
                        onClick={() => searchGamesData()}
                      >
                        {isSearching ? "Searching" : "Search"}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>

                <Box my={1}>
                  {hasSearchedGames ? (
                    <Box>
                      {gamesArray && gamesArray?.length > 0 ? (
                        <Grid container spacing={1}>
                          {gamesArray.map((item, idx) => (
                            <Grid key={item?.gameId} item xs={6} sm={3} md={2}>
                              <FormControlLabel
                                value={values.games}
                                control={
                                  <Checkbox
                                    color="success"
                                    name="games"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={item?.gameId}
                                  />
                                }
                                label={item?.name}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <Typography>
                          Sorry, no game match your search, search for a new
                          game again
                        </Typography>
                      )}
                    </Box>
                  ) : isSearching ? (
                    <FormLoader />
                  ) : null}
                  <br />
                </Box>
                {/* <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStyles}>
                        Enter the last name
                      </InputLabel>
                      <TextField
                        value={values.lastname}
                        error={touched.lastname && errors.lastname}
                        name="lastname"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.lastname && errors.lastname}
                      />
                    </FormControl>
                  </Box> */}

                <br />
                <Button
                  startIcon={
                    isSubmitting && (
                      <CircularProgress
                        style={{ color: "white" }}
                        size={"1rem"}
                      />
                    )
                  }
                  type="submit"
                  size="large"
                  variant="contained"
                  color="success"
                >
                  {isSubmitting ? "Submitting" : " Add Games"}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </AdminLayout>
  );
}

export default AttachClientGames;
