import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import { safeJSONParse } from "../../utils";

function GameOptions({ data }) {
  let resultOptions = safeJSONParse(data?.resultOptions) || [];
  let boosterOptions = safeJSONParse(data?.boosterOptions);
  let betOptions = safeJSONParse(data?.betOptions) || [];
  let overOptions = safeJSONParse(data?.overOptions) || [];
  let underOptions = safeJSONParse(data?.underOptions) || [];
  return (
    <Box>
      <Box py={1}>
        {boosterOptions &&
          boosterOptions !== null &&
          boosterOptions?.length > 0 && (
            <Box mb={1} pb={1} pr={2}>
              <Typography gutterBottom>Booster Options:</Typography>
              <Grid container spacing={2}>
                {boosterOptions?.map((item) => (
                  <Grid key={item} item xs={6} sm={3} md={2}>
                    <Chip label={item} variant="outlined" />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
      </Box>
      <Box py={1}>
        {betOptions && betOptions !== null && betOptions?.length > 0 && (
          <Box mb={1} pb={1} pr={2}>
            <Typography gutterBottom>Bet Options/Multiplier:</Typography>
            <Grid container spacing={2}>
              {betOptions?.map((item) => (
                <Grid key={`${item?.name}89`} item xs={6} sm={3} xl={2}>
                  <Chip
                    label={`${item?.name} / ${item?.multiplier}`}
                    variant="outlined"
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Box>
      <Box py={1}>
        {overOptions && overOptions !== null && overOptions?.length > 0 && (
          <Box mb={1} pb={1} pr={2}>
            <Typography gutterBottom>Over Options:</Typography>
            <Grid container spacing={2}>
              {typeof overOptions === "string" ? (
                <Typography>{overOptions}</Typography>
              ) : (
                overOptions?.map((item) => (
                  <Grid key={item?.name} item xs={6} sm={3} md={2}>
                    <Chip
                      label={item?.name}
                      variant="outlined"
                      sx={{ marginRight: 2 }}
                    />
                  </Grid>
                ))
              )}
            </Grid>
          </Box>
        )}
      </Box>
      <Box py={1}>
        {underOptions && underOptions !== null && underOptions?.length > 0 && (
          <Box mb={1} pb={1} pr={2}>
            <Typography gutterBottom>Under Options:</Typography>
            <Grid container spacing={2}>
              {typeof underOptions === "string" ? (
                <Typography>{underOptions}</Typography>
              ) : (
                underOptions?.map((item) => (
                  <Grid key={item?.name} item xs={6} sm={3} md={2}>
                    <Chip label={item?.name} variant="outlined" />
                  </Grid>
                ))
              )}
            </Grid>
          </Box>
        )}
      </Box>
      <Box py={1}>
        {resultOptions && resultOptions !== null && resultOptions?.length > 0 && (
          <Box mb={1} pb={1} pr={2}>
            <Typography gutterBottom>Result Options:</Typography>
            <Grid container spacing={2}>
              {typeof resultOptions === "string" ? (
                <Typography>{resultOptions}</Typography>
              ) : (
                resultOptions?.map((item) => (
                  <Grid key={item} item xs={6} sm={3}>
                    <Chip label={item} variant="outlined" />
                  </Grid>
                ))
              )}
            </Grid>
          </Box>
        )}
      </Box>{" "}
    </Box>
  );
}

export default GameOptions;
