import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { mutate } from "swr";
import useSWRImmutable from "swr/immutable";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";

import {
  getAuthToken,
  checkExpiredAuthToken,
  singleDetailsBoxStyle,
  limit,
} from "../utils";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import NumberFormat from "react-number-format";
import dayjs from "dayjs";
import GameConfigTable from "../components/GameConfigComponents/GameConfigTable";
import PreviewGameConfigModal from "../components/GameConfigComponents/PreviewGameConfigModal";
import EditConfigDialog from "../components/GameConfigComponents/EditConfigDialog";

function GameConfigurations() {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [openEditConfigDialog, setOpenEditConfigDialog] = React.useState(false);
  const [openPreview, setOpenPreview] = React.useState(false);
  const [selectedGame, setSelectedGame] = React.useState({});

  const handleOpenPreviewConfig = () => setOpenPreview(true);
  const handleClosePreviewConfig = () => setOpenPreview(false);
  const handleOpenEditConfig = () => setOpenEditConfigDialog(true);
  const handleCloseEditConfig = () => setOpenEditConfigDialog(false);
  const handleSelectGame = (game) => setSelectedGame(game);

  const filterURL = `${process.env.API_URL}game/fetch-lotteries?limit=${rowsPerPage}&page=${page}`;

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const rowsPerPageOptions = [20, 50, 100, 200];

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(
        `${process.env.API_URL}game/fetch-lotteries?limit=${rowsPerPage}&page=${
          page + 1
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        mutate(filterURL, res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.data?.data);
        return res?.data?.data;
      });
  };
  const { data, error } = useSWRImmutable(filterURL, fetcher);

  if (error)
    return (
      <FullScreenError
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  console.log("dddddddddddddddd", data);
  return (
    <AdminLayout>
      <MetaDecorator title={`Game Configurations- Admin`} />
      <Container maxWidth="xl">
        <PageHeading title="Game Configurations" />
        {openEditConfigDialog && (
          <EditConfigDialog
            configURL={filterURL}
            selectedGame={selectedGame}
            openDialog={openEditConfigDialog}
            handleCloseDialog={handleCloseEditConfig}
          />
        )}
        {openPreview && (
          <PreviewGameConfigModal
            selectedGame={selectedGame}
            openDialog={openPreview}
            handleCloseDialog={handleClosePreviewConfig}
          />
        )}
        <GameConfigTable
          selectedGame={selectedGame}
          rowsPerPage={rowsPerPage}
          page={page}
          rowsPerPageOptions={rowsPerPageOptions}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          data={data?.data}
          totalCount={data?.totalCount}
          handleChangePage={handleChangePage}
          handleSelectGame={handleSelectGame}
          handleOpenPreviewConfig={handleOpenPreviewConfig}
          handleOpenEditConfig={handleOpenEditConfig}
        />
      </Container>
    </AdminLayout>
  );
}

export default GameConfigurations;
