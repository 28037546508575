import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import { Formik, Form } from "formik";
import * as yup from "yup";
import axios from "axios";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import { getAuthToken, checkExpiredAuthToken } from "../utils";
import { AppContext } from "../context";

const initialValues = {
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  password: "",
  confirmPassword: "",
  role: "influencer",
};

let influencerSchema = yup.object().shape({
  firstname: yup.string().required().label("First name"),
  lastname: yup.string().required().label("Last name"),
  email: yup.string().email().required().label("Email"),
  phone: yup.string().required().label("Phone"),
  password: yup.string().required().label("Password"),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref("password"), null], "Both passwords must match")
    .label("Confirm Password"),
});

const url = `${process.env.API_URL}fetch-roles?userType=admin`;

function AddInfluencer() {
  const { handleOpenSnackbar } = React.useContext(AppContext);

  const handleSubmit = (values, helpers) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    axios
      .post(`${process.env.API_URL}auth/signup`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        helpers.resetForm(initialValues);
        helpers.resetForm();
        helpers.setSubmitting(false);
        handleOpenSnackbar({
          message: "Influencer Account Created Successfully",
          severity: "success",
        });
      })
      .catch((err) => {
        // console.log(err?.response?.data?.responsemessage?.msg);
        helpers.setSubmitting(false);
        if (err?.response?.data?.responsemessage?.msg) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage?.msg,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, Account Could Not Be Created",
          severity: "error",
        });
      });
  };
  return (
    <AdminLayout>
      <MetaDecorator title="Add New Influencer - Admin Dashboard" />
      <Container maxWidth="xl">
        <PageHeading title="Add Influencer" />
        <>
          <div>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={influencerSchema}
              validateOnBlur={true}
              validateOnChange={true}
              onSubmit={(values, helpers) => handleSubmit(values, helpers)}
            >
              {({
                handleChange,
                handleBlur,
                touched,
                errors,
                setFieldValue,
                isSubmitting,
                values,
              }) => (
                <Form noValidate>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        label="Enter the first name"
                        error={touched.firstname && errors.firstname}
                        value={values.firstname}
                        name="firstname"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.firstname && errors.firstname}
                      />
                    </FormControl>
                  </Box>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        label="Enter the last name"
                        value={values.lastname}
                        error={touched.lastname && errors.lastname}
                        name="lastname"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.lastname && errors.lastname}
                      />
                    </FormControl>
                  </Box>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        label="Enter the email"
                        error={touched.email && errors.email}
                        value={values.email}
                        name="email"
                        type="email"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.email && errors.email}
                      />
                    </FormControl>
                  </Box>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        label="Enter Phone Number"
                        value={values.phone}
                        error={touched.phone && errors.phone}
                        name="phone"
                        type="phone"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.phone && errors.phone}
                      />
                    </FormControl>
                  </Box>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        value={values.password}
                        label="Enter the password"
                        error={touched.password && errors.password}
                        name="password"
                        type="password"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.password && errors.password}
                      />
                    </FormControl>
                  </Box>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        label="Confirm the password"
                        error={
                          touched.confirmPassword && errors.confirmPassword
                        }
                        name="confirmPassword"
                        value={values.confirmPassword}
                        type="password"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={
                          touched.confirmPassword && errors.confirmPassword
                        }
                      />
                    </FormControl>
                  </Box>

                  <br />
                  <Button
                    startIcon={
                      isSubmitting && (
                        <CircularProgress
                          style={{ color: "white" }}
                          size={"1rem"}
                        />
                      )
                    }
                    type="submit"
                    size="large"
                    variant="contained"
                    color="success"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting" : " Add Influencer"}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </>
      </Container>
    </AdminLayout>
  );
}

export default AddInfluencer;
