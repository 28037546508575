import React, { useContext } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import RadioGroup from "@mui/material/RadioGroup";
import Container from "@mui/material/Container";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircularProgress from "@mui/material/CircularProgress";
import { Formik, Form } from "formik";
import * as yup from "yup";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { useParams } from "react-router-dom";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import { getAuthToken, checkExpiredAuthToken } from "../utils";
import { AppContext } from "../context";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";

let initialValues = {
  gameId: "",
};
let clientSchema = yup.object().shape({
  gameId: yup.string().required().label("Games"),
});

function DettachClientGames() {
  const { handleOpenSnackbar } = useContext(AppContext);
  const { clientId } = useParams();

  const clientUrl = `${process.env.API_URL}tenant/fetch-single-tenant/${clientId}`;

  const fetchInitialData = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(clientUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.data?.data);
        return res?.data?.data?.data;
      });
  };

  const { data, error } = useSWR(clientUrl, fetchInitialData);

  if (data) {
    // initialValues.name = data?.name;
  }

  if (error)
    return (
      <FullScreenError
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  const handleSubmit = (values, helpers) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    values.tenantId = parseInt(clientId);
    axios
      .post(`${process.env.API_URL}tenant/deassociate-game`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        // console.log(res.data);
        helpers.resetForm({});
        helpers.setSubmitting(false);
        await mutate(clientUrl);
        handleOpenSnackbar({
          message: "Client Details Updated Successfully",
          severity: "success",
        });
      })
      .catch((err) => {
        console.log(err?.response?.data?.responsemessage?.msg);
        helpers.setSubmitting(false);
        if (err?.response?.data?.responsemessage?.msg) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage?.msg,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, Game Could Not Be Dettach",
          severity: "error",
        });
      });
  };

  return (
    <AdminLayout>
      <MetaDecorator title="Dettach Games from Client - Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Dettach Games from Client" />

        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={clientSchema}
            enableReinitialize={true}
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={(values, helpers) => handleSubmit(values, helpers)}
          >
            {({
              handleChange,
              handleBlur,
              touched,
              errors,
              setFieldValue,
              isSubmitting,
              values,
            }) => (
              <Form noValidate>
                <Box my={1}>
                  {data?.Games && data?.Games?.length > 0 ? (
                    <Box>
                      <RadioGroup name="gameId" spacing={2} row>
                        {data?.Games.map((item, idx) => (
                          <FormControlLabel
                            key={item?.gameId}
                            color="success"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={item?.gameId}
                            control={<Radio />}
                            label={item?.name}
                          />
                        ))}
                      </RadioGroup>
                    </Box>
                  ) : (
                    <Typography>
                      There are no games attached to this client data
                    </Typography>
                  )}
                  <br />
                </Box>
                {/* <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStyles}>
                        Enter the last name
                      </InputLabel>
                      <TextField
                        value={values.lastname}
                        error={touched.lastname && errors.lastname}
                        name="lastname"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.lastname && errors.lastname}
                      />
                    </FormControl>
                  </Box> */}

                <br />
                <Button
                  startIcon={
                    isSubmitting && (
                      <CircularProgress
                        style={{ color: "white" }}
                        size={"1rem"}
                      />
                    )
                  }
                  type="submit"
                  size="large"
                  variant="contained"
                  color="success"
                >
                  {isSubmitting ? "Submitting" : " Detach Game"}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </AdminLayout>
  );
}

export default DettachClientGames;
