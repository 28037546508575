import * as React from "react";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import useSWR from "swr";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  getAuthToken,
  checkExpiredAuthToken,
  singleDetailsBoxStyle,
} from "../utils";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import dayjs from "dayjs";

function AdminDetails() {
  const { adminId } = useParams();

  const url = `${process.env.API_URL}admin/fetch-profile/${adminId}`;

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.data?.data);
        return res?.data?.data?.data;
      });
  };
  const { data, error } = useSWR(url, fetcher, { dedupingInterval: 10000 });

  if (error)
    return (
      <FullScreenError
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  //   console.log("dddddddddddddddd", data);
  return (
    <AdminLayout>
      <MetaDecorator
        title={`${data && data?.firstname} ${
          data && data?.lastname
        } details - Admin Dashboard`}
      />
      <Container maxWidth="xl">
        <PageHeading title="Admin details" />
        <Box>
          <Typography gutterBottom variant="h6" sx={{ fontWeight: 500 }}>
            Personal Information
          </Typography>
          <Box py={2}>
            <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
              <Typography color="text.secondary">Full Name:</Typography>
              <Typography align="left" sx={{ textTransform: "capitalize" }}>
                {data && data?.name}
              </Typography>
            </Box>
            <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
              <Typography color="text.secondary">Email:</Typography>
              <Typography align="left">{data && data?.email}</Typography>
            </Box>
            <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
              <Typography color="text.secondary">
                Email Verification:
              </Typography>
              {data?.hasVerifiedEmail ? (
                <Chip color="success" label="Verified" size="small" />
              ) : (
                <Chip label="Not Verified" color="error" size="small" />
              )}
            </Box>
            {/* <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
              <Typography color="text.secondary">Phone:</Typography>
              <Typography align="left">{data && data?.phone}</Typography>
            </Box> */}

            <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
              <Typography color="text.secondary">Role:</Typography>
              <Typography align="left">{data && data?.role}</Typography>
            </Box>
            <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
              <Typography color="text.secondary">Date Joined:</Typography>
              <Typography align="left">
                {dayjs(data?.createdAt).format("DD MMMM YYYY")}
              </Typography>
            </Box>
            <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
              <Typography color="text.secondary">Date Last Updated:</Typography>
              <Typography align="left">
                {dayjs(data?.updatedAt).format("DD MMMM YYYY")}
              </Typography>
            </Box>
            <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
              <Typography color="text.secondary">Status:</Typography>
              {data?.status ? (
                <Chip color="success" label="Active" size="small" />
              ) : (
                <Chip label="Not Active" color="error" size="small" />
              )}
            </Box>
          </Box>
        </Box>
      </Container>
    </AdminLayout>
  );
}

export default AdminDetails;
