import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import Container from "@mui/material/Container";
import { useFormik, Formik, Form, Field } from "formik";
import * as yup from "yup";
import axios from "axios";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import { useParams } from "react-router-dom";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import { AppContext } from "../context";
import {
  checkExpiredAuthToken,
  getAuthToken,
  labelStyles,
  labelStylesTwo,
  labelStylesThree,
  safeJSONParse,
  SALARY_FOR_LIFE,
  FIVE_OF_NINETY,
  LEGENDARY_LOTTO,
  MEGA_7,
  AFRIKENO,
  EAZY_WIN,
  KENO,
  SIX_FOUR_NINE,
  LOTTO,
  METRO,
  PERFECT_BALLS,
  CASH_OUT,
  META4,
  SIX49_BETOPTIONS,
  SIX49_OVEROPTIONS,
  SIX49_UNDEROPTIONS,
  SEVEN49_BETOPTIONS,
  SEVEN49_OVEROPTIONS,
  SEVEN49_UNDEROPTIONS,
  SEVEN_FOUR_NINE,
  MARIO_KENO_BETOPTIONS,
  LOTTO_CONTINENTAL_BET_OPTIONS,
  LOTTO_CONTINENTAL_RESULT_OPTIONS,
  BINGO_BALLS_BETOPTIONS,
  BINGO_BALLS_OVER_OPTIONS,
  BINGO_BALLS_UNDER_OPTIONS,
  MARIO_KENO,
  LOTTO_CONTINENTAL,
  BINGO_BALLS,
  MARIO_KENO_OVER_OPTIONS,
  MARIO_KENO_UNDER_OPTIONS,
  METRO_OVEROPTIONS,
  METRO_UNDEROPTIONS,
  SIX49_RESULT_OPTIONS,
} from "../utils";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import FormLoader from "../components/FormLoader/FormLoader";
import produce from "immer";

function EditGame() {
  const [selectedSlug, setSelectedSlug] = React.useState("");
  const [booster, setBoosterOption] = React.useState([]);
  const [betOption, setBetOption] = React.useState([]);
  const [over, setOverOption] = React.useState([]);
  const [under, setUnderOption] = React.useState([]);
  const [checkedBetOptions, setCheckedBetOptions] = React.useState([]);
  const [betOptionsValues, setBetOptionsValues] = React.useState([]);
  const [overOptionsValues, setOverOptionsValues] = React.useState([]);
  const [underOptionsValues, setUnderOptionsValues] = React.useState([]);
  const [resultOptionValues, setResultOptionValues] = React.useState([]);
  const [boosterOptionsValues, setBoosterOptionsValues] = React.useState([]);
  const [singleLotteryData, setSingleLotteryData] = React.useState([]);
  const [loadingSingleLotteryData, setLoadingSingleLotteryData] =
    React.useState(false);
  const [isSubmitting, setisSubmitting] = React.useState(false);
  const [loadingbooster, setLoadingBoosterOption] = React.useState(false);
  const [loadingbetOption, setLoadingBetOption] = React.useState(false);
  const [loadingover, setLoadingOverOption] = React.useState(false);
  const [loadingunder, setLoadingUnderOption] = React.useState(false);
  const [resultTypes, setResultTypes] = React.useState([]);
  const [loadingResultTypes, setLoadingResultTypes] = React.useState(false);

  const { handleOpenSnackbar } = React.useContext(AppContext);
  const specialInputRef = React.useRef();

  const { lotteryId } = useParams();

  const singleLotteryURL = `${process.env.API_URL}game/fetch-lottery/${lotteryId}`;
  const settingsURL = `${process.env.API_URL}site-settings/fetch-setting-by-slug/game-categories`;

  // Bet Options Onchange handler
  const handleBetOptionChange = (e) => {
    const value = e.target.value;
    const index = betOptionsValues?.indexOf(value);
    const found = checkedBetOptions.find((element) => value === element?.name);

    if (index === -1 && !found) {
      setBetOptionsValues((prevValue) => [...prevValue, value]);
      setCheckedBetOptions((prevState) => [
        ...prevState,
        { name: value, multiplier: "1" },
      ]);
    } else if (found) {
      setBetOptionsValues((prevValue) =>
        prevValue.filter((item) => item !== value)
      );
      setCheckedBetOptions((prevState) =>
        prevState.filter((item) => item?.name !== value)
      );
    } else {
      setBetOptionsValues((prevValue) =>
        prevValue.filter((item) => item !== value)
      );
      setCheckedBetOptions((prevState) =>
        prevState.filter((item) => item?.name !== value)
      );
    }
  };

  // Result Options Onchange Handler
  const handleResultOptionsChange = (e) => {
    const value = e.target.value;
    const index = resultOptionValues?.indexOf(value);
    if (index === -1) {
      setResultOptionValues((prevValue) => [...prevValue, value]);
    } else {
      setResultOptionValues((prevValue) =>
        prevValue?.filter((item) => item !== value)
      );
    }
  };

  // Booster Options Onchange Handler
  const handleBoosterOptionsChange = (e) => {
    const value = e.target.value;
    const index = boosterOptionsValues?.indexOf(value);
    if (index === -1) {
      setBoosterOptionsValues((prevValue) => [...prevValue, value]);
    } else {
      setBoosterOptionsValues((prevValue) =>
        prevValue?.filter((item) => item !== value)
      );
    }
  };

  // Over Options Onchange Handler
  const handleOverOptionsChange = useCallback((e) => {
    const value = e.target.value;
    const gottenOption = over?.find((item) => item?.name === value);
    const index = overOptionsValues?.find((item) => item?.name === value);
    if (!index) {
      setOverOptionsValues((prevValue) => [...prevValue, gottenOption]);
    } else {
      setOverOptionsValues((prevValue) =>
        prevValue?.filter((item) => item?.name !== value)
      );
    }
  }, []);

  // Under Options Onchange Handler
  const handleUnderOptionsChange = useCallback((e) => {
    const value = e.target.value;
    const gottenOption = under?.find((item) => item?.name === value);
    const index = underOptionsValues?.find((item) => item?.name === value);
    if (!index) {
      setUnderOptionsValues((prevValue) => [...prevValue, gottenOption]);
    } else {
      setUnderOptionsValues((prevValue) =>
        prevValue?.filter((item) => item !== value)
      );
    }
  }, []);

  const fetcherSingleLottery = async () => {
    try {
      checkExpiredAuthToken();
      const token = getAuthToken();
      const res = await axios.get(singleLotteryURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      await setSelectedSlug((prevState) => res?.data?.data?.data?.category);
      const category = res?.data?.data?.data?.category;
      if (category === FIVE_OF_NINETY) {
        getFiveNinetyOptions();
      } else if (category === SALARY_FOR_LIFE) {
        getSalary4LifeOption();
      } else if (category === LEGENDARY_LOTTO) {
        getLegendaryLottoOption();
      } else if (category === MEGA_7) {
        getMega7Option();
      } else if (category === AFRIKENO) {
        getAfrikenoOption();
      } else if (category === CASH_OUT) {
        getCashOutOptions();
      } else if (category === KENO) {
        getKenoOption();
      } else if (category === META4) {
        getmeta4Options();
      } else if (category === PERFECT_BALLS) {
        getPerfectBallsOptions();
      } else if (category === LOTTO) {
        getLottoOptions();
      } else if (category === METRO) {
        getMetroOptions();
      } else if (category === SIX_FOUR_NINE) {
        get649Option();
      } else if (category === SEVEN_FOUR_NINE) {
        get749Option();
      } else if (category === MARIO_KENO) {
        getMariokenoOptions();
      } else if (category === LOTTO_CONTINENTAL) {
        getLottoContinetalOptions();
      } else if (category === BINGO_BALLS) {
        getBingoBallsOptions();
      } else if (category === EAZY_WIN) {
        getEazyWinOption();
      }
      let parsedOptions = safeJSONParse(res?.data?.data?.data?.betOptions);
      let checkedOptions = parsedOptions?.map((item) => ({
        name: item?.name,
        multiplier: item?.multiplier,
      }));
      setSingleLotteryData(res?.data?.data?.data);
      let tempData, betOptions;
      betOptions = safeJSONParse(res?.data?.data?.data?.betOptions);
      tempData = betOptions.map((option) => option?.name);
      setCheckedBetOptions((prevData) => checkedOptions);
      setBetOptionsValues(tempData);

      setResultOptionValues(
        safeJSONParse(res?.data?.data?.data?.resultOptions)
      );
      setOverOptionsValues(safeJSONParse(res?.data?.data?.data?.overOptions));
      setUnderOptionsValues(safeJSONParse(res?.data?.data?.data?.underOptions));
      setBoosterOptionsValues(
        safeJSONParse(res?.data?.data?.data?.boosterOptions)
      );
      setLoadingSingleLotteryData(false);
    } catch (error) {
      setLoadingSingleLotteryData(false);
      console.log("error fetching lottery data", error);
    }
  };

  React.useEffect(() => {
    fetcherSingleLottery();
  }, []);

  let initialValues = {
    lotteryName: "",
    category: "",
    slug: "",
    imageUrl: "",
    audioUrl: "",
    resultOptions: [],
    betOptions: [],
    boosterOptions: [],
    overOptions: [],
    underOptions: [],
    setA: false,
    setB: false,
  };
  if (singleLotteryData && singleLotteryData?.lotteryId) {
    try {
      let overOptions = safeJSONParse(singleLotteryData?.overOptions);
      let underOptions = safeJSONParse(singleLotteryData?.underOptions);
      let underOptionsNames = [],
        overOptionsNames = [];
      overOptions &&
        overOptions?.length > 0 &&
        overOptions?.forEach((item) => {
          if (item?.name) {
            overOptionsNames.push(item?.name);
          } else {
            overOptionsNames.push(item);
          }
        });
      underOptions &&
        underOptions?.length > 0 &&
        underOptions?.forEach((item) => {
          if (item?.name) {
            underOptionsNames.push(item?.name);
          } else {
            underOptionsNames.push(item);
          }
        });
      initialValues.lotteryName = singleLotteryData?.name;
      initialValues.category = singleLotteryData?.category;
      initialValues.slug = singleLotteryData?.slug;
      initialValues.imageUrl = singleLotteryData?.imageUrl;
      initialValues.setA = singleLotteryData?.setA;
      initialValues.setB = singleLotteryData?.setB;
      initialValues.overOptions = overOptionsNames;
      initialValues.underOptions = underOptionsNames;
    } catch (error) {
      console.log("ee", error);
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: async (values, helpers) => {
      let lotterySlug = values.lotteryName;
      lotterySlug = lotterySlug.replaceAll(" ", "-");
      lotterySlug = lotterySlug.toLowerCase();
      let overSubmitOptions = [];
      let underSubmitOptions = [];
      let betSubmitOptions = [];
      over?.map((item) => {
        if (values.overOptions?.includes(item?.name)) {
          overSubmitOptions.push(item);
        }
      });
      under?.map((item) => {
        if (values.underOptions?.includes(item?.name)) {
          underSubmitOptions.push(item);
        }
      });

      for (let index = 0; index < checkedBetOptions.length; index++) {
        const element = checkedBetOptions[index];

        let found = betOption?.find(
          (item) =>
            item?.name?.toLowerCase() === element?.name?.toLowerCase() ||
            item?.value?.toLowerCase() === element?.name?.toLowerCase()
        );
        if (found) {
          // console.log("eeeeeeeeeeeeeeeeeeeeeeee", element);
          // console.log("ffffffffffffffffffffffff", found);
          // found.name = element.name;
          betSubmitOptions.push({
            ...found,
            name: found?.value,
            multiplier: element?.multiplier,
          });
        }
        found = null;
      }

      setisSubmitting((prev) => true);
      checkExpiredAuthToken();
      const token = getAuthToken();
      if (selectedSlug === FIVE_OF_NINETY || selectedSlug === LOTTO) {
        values.setA = true;
        values.setB = true;
      }
      values.overOptions = JSON.stringify(overSubmitOptions);
      values.underOptions = JSON.stringify(underSubmitOptions);
      values.betOptions = JSON.stringify(betSubmitOptions);
      values.boosterOptions = JSON.stringify(boosterOptionsValues);
      // values.boosterOptions = JSON.stringify([]);
      values.resultOptions = JSON.stringify(resultOptionValues);

      // values.overOptions = JSON.stringify([]);
      // values.underOptions = JSON.stringify([]);
      // values.betOptions = JSON.stringify([]);
      // values.boosterOptions = JSON.stringify([]);
      // values.resultOptions = JSON.stringify([]);
      values.category = selectedSlug;
      const formData = new FormData();

      formData.append("lotteryName", values.lotteryName);
      formData.append("category", values.category);
      formData.append("slug", lotterySlug);
      formData.append("resultOptions", values.resultOptions);
      formData.append("betOptions", values.betOptions);
      formData.append("boosterOptions", values.boosterOptions);
      formData.append("overOptions", values.overOptions);
      formData.append("underOptions", values.underOptions);
      formData.append("setA", values.setA);
      formData.append("setB", values.setB);

      if (!values?.imageUrl) {
        formData.append("imageUrl", singleLotteryData?.imageUrl);
      } else {
        formData.append("imageUrl", values.imageUrl);
      }
      if (!values?.audioUrl) {
        formData.append("audioUrl", singleLotteryData?.audioUrl);
      } else {
        formData.append("audioUrl", values.audioUrl);
      }

      axios
        .put(
          `${process.env.API_URL}game/update-lottery/${lotteryId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          helpers.setSubmitting(false);
          setisSubmitting((prev) => false);
          helpers.resetForm();
          window.scrollTo(0, 0);
          if (res?.data?.message) {
            return handleOpenSnackbar({
              message: res?.data?.message,
              severity: "success",
            });
          }
          return handleOpenSnackbar({
            message: "Game Updated Successfully",
            severity: "success",
          });
        })
        .catch((err) => {
          helpers.setSubmitting(false);
          setisSubmitting((prev) => false);
          if (err?.response?.data?.responsemessage?.msg) {
            handleOpenSnackbar({
              message: err?.response?.data?.responsemessage?.msg,
              severity: "error",
            });
            setTimeout(() => {
              return window.location.reload();
            }, 2000);
          }
          if (err?.response?.data?.responsemessage) {
            handleOpenSnackbar({
              message: err?.response?.data?.responsemessage,
              severity: "error",
            });
            setTimeout(() => {
              return window.location.reload();
            }, 2000);
          }
          handleOpenSnackbar({
            message: "Sorry, Game Could Not Be Updated",
            severity: "error",
          });
        });
    },
    validationSchema: yup.object().shape({
      lotteryName: yup.string().required().label("Lottery Name"),
      // slug: yup.string().required().label("Alternate Name"),
    }),
  });
  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(settingsURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };
  // console.log("initialvalues", initialValues);
  const getOptions = () => {
    // console.log("zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz");
    setCheckedBetOptions([]);
    if (selectedSlug === FIVE_OF_NINETY) {
      getFiveNinetyOptions();
    } else if (selectedSlug === SALARY_FOR_LIFE) {
      getSalary4LifeOption();
    } else if (selectedSlug === LEGENDARY_LOTTO) {
      getLegendaryLottoOption();
    } else if (selectedSlug === MEGA_7) {
      getMega7Option();
    } else if (selectedSlug === AFRIKENO) {
      getAfrikenoOption();
    } else if (selectedSlug === EAZY_WIN) {
      getEazyWinOption();
    } else if (selectedSlug === KENO) {
      getKenoOption();
    } else if (selectedSlug === SIX_FOUR_NINE) {
      get649Option();
    } else if (selectedSlug === META4) {
      getmeta4Options();
    } else if (selectedSlug === CASH_OUT) {
      getCashOutOptions();
    } else if (selectedSlug === PERFECT_BALLS) {
      getPerfectBallsOptions();
    } else if (selectedSlug === METRO) {
      getMetroOptions();
    } else if (selectedSlug === LOTTO) {
      getLottoOptions();
    } else if (selectedSlug === SEVEN_FOUR_NINE) {
      get749Option();
    } else if (selectedSlug === MARIO_KENO) {
      getMariokenoOptions();
    } else if (selectedSlug === LOTTO_CONTINENTAL) {
      getLottoContinetalOptions();
    } else if (selectedSlug === BINGO_BALLS) {
      getBingoBallsOptions();
    }
  };

  const getFiveNinetyOptions = () => {
    getBetOption("five-ninety-bet-options");
    getBoosterOption("five-ninety-booster-options");
    getOverOption("over-bet-options");
    getUnderOption("under-bet-options");
    getResultTypes("five-ninety-result-types");
  };

  const getSalary4LifeOption = () => {
    getBetOption("salary-for-life-bet-options");
    setBoosterOption([]);
    setResultTypes([]);
    setOverOption([]);
    setUnderOption([]);
  };

  const getLegendaryLottoOption = () => {
    getBetOption("legendary-lotto-bet-options");
    getBoosterOption("legendary-lotto-booster-options");
    getOverOption("over-bet-options");
    getUnderOption("under-bet-options");
    getResultTypes("legendary-lotto-result-types");
  };

  const getMega7Option = () => {
    getBetOption("mega-7-bet-options");
    setResultTypes([]);
    setBoosterOption([]);
    setOverOption([]);
    setUnderOption([]);
  };

  const get749Option = () => {
    setResultTypes([]);
    setBoosterOption([]);
    getBetOption(SEVEN49_BETOPTIONS);
    getOverOption(SEVEN49_OVEROPTIONS);
    getUnderOption(SEVEN49_UNDEROPTIONS);
  };
  const getAfrikenoOption = () => {
    getBetOption("afrikeno-bet-options");
    setResultTypes([]);
    setBoosterOption([]);
    getOverOption("afrikeno-over-bet-options");
    getUnderOption("afrikeno-under-bet-options");
  };
  const getEazyWinOption = () => {
    getBetOption("eazy-win-bet-options");
    setResultTypes([]);
    setBoosterOption([]);
    getOverOption("eazy-win-over-bet-options");
    getUnderOption("eazy-win-under-bet-options");
  };

  const get649Option = () => {
    setBoosterOption([]);
    setResultTypes([]);
    getBetOption(SIX49_BETOPTIONS);
    getOverOption(SIX49_OVEROPTIONS);
    getUnderOption(SIX49_UNDEROPTIONS);
  };
  const getKenoOption = () => {
    setResultTypes([]);
    setBoosterOption([]);
    getBetOption("keno-bet-options");
    getOverOption("keno-over-bet-options");
    getUnderOption("keno-under-bet-options");
  };

  const getmeta4Options = () => {
    setResultTypes([]);
    getBetOption("meta4-bet-options");
    setBoosterOption([]);
    getOverOption("meta4-over-bet-options");
    getUnderOption("meta4-under-bet-options");
  };

  const getCashOutOptions = () => {
    getBetOption("cash-out-bet-options");
    setBoosterOption([]);
    setResultTypes([]);
    setOverOption([]);
    setUnderOption([]);
  };

  const getPerfectBallsOptions = () => {
    getBetOption("perfect-balls-bet-options");
    setResultTypes([]);
    setBoosterOption([]);
    setOverOption([]);
    setUnderOption([]);
  };

  const getMetroOptions = () => {
    getBetOption("metro-bet-options");
    setResultTypes([]);
    setBoosterOption([]);
    getOverOption(METRO_OVEROPTIONS);
    getUnderOption(METRO_UNDEROPTIONS);
  };
  const getLottoOptions = () => {
    getBetOption("lotto-bet-options");
    getResultTypes("lotto-result-options");
    getOverOption("lotto-over-bet-options");
    getUnderOption("lotto-under-bet-options");
    setBoosterOption([]);
  };

  const getMariokenoOptions = () => {
    getBetOption(MARIO_KENO_BETOPTIONS);
    setResultTypes([]);
    getOverOption(MARIO_KENO_OVER_OPTIONS);
    getUnderOption(MARIO_KENO_UNDER_OPTIONS);
    setBoosterOption([]);
  };
  const getLottoContinetalOptions = () => {
    getBetOption(LOTTO_CONTINENTAL_BET_OPTIONS);
    getResultTypes(LOTTO_CONTINENTAL_RESULT_OPTIONS);
    setOverOption([]);
    setUnderOption([]);
    setBoosterOption([]);
  };
  const getBingoBallsOptions = () => {
    getBetOption(BINGO_BALLS_BETOPTIONS);
    getOverOption(BINGO_BALLS_OVER_OPTIONS);
    getUnderOption(BINGO_BALLS_UNDER_OPTIONS);
    setResultTypes([]);
    setBoosterOption([]);
  };
  const getBetOption = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingBetOption(true);
    return axios
      .get(
        `${process.env.API_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let { content } = res?.data?.data?.data;
        content = JSON.parse(content);
        setBetOption(content);
        setLoadingBetOption(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const getResultTypes = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingResultTypes(true);
    return axios
      .get(
        `${process.env.API_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let { content } = res?.data?.data?.data;
        content = JSON.parse(content);
        setResultTypes(content);
        setLoadingResultTypes(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const getBoosterOption = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingBoosterOption(true);
    return axios
      .get(
        `${process.env.API_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let { content } = res?.data?.data?.data;
        content = JSON.parse(content);
        setBoosterOption(content);
        setLoadingBoosterOption(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const getOverOption = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingOverOption(true);
    return axios
      .get(
        `${process.env.API_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let { content } = res?.data?.data?.data;
        content = JSON.parse(content);

        setOverOption(content);
        setLoadingOverOption(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const getUnderOption = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingUnderOption(true);
    return axios
      .get(
        `${process.env.API_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let { content } = res?.data?.data?.data;

        setUnderOption(JSON.parse(content));
        setLoadingUnderOption(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const isOptionChecked = (list = [], value = "") => {
    if (list?.find?.((item) => item?.name === value)) {
      return true;
    }
    return false;
  };
  const handleSelectCategory = (e) => {
    const value = e.target.value;
    setCheckedBetOptions([]);
    setBetOptionsValues([]);
    setResultOptionValues([]);
    setOverOptionsValues([]);
    setUnderOptionsValues([]);
    setBoosterOptionsValues([]);
    setSelectedSlug(value);
    setTimeout(() => {
      specialInputRef.current.click();
    }, 1000);
  };
  const specialOnchange = () => {
    getOptions();
  };

  const formPaperStyle = { padding: 3 };
  const { data, error } = useSWRImmutable(settingsURL, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  if (error)
    return (
      <FullScreenError
        title=" Sorry, data cannot be retrieved at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  let Categories = data && data?.content ? safeJSONParse(data?.content) : [];
  console.log("formik state", resultTypes);
  return (
    <AdminLayout>
      <MetaDecorator title="Update Game Category- Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Update Game Category" />
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={7}>
              <Paper sx={formPaperStyle}>
                <div>
                  <form noValidate onSubmit={formik.handleSubmit}>
                    <Box
                      my={1}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <FormControl fullWidth>
                        <FormLabel sx={labelStyles}>
                          Select Display Image
                        </FormLabel>
                        <br />
                        <input
                          type="file"
                          name="imageUrl"
                          accept="image/*"
                          onBlur={formik.handleBlur}
                          onChange={(e) =>
                            formik.setFieldValue("imageUrl", e.target.files[0])
                          }
                        />
                      </FormControl>
                      <div>
                        {singleLotteryData?.imageUrl && (
                          <div>
                            <Typography variant="caption">
                              Previous Image
                            </Typography>
                            <img
                              src={singleLotteryData?.imageUrl}
                              alt="lottery image"
                              width="60px"
                              height="60px"
                            />{" "}
                          </div>
                        )}
                      </div>
                    </Box>
                    <Box my={2}>
                      <FormControl fullWidth>
                        <FormLabel sx={labelStyles}>
                          Select Lottery TV Screen Music (Max of 2mb)
                        </FormLabel>
                        <br />
                        <input
                          type="file"
                          name="audioUrl"
                          accept=".mp3,audio/*"
                          onBlur={formik.handleBlur}
                          onChange={(e) => {
                            formik.setFieldValue("audioUrl", e.target.files[0]);
                          }}
                        />
                      </FormControl>
                    </Box>
                    <Box my={1}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          label="Enter Game Category Title"
                          error={
                            formik.touched.lotteryName &&
                            formik.errors.lotteryName
                          }
                          name="lotteryName"
                          value={formik.values.lotteryName}
                          variant="outlined"
                          fullWidth
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          helperText={
                            formik.touched.lotteryName &&
                            formik.errors.lotteryName
                          }
                        />
                      </FormControl>
                    </Box>

                    <Box my={1}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        error={
                          formik.touched.category && formik.errors.category
                        }
                      >
                        <InputLabel variant="outlined" sx={labelStylesThree}>
                          Select Category
                        </InputLabel>
                        {/* <br />
                  <br /> */}
                        <Select
                          // label="Select Category"
                          name="category"
                          // variant="outlined"
                          value={selectedSlug}
                          onChange={handleSelectCategory}
                        >
                          {Categories?.map((category, idx) => (
                            <MenuItem
                              key={`222${idx}${category}`}
                              value={category}
                            >
                              {category}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {formik.touched.category && formik.errors.category}
                        </FormHelperText>
                      </FormControl>
                    </Box>

                    <button
                      style={{ display: "none" }}
                      type="button"
                      ref={specialInputRef}
                      onClick={specialOnchange}
                    >
                      Click
                    </button>
                    {selectedSlug !== "salary4life" &&
                    selectedSlug !== MEGA_7 ? (
                      <>
                        {loadingResultTypes ? (
                          <FormLoader />
                        ) : (
                          <Box>
                            {resultTypes && resultTypes?.length > 0 && (
                              <Box my={1}>
                                <FormControl variant="outlined" fullWidth>
                                  <InputLabel
                                    variant="outlined"
                                    sx={labelStylesTwo}
                                  >
                                    Result Options
                                  </InputLabel>
                                  <br />
                                  <FormGroup
                                    aria-label="result options"
                                    row
                                    name="resultOptions"
                                  >
                                    {resultTypes &&
                                      resultTypes?.length > 0 &&
                                      resultTypes?.map((item, idx) => (
                                        <FormControlLabel
                                          key={`${item?.value}a0${item?.name}${idx}`}
                                          value={item?.value}
                                          control={
                                            <Checkbox
                                              color="success"
                                              checked={resultOptionValues?.includes(
                                                item?.value
                                              )}
                                              onChange={
                                                handleResultOptionsChange
                                              }
                                              name="resultOptions"
                                            />
                                          }
                                          label={item?.name}
                                        />
                                      ))}
                                  </FormGroup>
                                </FormControl>
                              </Box>
                            )}
                          </Box>
                        )}
                      </>
                    ) : null}
                    {loadingbetOption ? (
                      <FormLoader />
                    ) : (
                      <Box>
                        {betOption && betOption?.length > 0 && (
                          <Box my={1}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel
                                variant="outlined"
                                sx={labelStylesTwo}
                              >
                                Bet Options
                              </InputLabel>
                              <br />
                              <FormGroup
                                aria-label="bet options"
                                row
                                name="betOptions"
                              >
                                {betOption &&
                                  betOption?.length > 0 &&
                                  betOption?.map((item, idx) => (
                                    <FormControlLabel
                                      key={`${item?.value}0${item?.name}${idx}`}
                                      value={item?.value}
                                      control={
                                        <Checkbox
                                          color="success"
                                          checked={
                                            betOptionsValues?.includes(
                                              item?.value
                                            ) ||
                                            betOptionsValues?.includes(
                                              item?.name
                                            )
                                          }
                                          onChange={handleBetOptionChange}
                                          name="betOptions"
                                        />
                                      }
                                      label={item?.name}
                                    />
                                  ))}
                              </FormGroup>
                            </FormControl>
                          </Box>
                        )}
                      </Box>
                    )}

                    {loadingbooster ? (
                      <FormLoader />
                    ) : (
                      <Box>
                        {booster && booster?.length > 0 && (
                          <Box my={1}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel
                                variant="outlined"
                                sx={labelStylesTwo}
                              >
                                Booster Options
                              </InputLabel>
                              <br />
                              <FormGroup
                                aria-label="booster options"
                                row
                                name="boosterOptions"
                              >
                                {booster &&
                                  booster?.length > 0 &&
                                  booster?.map((item) => (
                                    <FormControlLabel
                                      key={item.value}
                                      value={item.value}
                                      control={
                                        <Checkbox
                                          color="success"
                                          checked={boosterOptionsValues?.includes(
                                            item?.value
                                          )}
                                          onChange={handleBoosterOptionsChange}
                                          name="boosterOptions"
                                        />
                                      }
                                      label={item.name}
                                    />
                                  ))}
                              </FormGroup>
                            </FormControl>
                          </Box>
                        )}
                      </Box>
                    )}

                    {loadingover ? (
                      <FormLoader />
                    ) : (
                      <Box>
                        {over && over?.length > 0 && (
                          <Box my={1}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel
                                variant="outlined"
                                sx={labelStylesTwo}
                              >
                                Over Options
                              </InputLabel>
                              <br />
                              <FormGroup aria-label="over options" row>
                                {over &&
                                  over?.length > 0 &&
                                  over?.map((item, idx) => (
                                    <FormControlLabel
                                      key={`${item?.name}p${idx}`}
                                      value={item?.name}
                                      control={
                                        <Checkbox
                                          color="success"
                                          // checked={isOptionChecked(
                                          //   overOptionsValues,
                                          //   item?.name
                                          // )}
                                          checked={formik?.values?.overOptions?.includes(
                                            item?.name
                                          )}
                                          onChange={formik.handleChange}
                                          name="overOptions"
                                        />
                                      }
                                      label={item?.name}
                                    />
                                  ))}
                              </FormGroup>
                            </FormControl>
                          </Box>
                        )}
                      </Box>
                    )}

                    {loadingunder ? (
                      <FormLoader />
                    ) : (
                      <Box>
                        {under && under?.length > 0 && (
                          <Box my={1}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel
                                variant="outlined"
                                sx={labelStylesTwo}
                              >
                                Under Options
                              </InputLabel>
                              <br />
                              <FormGroup aria-label="under options" row>
                                {under &&
                                  under?.length > 0 &&
                                  under?.map((item, idx) => (
                                    <FormControlLabel
                                      key={`${item?.name}ew${idx}`}
                                      value={item?.name}
                                      control={
                                        <Checkbox
                                          color="success"
                                          checked={formik?.values?.underOptions?.includes(
                                            item?.name
                                          )}
                                          // onChange={handleUnderOptionsChange}
                                          onChange={formik.handleChange}
                                          name="underOptions"
                                        />
                                      }
                                      label={item?.name}
                                    />
                                  ))}
                              </FormGroup>
                            </FormControl>
                          </Box>
                        )}
                      </Box>
                    )}

                    <br />
                    <Button
                      startIcon={
                        isSubmitting && (
                          <CircularProgress severity="success" size={"1rem"} />
                        )
                      }
                      type="submit"
                      disabled={isSubmitting}
                      size="large"
                      variant="contained"
                      color="success"
                    >
                      {isSubmitting ? "Updating Game" : " Update Game"}
                    </Button>
                  </form>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={5}>
              <Paper sx={formPaperStyle}>
                {checkedBetOptions?.length > 0 &&
                  checkedBetOptions?.map((item, idx) => (
                    <Box
                      key={`${item?.name}pp`}
                      my={1}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormControl variant="outlined">
                        <TextField
                          label="Bet Option"
                          variant="outlined"
                          size="small"
                          value={item?.name}
                          readOnly
                        />
                      </FormControl>
                      &nbsp;
                      <FormControl variant="outlined">
                        <TextField
                          label="Multiplier"
                          variant="outlined"
                          size="small"
                          value={item?.multiplier}
                          onChange={(e) => {
                            const value = e.target.value;
                            setCheckedBetOptions((currentValue) =>
                              produce(currentValue, (draft) => {
                                draft[idx].multiplier = value;
                              })
                            );
                          }}
                        />
                      </FormControl>
                    </Box>
                  ))}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </AdminLayout>
  );
}

export default EditGame;
