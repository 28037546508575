import * as React from "react";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { dialogMainTextStyles, previewDialogMainTitleStyle } from "../../utils";

import dayjs from "dayjs";

export default function PreviewGameConfigModal({
  openDialog,
  handleCloseDialog,
  selectedGame,
}) {
  return (
    <div>
      <Dialog
        open={openDialog}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleCloseDialog}
      >
        <DialogTitle color="success" sx={previewDialogMainTitleStyle}>
          GAME CONFIG PREVIEW
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box my={1}>
                  {/* <Typography gutterBottom sx={previewSubTitleStyles}>
                    Primary Information
                  </Typography> */}
                  <Box my={1}>
                    <Typography variant="caption">ID:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {selectedGame?.id}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption"> Category:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {selectedGame?.category}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Bet Type:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {selectedGame?.betType}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Result Type:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {selectedGame?.resultType}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Booster:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {selectedGame?.booster}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Date Created:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {dayjs(selectedGame?.createdAt).format("MMM, D YYYY")}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">
                      Last Updated Date:
                    </Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {dayjs(selectedGame?.updatedAt).format("MMM, D YYYY")}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box my={1}>
                  {/* <Typography gutterBottom sx={previewSubTitleStyles}>
                    Other Information
                  </Typography> */}
                  <Box my={1}>
                    <Typography variant="caption">Config Name:</Typography>
                    <Typography sx={dialogMainTextStyles} align="left">
                      {JSON.parse(selectedGame?.config)?.name}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Odds:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {JSON.parse(selectedGame?.config)?.odds}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Min Odds:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {JSON.parse(selectedGame?.config)?.minOdds}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Max Odds:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {JSON.parse(selectedGame?.config)?.maxOdds}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">
                      Affiliates Commission:
                    </Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {JSON.parse(selectedGame?.config)?.affiliate}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Agent Commission:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {JSON.parse(selectedGame?.config)?.agentCommission}
                    </Typography>
                  </Box>
                  <Box mb={1}>
                    <Typography variant="caption">RTP:</Typography>
                    <Typography>
                      {JSON.parse(selectedGame?.config)?.rtp}{" "}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">RTO:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {JSON.parse(selectedGame?.config)?.rto}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box mb={1}>
              <Typography variant="caption">Description:</Typography>
              <Typography>
                {JSON.parse(selectedGame?.config)?.description}{" "}
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
