import * as React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import NoDataAvailable from "../EmptyTable/EmptyTable";

function GameConfigTable({
  handleChangePage,
  rowsPerPage,
  rowsPerPageOptions,
  handleChangeRowsPerPage,
  page,
  data,
  totalCount,
  handleOpenPreviewConfig,
  handleOpenEditConfig,
  handleSelectGame,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event, ticket) => {
    setAnchorEl(event.currentTarget);

    handleSelectGame(ticket);
  };
  const handleClose = () => {
    setAnchorEl(null);
    handleSelectGame({});
  };

  const headCells = [
    "Name",
    "Category",
    "Game Count",
    "Result Count",
    "Date Created",
    "Status",
    "Actions",
  ];

  return (
    <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="recent users table">
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell key={item} component="th" sx={{ fontWeight: 600 }}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.length > 0 &&
              data?.map((row, index) => (
                <TableRow key={`${row.lotteryId}oo${index}`}>
                  <TableCell>{row?.name}</TableCell>
                  <TableCell>{row?.category}</TableCell>
                  <TableCell>{row?.gameCount}</TableCell>
                  <TableCell>{row?.resultCount}</TableCell>
                  <TableCell style={{ width: 160 }}>
                    {dayjs(row.createdAt).format("MMM D, YYYY")}
                  </TableCell>
                  <TableCell style={{ width: 120 }}>
                    {row?.status ? (
                      <Chip color="success" label="Active" size="small" />
                    ) : (
                      <Chip label="Not Active" color="error" size="small" />
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => handleClick(e, row)}>
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      PaperProps={{
                        elevation: 1,
                        sx: { boxShadow: "2px 2px 4px #eee" },
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => handleOpenPreviewConfig()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Preview Details
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => {
                          handleOpenEditConfig();
                        }}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Edit
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

export default GameConfigTable;
