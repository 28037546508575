import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import NumberFormat from "react-number-format";
import NoDataAvailable from "../EmptyTable/EmptyTable";
import { boldTextCalculationStyles, getTransactionSum } from "../../utils";
import TableLoader from "../TableLoader/TableLoader";
import TableCSVButton from "../TableCSVButton/TableCSVButton";

function WithdrawalRequestsTable({
  hasFiltered,
  isFiltering,
  data,
  page,
  totalCount,
  selectedRequest,
  rowsPerPage,
  rowsPerPageOptions,
  handleChangeRowsPerPage,
  handleChangePage,
  handleSelectTicket,
  handleOpenPreviewRequest,
  handleOpenApproveRequestDialog,
}) {
  const [selectedId, setSelectedId] = React.useState(""); // For user ID when the more icon is clicked
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();

  const open = Boolean(anchorEl);

  const handleClick = (event, ticket) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(ticket?.requestId);
    handleSelectTicket(ticket);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedId("");
  };

  const headCells = [
    "Player's Name",
    "Player's Email",
    "Phone Number",
    "Request ID",
    "Reference ID",
    "Amount",
    "Date Created",
    "Approval Status",
    "Request Status",
    "Actions",
  ];
  const renderStatus = (stat) => {
    if (stat === "blacklisted") {
      return (
        <Chip
          sx={{ background: "black", color: "#fff" }}
          label={stat}
          size="small"
        />
      );
    }

    if (stat === "held") {
      return (
        <Chip
          sx={{ background: "black", color: "#fff" }}
          label={stat}
          size="small"
        />
      );
    }
    if (stat === "canceled") {
      return (
        <Chip
          sx={{ background: "black", color: "#fff" }}
          label={stat}
          size="small"
        />
      );
    }
    if (stat === "pending") {
      return <Chip color="warning" label={stat} size="small" />;
    }
    if (stat === "lost" || stat === "inactive") {
      return <Chip color="error" label={stat} size="small" />;
    }

    if (stat === "ongoing") {
      return <Chip color="primary" label={stat} size="small" />;
    }

    return <Chip label={stat} color="success" size="small" />;
  };

  const headers = [
    "Player's Name",
    "Player's Email",
    "Phone Number",
    "Request ID",
    "Reference ID",
    "Amount",
    "Date Created",
    "Approval Status",
    "Request Status",
  ];

  let rows = [];
  if (data?.length > 0) {
    data?.forEach((item) => {
      rows.push([
        `${item?.User?.firstname} ${item?.User?.lastname}`,
        item?.User?.email,
        item?.User?.phone,
        item?.requestId,
        item?.referenceId,
        item?.amount,
        item?.createdAt,
        item?.isApproved,
        item?.status,
      ]);
    });
    rows = [headers, ...rows];
  }
  return isFiltering ? (
    <TableLoader />
  ) : (
    <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
      {data?.length > 0 ? (
        <TableCSVButton data={rows} filename="withdrawal_requests.csv" />
      ) : null}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="recent users table">
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell key={item} component="th" sx={{ fontWeight: 600 }}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.length > 0 &&
              data?.map((row) => (
                <TableRow key={row?.requestId}>
                  <TableCell>
                    {row?.User?.firstname} {row?.User?.lastname}
                  </TableCell>
                  <TableCell>{row?.User?.email}</TableCell>
                  <TableCell>{row?.User?.phone}</TableCell>
                  <TableCell>{row?.requestId}</TableCell>
                  <TableCell>{row?.referenceId}</TableCell>
                  <TableCell>
                    <NumberFormat
                      value={row?.amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"NGN"}
                      renderText={(value, props) => (
                        <div {...props}>{value}</div>
                      )}
                    />
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                    {dayjs(row.createdAt).format("MMM D, YYYY")}
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                    {row.isApproved ? (
                      <Chip color="success" label="Approved" size="small" />
                    ) : (
                      <Chip label="Not Approved" color="error" size="small" />
                    )}
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                    {renderStatus(row?.status)}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => handleClick(e, row)}>
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      PaperProps={{
                        elevation: 1,
                        sx: { boxShadow: "2px 2px 4px #eee" },
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      {" "}
                      <MenuItem
                        onClick={() => handleOpenPreviewRequest()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Preview Details
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        style={{
                          background: selectedRequest?.isApproved
                            ? "#eee"
                            : "auto",
                          pointerEvents: selectedRequest?.isApproved
                            ? "none"
                            : "auto",
                        }}
                        onClick={() => handleOpenApproveRequestDialog()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        {selectedRequest?.isApproved
                          ? "Already Approved"
                          : "Approve Request"}
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() =>
                          history.push(
                            `/withdrawal-requests/withdrawal-request-details/${selectedId}`
                          )
                        }
                        sx={{ fontSize: "12.7px" }}
                      >
                        View More Details
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            {data && data?.length > 0 && (
              <TableRow>
                <TableCell sx={boldTextCalculationStyles} colSpan={5}>
                  Total
                </TableCell>
                <TableCell align="right">
                  <NumberFormat
                    value={getTransactionSum(data)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"NGN"}
                    renderText={(value, props) => (
                      <Typography
                        sx={boldTextCalculationStyles}
                        align="left"
                        {...props}
                      >
                        {value}
                      </Typography>
                    )}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

export default WithdrawalRequestsTable;
