import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import dayjs from "dayjs";
import TableLoader from "../TableLoader/TableLoader";
import NoDataAvailable from "../EmptyTable/EmptyTable";
import TableCSVButton from "../TableCSVButton/TableCSVButton";

function LogsTable({
  totalCount,
  rowsPerPageOptions,
  rowsPerPage,
  isFiltering,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSelectLog,
  handleOpenDialog,
  page,
  data,
  hasFiltered,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event, log) => {
    setAnchorEl(event.currentTarget);
    handleSelectLog(log);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const headCells = [
    "Name",
    "Admin ID",
    "Request Method",
    "Activity Date",
    "Actions",
  ];
  const headers = ["Name", "Admin ID", "Request Method", "Activity Date"];

  let rows = [];
  if (data?.length > 0) {
    data?.forEach((item) => {
      rows.push([item?.name, item?.adminId, item?.requestMethod, item?.date]);
    });

    rows = [headers, ...rows];
  }

  return isFiltering ? (
    <TableLoader />
  ) : (
    <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
      {data?.length > 0 ? (
        <TableCSVButton data={rows} filename="logs.csv" />
      ) : null}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="logs table">
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell key={item} component="th" sx={{ fontWeight: 600 }}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.length > 0 &&
              data?.map((row, index) => (
                <TableRow key={`${row?.adminId}000${index}`}>
                  <TableCell>{row?.name}</TableCell>
                  <TableCell>{row?.adminId}</TableCell>
                  <TableCell>{row?.requestMethod}</TableCell>
                  <TableCell style={{ width: 180 }}>
                    {dayjs(row?.date).format("MMM D, YYYY hh:mm a")}
                  </TableCell>

                  <TableCell>
                    <IconButton onClick={(e) => handleClick(e, row)}>
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      row={row}
                      anchorEl={anchorEl}
                      PaperProps={{
                        elevation: 1,
                        sx: { boxShadow: "2px 2px 4px #eee" },
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => handleOpenDialog()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Preview Admin Log
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

export default LogsTable;
