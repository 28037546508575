import * as React from "react";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import NumberFormat from "react-number-format";
import { getReportSum, reportTotalStyles, whiteColorStyles } from "../data";

function DailySalesTotalRow({ data }) {
  return (
    <TableRow sx={reportTotalStyles}>
      <TableCell sx={whiteColorStyles}>Total</TableCell>
      <TableCell align="right">
        <NumberFormat
          value={getReportSum(data, "totalSold")}
          displayType={"text"}
          thousandSeparator={true}
          prefix={""}
          renderText={(value, props) => (
            <Typography sx={whiteColorStyles} align="left" {...props}>
              &#8358;{value}
            </Typography>
          )}
        />
      </TableCell>
      <TableCell align="right">
        <NumberFormat
          value={getReportSum(data, "ticketCount")}
          displayType={"text"}
          thousandSeparator={true}
          prefix={""}
          renderText={(value, props) => (
            <Typography sx={whiteColorStyles} align="left" {...props}>
              {value}
            </Typography>
          )}
        />
      </TableCell>
      <TableCell align="right">
        <NumberFormat
          value={getReportSum(data, "totalCommission")}
          displayType={"text"}
          thousandSeparator={true}
          prefix={""}
          renderText={(value, props) => (
            <Typography sx={whiteColorStyles} align="left" {...props}>
              &#8358;{value}
            </Typography>
          )}
        />
      </TableCell>
      <TableCell align="right">
        <NumberFormat
          value={getReportSum(data, "commissionCount")}
          displayType={"text"}
          thousandSeparator={true}
          prefix={""}
          renderText={(value, props) => (
            <Typography sx={whiteColorStyles} align="left" {...props}>
              {value}
            </Typography>
          )}
        />
      </TableCell>
      <TableCell align="right">
        <NumberFormat
          value={getReportSum(data, "totalWon")}
          displayType={"text"}
          thousandSeparator={true}
          prefix={""}
          renderText={(value, props) => (
            <Typography sx={whiteColorStyles} align="left" {...props}>
              &#8358;{value}
            </Typography>
          )}
        />
      </TableCell>
      <TableCell align="right" colSpan={2}>
        <NumberFormat
          value={getReportSum(data, "profit")}
          displayType={"text"}
          thousandSeparator={true}
          prefix={""}
          renderText={(value, props) => (
            <Typography sx={whiteColorStyles} align="left" {...props}>
              &#8358;{value}
            </Typography>
          )}
        />
      </TableCell>
    </TableRow>
  );
}

export default DailySalesTotalRow;
