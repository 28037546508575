import React, { useState } from "react";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import BannerTable from "../components/BannerComponents/BannerTable";
import PageHeading from "../components/PageHeading/PageHeading";
import BannerFilter from "../components/BannerComponents/BannerFilter";
import axios from "axios";
import useSWR, { mutate } from "swr";
import {
  getAuthToken,
  checkExpiredAuthToken,
  AddButtonBoxStyles,
} from "../utils";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import BannerDialog from "../components/BannerComponents/BannerDialog";
import { AppContext } from "../context";

function Banners() {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [status, setStatus] = useState("");
  const [bannerTitle, setBannerTitle] = useState("");
  const [isFiltering, setIsFiltering] = useState(false);
  const [hasFiltered, setHasFiltered] = useState(false);
  const [isSubmittingDialog, setIsSubmittingDialog] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const history = useHistory();
  const { handleOpenSnackbar } = React.useContext(AppContext);

  const handleChangeStatus = (e) => setStatus(e.target.value);
  const handleChangeBannerTitle = (e) => setBannerTitle(e.target.value);

  const url = `${process.env.API_URL}banner/fetch-banners?page=${page}&limit=${rowsPerPage}`;

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const rowsPerPageOptions = [20, 50, 100, 200];

  let filterURL = `${
    process.env.API_URL
  }banner/fetch-banners?page=${page}&limit=${rowsPerPage}${
    bannerTitle && `&search=${bannerTitle}`
  }`;

  if (status) {
    filterURL = filterURL + `&status=${status}`;
  }
  const hadleResetFields = () => {
    setStatus("");
    setBannerTitle("");
  };

  const handleSelectBanner = (value) => setSelectedBanner(value);
  const handleCloseDialog = () => setOpenDialog(false);
  const handleOpenDialog = () => setOpenDialog(true);

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data;
      });
  };

  const handleFiterSubmit = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setIsFiltering(true);
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res?.data?.data);
        mutate(url, res.data?.data, false);
        hadleResetFields();
        setIsFiltering(false);
      })
      .catch((err) => {
        setIsFiltering(false);
        return err;
      });
  };

  const handleDialogSubmit = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    let submitURL = selectedBanner?.status
      ? `${process.env.API_URL}banner/deactivate-banner/${selectedBanner?.bannerId}`
      : `${process.env.API_URL}banner/activate-banner/${selectedBanner?.bannerId}`;

    setIsSubmittingDialog(true);
    return axios
      .post(
        submitURL,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setIsSubmittingDialog(false);
        mutate(url);
        handleCloseDialog();
        if (res?.data?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.data?.message,
            severity: "success",
          });
        }
        return handleOpenSnackbar({
          message: "Operation Was Successful",
          severity: "success",
        });
      })
      .catch((err) => {
        setIsSubmittingDialog(false);

        if (err?.response?.data?.message?.msg) {
          return handleOpenSnackbar({
            message: err?.response?.data?.message?.msg,
            severity: "error",
          });
        }
        if (err?.response?.data?.message) {
          return handleOpenSnackbar({
            message: err?.response?.data?.message,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: err?.message,
          severity: "error",
        });
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL + `?limit=${rowsPerPage}&page=${newPage + 1}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        mutate(url, res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { data, error } = useSWR(url, fetcher);

  if (error)
    return (
      <FullScreenError
        title="Sorry, the requested data cannot be fetched at this momment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;
  return (
    <AdminLayout>
      <BannerDialog
        handleCloseDialog={handleCloseDialog}
        openDialog={openDialog}
        selectedBanner={selectedBanner}
        isSubmittingDialog={isSubmittingDialog}
        handleDialogSubmit={handleDialogSubmit}
      />
      <MetaDecorator title="Banners Table - Admin" />
      <Container maxWidth="xl">
        <PageHeading title="banners" />
        <BannerFilter
          status={status}
          bannerTitle={bannerTitle}
          handleChangeStatus={handleChangeStatus}
          handleChangeBannerTitle={handleChangeBannerTitle}
          handleFiterSubmit={handleFiterSubmit}
        />
        <Box sx={AddButtonBoxStyles}>
          <Button
            variant="contained"
            color="success"
            onClick={() => history.push("/banners/add")}
          >
            Add Banner
          </Button>
        </Box>
        <BannerTable
          totalCount={data?.totalCount}
          status={status}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          isFiltering={isFiltering}
          handleChangePage={handleChangePage}
          bannerTitle={bannerTitle}
          selectedBanner={selectedBanner}
          page={page}
          data={data?.data}
          handleSelectBanner={handleSelectBanner}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleOpenDialog={handleOpenDialog}
        />
      </Container>
    </AdminLayout>
  );
}

export default Banners;
