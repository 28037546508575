import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import useSWR from "swr";
import axios from "axios";
import { FaBorderNone } from "react-icons/fa";
import { MdOutlineAdminPanelSettings, MdThumbUp } from "react-icons/md";
import { AiFillDislike } from "react-icons/ai";
import { RiDeleteBackFill } from "react-icons/ri";
import { checkExpiredAuthToken, getAuthToken } from "../../utils";
import "../../styles/analytics.scss";
import HalfErrorScreen from "../HalfErrorScreen/HalfErrorScreen";
import AnalyticsCard from "../AnalyticsCard/AnalyticsCard";

const url = `${process.env.API_URL}game/lottery/analytics`;

function GameAnalytics() {
  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };

  const { data, error } = useSWR(url, fetcher, {});

  if (error)
    return (
      <HalfErrorScreen
        title=" Sorry, games analytics data cannot be retrieved at this momment"
        subtitle={error.message}
      />
    );

  if (!data) return null;

  return (
    <Box mb={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <AnalyticsCard
            mainNumber={data && data?.totalLotteries}
            icon={<MdOutlineAdminPanelSettings className="cardIcon" />}
            text={
              data?.totalLotteries <= 1 ? "Games Category" : "Games Categories"
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AnalyticsCard
            mainNumber={data && data?.inactiveLotteries}
            icon={<FaBorderNone className="cardIcon" />}
            text={
              data && data?.inactiveLotteries <= 1
                ? "Inactive Game Category"
                : "Inactive Game Categories"
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AnalyticsCard
            mainNumber={data && data?.activeLotteries}
            icon={<MdThumbUp className="cardIcon" />}
            text={
              data && data?.activeLotteries
                ? "Active Game Category"
                : "Active Game Categories"
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AnalyticsCard
            error={true}
            mainNumber={data && data?.deletedLotteries}
            icon={<RiDeleteBackFill className="errorCardIcon" />}
            text={
              data && data?.deletedLotteries <= 1
                ? "Deleted Game Category"
                : "Deleted Game Categories"
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default GameAnalytics;
