import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export default function BannerDialog({
  openDialog,
  handleCloseDialog,
  selectedBanner,
  handleDialogSubmit,
  isSubmittingDialog,
}) {
  return (
    <div>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {selectedBanner?.status ? "Deactivate Banner" : "Activate Banner"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText my={1}>
            Do you realy want to{" "}
            {selectedBanner?.status ? "deactivate" : "activate"} this banner,
            click the contine button to continue or cancel button to stop this
            operations
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isSubmittingDialog}
            color="error"
            onClick={() => handleCloseDialog()}
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmittingDialog}
            onClick={() => handleDialogSubmit()}
            variant="contained"
            color="success"
          >
            {selectedBanner?.status ? "deactivate" : "activate"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
