import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import axios from "axios";
import useSWR from "swr";
import {
  checkExpiredAuthToken,
  getAuthToken,
  dialogLoaderStyles,
  previewSubTitleStyles,
  dialogMainTextStyles,
  previewDialogMainTitleStyle,
} from "../../utils";
import { AppContext } from "../../context";
import FullScreenError from "../FullScreenError/FullScreenError";
import FullScreenLoader from "../FullScreenLoader/FullScreenLoader";
import dayjs from "dayjs";

export default function PreviewAdminModal({
  openDialog,
  handleCloseDialog,
  selectedUser,
}) {
  const url = `${process.env.API_URL}admin/fetch-admin/${selectedUser?.adminId}`;

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };

  const { data, error } = useSWR(url, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  if (error)
    return (
      <Dialog open={openDialog} fullWidth={true} maxWidth={"sm"}>
        <DialogContent>
          <Box style={{ dialogLoaderStyles }}>
            <FullScreenError
              title=" Sorry, data cannot be retrieved at this momment"
              subtitle={error.message}
            />
          </Box>
        </DialogContent>
      </Dialog>
    );

  if (!data)
    return (
      <Dialog open={true} fullWidth={true} maxWidth={"sm"}>
        <DialogContent>
          <Box style={{ dialogLoaderStyles }}>
            <FullScreenLoader />
          </Box>
        </DialogContent>
      </Dialog>
    );
  return (
    <div>
      <Dialog
        open={openDialog}
        fullWidth={true}
        maxWidth={"sm"}
        onClose={handleCloseDialog}
      >
        <DialogTitle color="success" sx={previewDialogMainTitleStyle}>
          ADMIN PREVIEW
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box my={1}>
              <Box my={1}>
                <Typography variant="caption">Name:</Typography>
                <Typography sx={dialogMainTextStyles}>{data?.name}</Typography>
              </Box>
              <Box my={1}>
                <Typography variant="caption">Email:</Typography>
                <Typography sx={dialogMainTextStyles}>{data?.email}</Typography>
              </Box>
              <Box my={1}>
                <Typography variant="caption">Role:</Typography>
                <Typography sx={dialogMainTextStyles}>{data?.role}</Typography>
              </Box>

              <Box my={1}>
                <Typography variant="caption">
                  Email Verification Status:
                </Typography>
                <Typography sx={dialogMainTextStyles}>
                  {data?.hasVerifiedEmail ? (
                    <Chip color="success" label="Verified" size="small" />
                  ) : (
                    <Chip label="Not Verified" color="error" size="small" />
                  )}
                </Typography>
              </Box>
              <Box my={1}>
                <Typography variant="caption">Status:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  {data?.status ? (
                    <Chip color="success" label="Active" size="small" />
                  ) : (
                    <Chip label="Not Active" color="error" size="small" />
                  )}
                </Typography>
              </Box>

              <Box my={1}>
                <Typography variant="caption">Date Created:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  {dayjs(data?.createdAt).format("DD MMM YYYY")}
                </Typography>
              </Box>
              <Box my={1}>
                <Typography variant="caption">Last Updated:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  {dayjs(data?.updatedAt).format("DD MMM YYYY")}
                </Typography>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
