import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ReactApexChart from "react-apexcharts";

const ReportChart = ({ data }) => {
  let newData = { ...data };
  delete newData.sales;

  let lables = Object.keys(newData);
  const series = Object.values(newData);

  let options = {
    plotOptions: {
      pie: {
        customScale: 1.07,
      },
    },
    chart: {
      width: 380,
      type: "pie",
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  options.labels = lables;

  return (
    <Box
      id="chart"
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ReactApexChart
        options={options}
        series={series}
        type="pie"
        width={420}
      />
    </Box>
  );
};

export default ReportChart;
