import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import TableLoader from "../TableLoader/TableLoader";
import NoDataAvailable from "../EmptyTable/EmptyTable";
import NumberFormat from "react-number-format";

function UsersDownlinesTable({ data }) {
  const headCells = [
    "ID",
    "First Name",
    "Last Name",
    "Email",
    "Phone Number",
    "Earned Commision",
    "Wallet Balance",
    "Date Joined",
    "Status",
  ];

  return (
    <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="recent users table">
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell key={item} component="th" sx={{ fontWeight: 600 }}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.length > 0 &&
              data?.map((row) => (
                <TableRow key={row.userId}>
                  <TableCell>{row?.id}</TableCell>
                  <TableCell>{row?.firstname}</TableCell>
                  <TableCell>{row?.lastname}</TableCell>
                  <TableCell>{row?.email}</TableCell>
                  <TableCell>{row?.phone}</TableCell>
                  <TableCell>
                    <NumberFormat
                      value={row?.commissionBalance}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"NGN"}
                      renderText={(value, props) => (
                        <Typography align="left" {...props}>
                          {value}
                        </Typography>
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      value={row?.walletBalance}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"NGN"}
                      renderText={(value, props) => (
                        <Typography align="left" {...props}>
                          {value}
                        </Typography>
                      )}
                    />
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                    {dayjs(row.createdAt).format("MMM D, YYYY")}
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                    {row.status ? (
                      <Chip color="success" label="Active" size="small" />
                    ) : (
                      <Chip label="Not Active" color="error" size="small" />
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>
    </Box>
  );
}

export default UsersDownlinesTable;
