import * as React from "react";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import { labelStyles } from "../../utils";

function BookedCodesFilter({
  gameTitle,
  status,
  startDate,
  endDate,
  minAmount,
  maxAmount,
  handleChangeMinimumAmount,
  handleChangeMaximumAmount,
  handleChangeStartDate,
  handleChangeEndDate,
  handleChangeStatus,
  handleChangeGameTitle,
  handleFilterSubmit,
}) {
  return (
    <Box mb={3}>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={12} sm={4} md={3}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Ticket ID
            </InputLabel>
            <TextField
              value={gameTitle}
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => handleChangeGameTitle(e)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Minimum Staked Amount
            </InputLabel>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              value={minAmount}
              fullWidth
              onChange={(e) => handleChangeMinimumAmount(e)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Maximum Staked Amount
            </InputLabel>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              value={maxAmount}
              fullWidth
              onChange={(e) => handleChangeMaximumAmount(e)}
            />
          </FormControl>
        </Grid>
        {/* <Grid item xs={12} sm={4} md={3}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Ticket Status
            </InputLabel>
            <Select
              labelId="statusSelect"
              variant="outlined"
              id="statusSelect"
              value={status}
              onChange={(e) => handleChangeStatus(e)}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Non Active</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}
        <Grid item xs={12} sm={4} md={3}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Date Created (From)
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                size="small"
                // label="Last Login Date"
                value={startDate}
                onChange={(value) => {
                  handleChangeStartDate(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth size="small" />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Date Created (To)
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                size="small"
                // label="Date Joined"
                value={endDate}
                onChange={(value) => {
                  handleChangeEndDate(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth size="small" />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <Button
            color="success"
            size="large"
            variant="contained"
            endIcon={<SearchIcon />}
            onClick={() => handleFilterSubmit()}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default BookedCodesFilter;
