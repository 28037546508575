import React, { useState } from "react";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import GamesTable from "../components/GamesComponents/GamesTable";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";
import useSWR, { mutate } from "swr";
import {
  getAuthToken,
  checkExpiredAuthToken,
  limit,
  AddButtonBoxStyles,
} from "../utils";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import ToggleGameDialog from "../components/GamesComponents/ToggleGameDialog";
import { AppContext } from "../context";
import PreviewGameModal from "../components/GamesComponents/PreviewGameModal";
import GameAnalytics from "../components/GamesComponents/GameAnalytics";
import FullScreenError from "../components/FullScreenError/FullScreenError";

function Games() {
  const [page, setPage] = useState(1);
  const [status, setstatus] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [gameTitle, setGameTitle] = useState("");
  const [isFiltering, setIsFiltering] = useState(false);
  const [hasFiltered, setHasFiltered] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openToggleDialog, setOpenToggleDialog] = useState(false);
  const [selectedGame, setSelectedGame] = useState({});

  const [openPreviewGameDialog, setOpenPreviewGameDialog] = useState(false);
  const history = useHistory();

  const filterURL = `${process.env.API_URL}game/fetch-lotteries?limit=${rowsPerPage}&page=${page}`;
  const url = `${process.env.API_URL}game/fetch-lotteries?limit=${rowsPerPage}&page=${page}`;

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const rowsPerPageOptions = [20, 50, 100, 200];

  const handleResetFields = () => {
    setGameTitle("");
    setstatus("");
  };
  if (status) {
    filterURL = filterURL + `&status=${status}`;
  }
  if (gameTitle) {
    filterURL = filterURL + `&search=${gameTitle}`;
  }
  const handleChangeGameTitle = (e) => setGameTitle(e.target.value);
  const handleChangeStatus = (e) => setstatus(e.target.value);
  const handleCloseToggleGameDialog = () => setOpenToggleDialog(false);
  const handleOpenToggleGameDialog = () => setOpenToggleDialog(true);

  const { handleOpenSnackbar } = React.useContext(AppContext);
  const handleSelectGame = (game) => setSelectedGame(game);

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-api-key": `${process.env.ADMIN_API_KEY}`,
        },
      })
      .then((res) => {
        // console.log(res?.data);
        return res?.data?.data;
      });
  };

  const handleOpenPreviewGameDialog = () => {
    setOpenPreviewGameDialog(true);
    setOpenToggleDialog(false);
  };
  const handleClosePreviewGameDialog = () => {
    setOpenPreviewGameDialog(false);
  };

  const handleSubmitToggleGameStatus = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setIsSubmitting(true);
    let values = {
      status: selectedGame?.status ? false : true,
    };
    values.lotteryId = selectedGame?.lotteryId;
    axios
      .post(`${process.env.API_URL}game/toggle-lottery-status`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res?.data?.message);
        setIsSubmitting(false);
        if (res?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.message,
            severity: "success",
          });
        }

        mutate(url);
        return handleCloseToggleGameDialog();
      })
      .catch((err) => {
        setIsSubmitting(false);

        if (err?.response?.data?.responsemessage?.msg) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage?.msg,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, Game Could Not Be Deactivated",
          severity: "error",
        });
      });
  };

  const handleFiterSubmit = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setIsFiltering(true);
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res?.data?.data?.data);
        mutate(url, res.data?.data, false);
        hadleResetFields();
        setIsFiltering(false);
      })
      .catch((err) => {
        setIsFiltering(false);
        return err;
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    const token = getAuthToken();
    return axios
      .get(
        `${process.env.API_URL}game/fetch-lotteries?limit=${limit}&page=${
          page + 1
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // console.log(res?.data);
        return res?.data?.data;
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const { data, error } = useSWR(filterURL, fetcher);

  if (error)
    return (
      <FullScreenError
        title=" Sorry, we cannot retrieve games data at this momment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  return (
    <AdminLayout>
      <MetaDecorator title="Games Category Table - Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Games Category" />
        {openToggleDialog ? (
          <ToggleGameDialog
            openDialog={openToggleDialog}
            selectedGame={selectedGame}
            isSubmitting={isSubmitting}
            handleCloseDialog={handleCloseToggleGameDialog}
            handleSubmit={handleSubmitToggleGameStatus}
          />
        ) : null}
        {openPreviewGameDialog ? (
          <PreviewGameModal
            openDialog={openPreviewGameDialog}
            selectedGame={selectedGame}
            handleCloseDialog={handleClosePreviewGameDialog}
          />
        ) : null}
        <GameAnalytics />
        <Box sx={AddButtonBoxStyles}>
          <Button
            variant="contained"
            color="success"
            onClick={() => history.push("/games/add")}
          >
            Add New Category
          </Button>
        </Box>
        <GamesTable
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          data={data?.data}
          totalCount={data?.totalCount}
          selectedGame={selectedGame}
          handleSelectGame={handleSelectGame}
          handleChangePage={handleChangePage}
          handleOpenToggleGameDialog={handleOpenToggleGameDialog}
          handleOpenPreviewGameDialog={handleOpenPreviewGameDialog}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Container>
    </AdminLayout>
  );
}

export default Games;
