import React, { useContext, useRef, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Container from "@mui/material/Container";
import { Formik, Form } from "formik";
import * as yup from "yup";
import axios from "axios";
import { useParams } from "react-router-dom";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import { AppContext } from "../context";
import {
  AFRIKENO,
  BINGO_BALLS,
  BINGO_BALLS_BETOPTIONS,
  CASH_OUT,
  checkExpiredAuthToken,
  EAZY_WIN,
  FIVE_OF_NINETY,
  getAuthToken,
  KENO,
  labelStyles,
  labelStylesTwo,
  LOTTO,
  LOTTO_CONTINENTAL,
  LOTTO_CONTINENTAL_BET_OPTIONS,
  MARIO_KENO,
  MARIO_KENO_BETOPTIONS,
  META4,
  METRO,
  NORMAL_BONUS,
  PERFECT_BALLS,
  POOL_OF_FAME,
  POOL_OF_FAME_BETOPTIONS,
  SEVEN49_BETOPTIONS,
  SEVEN_FOUR_NINE,
  SIX49_BETOPTIONS,
  SIX_FOUR_NINE,
} from "../utils";
import useSWR, { mutate } from "swr";
import FormLoader from "../components/FormLoader/FormLoader";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  bonusTypes,
  depositRoundOptions,
  initialValues,
} from "../components/AddBonusConfiguration/initialvalues";

let bonusSchema = yup.object().shape({
  title: yup.string().required(),
  depositRound: yup.string().required().label("Deposit Round"),
  betType: yup.string().required().label("Bet Type"),
  expiration: yup.string().required().label("Expiration"),
  winCount: yup.number().positive().min(1).required().label("Win Count"),
  minimumDeposit: yup
    .number()
    .positive()
    .min(1)
    .required()
    .label("Minimum Deposit"),
  prize: yup.number().positive().min(1).required().label("Prize"),
  gamePlayCount: yup
    .number()
    .positive()
    .min(1)
    .required()
    .label("Game Play Count"),
});

function AddBonusConfiguration() {
  const [endTime, setEndTime] = useState(new Date());
  const [bonusSearchField, setBonusSearchField] = useState("");
  const [currentGameId, setCurrentGameId] = useState("");
  const [selectedGame, setSelectedGame] = useState({});
  const [betOption, setBetOption] = useState([]);
  const [gamesArray, setGamesArray] = useState([]);
  const [isGameSelected, setIsGameSelected] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [hasSearchedGames, setHasSearchedGames] = useState(false);
  const [loadingBetOption, setLoadingBetOption] = useState(false);
  const { handleOpenSnackbar } = useContext(AppContext);

  const optionsRef = useRef();

  const handleSubmit = (values, helpers) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    values.type = NORMAL_BONUS;
    values.gameType = selectedGame?.name;

    axios
      .post(`${process.env.API_URL}bonus/create-bonus`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        helpers.setSubmitting(false);
        helpers.resetForm(initialValues);
        if (res?.data?.data?.message?.msg) {
          return handleOpenSnackbar({
            message: res?.data?.data?.message?.msg,
            severity: "success",
          });
        }
        if (res?.data?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.data?.message,
            severity: "success",
          });
        }
        return handleOpenSnackbar({
          message: "Bonus Created Successfully",
          severity: "success",
        });
      })
      .catch((err) => {
        helpers.setSubmitting(false);
        if (err?.response?.data?.responsemessage?.msg) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage?.msg,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, Bonus Could Not Be Created",
          severity: "error",
        });
      });
  };

  const handleChangeSearch = (e) => {
    setBonusSearchField(e.target.value);
  };
  const url = `${
    process.env.API_URL
  }game/fetch-games?limit=${100}&search=${bonusSearchField}`;
  // const url = `${process.env.API_URL}game/fetch-games?limit=${20}`;

  const searchGamesData = async () => {
    try {
      setIsSearching(true);
      checkExpiredAuthToken();
      const token = getAuthToken();

      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setGamesArray(res?.data?.data?.data);
      setIsSearching(false);
      setHasSearchedGames(true);
      // console.log("ff", gamesArray);
    } catch (err) {
      setIsSearching(false);
      return err;
    }
  };

  const getBetOption = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingBetOption(true);
    return axios
      .get(
        `${process.env.API_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let { content } = res?.data?.data?.data;
        content = JSON.parse(content);
        setBetOption((prevState) => content);
        setLoadingBetOption((prevState) => false);
        setHasSearchedGames((prevState) => true);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const getOptions = async () => {
    // console.log("zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz");
    if (selectedGame?.Lottery?.category === FIVE_OF_NINETY) {
      return await getBetOption("five-ninety-bet-options");
    } else if (selectedGame?.Lottery?.category === "salary4life") {
      return await getBetOption("salary-for-life-bet-options");
    } else if (
      selectedGame?.Lottery?.category === "legendarylotto" ||
      selectedGame?.Lottery?.category === "legendary-lotto"
    ) {
      return await getBetOption("legendary-lotto-bet-options");
    } else if (selectedGame?.Lottery?.category === "mega-7") {
      return await getBetOption("mega-7-bet-options");
    } else if (selectedGame?.Lottery?.category === AFRIKENO) {
      getBetOption("afrikeno-bet-options");
    } else if (selectedGame?.Lottery?.category === EAZY_WIN) {
      getBetOption("eazy-win-bet-options");
    } else if (selectedGame?.Lottery?.category === KENO) {
      getBetOption("keno-bet-options");
    } else if (selectedGame?.Lottery?.category === SIX_FOUR_NINE) {
      getBetOption(SIX49_BETOPTIONS);
    } else if (selectedGame?.Lottery?.category === SEVEN_FOUR_NINE) {
      getBetOption(SEVEN49_BETOPTIONS);
    } else if (selectedGame?.Lottery?.category === META4) {
      getBetOption("meta4-bet-options");
    } else if (selectedGame?.Lottery?.category === CASH_OUT) {
      getBetOption("cash-out-bet-options");
    } else if (selectedGame?.Lottery?.category === PERFECT_BALLS) {
      getBetOption("perfect-balls-bet-options");
    } else if (selectedGame?.Lottery?.category === METRO) {
      getBetOption("metro-bet-options");
    } else if (selectedGame?.Lottery?.category === LOTTO) {
      getBetOption("lotto-bet-options");
    } else if (selectedGame?.Lottery?.category === POOL_OF_FAME) {
      getBetOption(POOL_OF_FAME_BETOPTIONS);
    } else if (selectedGame?.Lottery?.category === LOTTO_CONTINENTAL) {
      getBetOption(LOTTO_CONTINENTAL_BET_OPTIONS);
    } else if (selectedGame?.Lottery?.category === MARIO_KENO) {
      getBetOption(MARIO_KENO_BETOPTIONS);
    } else if (selectedGame?.Lottery?.category === BINGO_BALLS) {
      getBetOption(BINGO_BALLS_BETOPTIONS);
    }
  };
  const handleClick = async (game) => {
    setSelectedGame((prevState) => game);
    setCurrentGameId((prevState) => game?.gameId);
    setIsGameSelected((prev) => true);
    setTimeout(() => {
      optionsRef.current.click();
    }, 800);
  };

  return (
    <AdminLayout>
      <MetaDecorator title="Add New Bonus Configuration - Admin Dashboard" />
      <Container maxWidth="xl">
        <PageHeading title="New Bonus Configuration" />

        <div>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={bonusSchema}
            validateOnBlur={true}
            validateOnChange={true}
            //   onSubmit={(values) => console.log(values)}
            onSubmit={(values, helpers) => handleSubmit(values, helpers)}
          >
            {({
              handleChange,
              handleBlur,
              touched,
              errors,
              values,
              setFieldValue,
              isSubmitting,
            }) => (
              <Form noValidate>
                <Box my={2}>
                  <Typography gutterBottom>
                    To add a bonus, search for a game or click on the search
                    button without any text to get the most recent 100 games,
                    select a game from the result and complete filling the form,
                    and click on the Add Bonus button
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={10}>
                      <TextField
                        variant="outlined"
                        placeholder="Enter Game Title"
                        size="small"
                        fullWidth
                        onChange={(e) => handleChangeSearch(e)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button
                        size="large"
                        color="success"
                        disabled={isSearching}
                        variant="contained"
                        type="button"
                        onClick={() => searchGamesData()}
                      >
                        {isSearching ? "Searching" : "Search"}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
                {hasSearchedGames ? (
                  <Box>
                    {gamesArray && gamesArray?.length > 0 ? (
                      <Grid container spacing={1}>
                        {gamesArray.map((item, idx) => (
                          <Grid key={item?.gameId} item xs={6} sm={3} md={2}>
                            <Chip
                              label={item?.name}
                              color={
                                currentGameId === item?.gameId
                                  ? "success"
                                  : "default"
                              }
                              variant={
                                currentGameId === item?.gameId
                                  ? "filled"
                                  : "outlined"
                              }
                              onClick={() => {
                                handleClick(item);
                                setSelectedGame(item);
                                return optionsRef.current.click();
                              }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    ) : (
                      <Typography>
                        Sorry, no game match your search, search for a new game
                        again
                      </Typography>
                    )}
                  </Box>
                ) : isSearching ? (
                  <FormLoader />
                ) : null}
                <br />
                <button
                  style={{ display: "none" }}
                  type="button"
                  ref={optionsRef}
                  onClick={() => getOptions()}
                >
                  Fetch Options
                </button>
                <Box my={1}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      label="Enter Bonus Title"
                      error={touched.title && errors.title}
                      disabled={isGameSelected === false}
                      name="title"
                      variant="outlined"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.title && errors.title}
                    />
                  </FormControl>
                </Box>

                <Box my={1}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel
                      variant="outlined"
                      id="statusSelect"
                      sx={labelStyles}
                    >
                      Deposit Round
                    </InputLabel>
                    <Select
                      disabled={isGameSelected === false}
                      name="depositRound"
                      variant="outlined"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.depositRound}
                      error={touched.depositRound && errors.depositRound}
                      helperText={touched.depositRound && errors.depositRound}
                    >
                      {depositRoundOptions.map((item) => (
                        <MenuItem value={item} key={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box mt={2} mb={1}>
                  <Grid container spacing={2}>
                    <Grid xs={12} sm={6} item>
                      <FormControl variant="outlined" fullWidth>
                        {/* <InputLabel variant="outlined" sx={labelStylesTwo}>
                            Minimum Deposit
                          </InputLabel> */}
                        <TextField
                          label="Minimum Deposit"
                          name="minimumDeposit"
                          disabled={isGameSelected === false}
                          type="number"
                          variant="outlined"
                          fullWidth
                          onBlur={handleBlur}
                          value={values.minimumDeposit}
                          onChange={handleChange}
                          error={
                            touched.minimumDeposit && errors.minimumDeposit
                          }
                          helperText={
                            touched.minimumDeposit && errors.minimumDeposit
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid xs={12} sm={6} item>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          label="Prize"
                          disabled={isGameSelected === false}
                          name="prize"
                          type="number"
                          variant="outlined"
                          value={values.prize}
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.prize && errors.prize}
                          helperText={touched.prize && errors.prize}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={2} mb={2}>
                  <Grid container spacing={2}>
                    <Grid xs={12} sm={6} item>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          label="Win Count"
                          disabled={isGameSelected === false}
                          name="winCount"
                          type="number"
                          variant="outlined"
                          fullWidth
                          value={values.winCount}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.winCount && errors.winCount}
                          helperText={touched.winCount && errors.winCount}
                        />
                      </FormControl>
                    </Grid>
                    <Grid xs={12} sm={6} item>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          label="Game Play Count"
                          disabled={isGameSelected === false}
                          name="gamePlayCount"
                          type="number"
                          variant="outlined"
                          fullWidth
                          value={values.gamePlayCount}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.gamePlayCount && errors.gamePlayCount}
                          helperText={
                            touched.gamePlayCount && errors.gamePlayCount
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>

                <Box mt={2} my={1}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      label="Bonus Expiration Period (In Days)"
                      disabled={isGameSelected === false}
                      name="expiration"
                      type="number"
                      variant="outlined"
                      fullWidth
                      value={values.expiration}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={touched.expiration && errors.expiration}
                      helperText={touched.expiration && errors.expiration}
                    />
                  </FormControl>
                </Box>
                <Box mt={2} my={1}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      label="Description"
                      multiline
                      disabled={isGameSelected === false}
                      name="description"
                      variant="outlined"
                      fullWidth
                      value={values.description}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={touched.description && errors.description}
                      helperText={touched.description && errors.description}
                    />
                  </FormControl>
                </Box>

                <Box my={2}>
                  {loadingBetOption ? (
                    <>
                      <FormLoader />
                      <FormLoader />
                    </>
                  ) : betOption && betOption?.length > 0 ? (
                    <FormControl>
                      <FormLabel sx={labelStylesTwo}>Bet Type</FormLabel>
                      <RadioGroup
                        required
                        value={values.betType}
                        row
                        name="betType"
                        onChange={handleChange}
                      >
                        {betOption &&
                          betOption?.length > 0 &&
                          betOption?.map((item, idx) => (
                            <FormControlLabel
                              key={`${item?.name}ll${idx}`}
                              value={item?.value}
                              control={<Radio color="success" name="betType" />}
                              label={item?.name}
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  ) : null}
                </Box>
                <br />
                <Button
                  startIcon={
                    isSubmitting && (
                      <CircularProgress severity="success" size={"1rem"} />
                    )
                  }
                  disabled={isSubmitting}
                  type="submit"
                  size="large"
                  variant="contained"
                  color="success"
                >
                  {isSubmitting ? "Adding Bonus" : "Add Bonus"}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </AdminLayout>
  );
}

export default AddBonusConfiguration;
