import * as React from "react";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { labelStyles } from "../../utils";

function BannerFilter({
  bannerTitle,
  status,
  handleChangeStatus,
  handleChangeBannerTitle,
  handleFiterSubmit,
}) {
  return (
    <Box mb={3}>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={12} sm={6} lg={5}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" sx={labelStyles}>
              Enter title of banner
            </InputLabel>
            <TextField
              variant="outlined"
              size="small"
              value={bannerTitle}
              fullWidth
              onChange={(e) => handleChangeBannerTitle(e)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} lg={5}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" sx={labelStyles}>
              Status
            </InputLabel>
            <Select
              variant="outlined"
              value={status}
              onChange={(e) => handleChangeStatus(e)}
            >
              <MenuItem value={""}></MenuItem>
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Non Active</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} lg={2}>
          <Button
            size="large"
            color="success"
            variant="contained"
            endIcon={<SearchIcon />}
            onClick={() => handleFiterSubmit()}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default BannerFilter;
