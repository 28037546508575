import React from "react";
import { useHistory } from "react-router-dom";
import { checkExpiredAuthToken, getAuthToken } from "../utils";

export default function Index() {
  const history = useHistory();

  React.useEffect(() => {
    checkExpiredAuthToken();
    let token = getAuthToken();
    token ? history.replace("/dashboard") : history.push("/login");
  }, []);

  return <></>;
}
