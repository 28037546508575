import * as React from "react";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import InputLabel from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { labelStyles, WeekdayList } from "../../utils";

function GamesInstanceFilter({
  gameTitle,
  status,
  startTime,
  endTime,
  weekday,
  handleChangeStatus,
  handleChangeGameTitle,
  handleChangeStartTime,
  handleChangeWeekday,
  handleChangeEndTime,
  handleFilterSubmit,
}) {
  return (
    <Box mb={3}>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={12} sm={6} md={3}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Enter Game Name
            </InputLabel>
            <TextField
              value={gameTitle}
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => handleChangeGameTitle(e)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Game Start Time
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                size="small"
                ampm={true}
                // label="Last Login Date"
                value={startTime}
                onChange={(value) => {
                  handleChangeStartTime(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth size="small" />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Game End Time
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                size="small"
                // label="Last Login Date"
                value={endTime}
                onChange={(value) => {
                  handleChangeEndTime(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth size="small" />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
              Game Status
            </InputLabel>
            <Select
              labelId="statusSelect"
              variant="outlined"
              id="statusSelect"
              value={status}
              onChange={(e) => handleChangeStatus(e)}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Non Active</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="roleSelect" sx={labelStyles}>
              Weekdays
            </InputLabel>
            <Select
              labelId="roleSelect"
              variant="outlined"
              id="roleSelect"
              value={weekday}
              onChange={(e) => handleChangeWeekday(e)}
            >
              {WeekdayList.map((item) => (
                <MenuItem value={item?.value} key={item?.title}>
                  {item?.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Button
            size="large"
            variant="contained"
            endIcon={<SearchIcon />}
            color="success"
            onClick={() => handleFilterSubmit()}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default GamesInstanceFilter;
