export const headCells = [
  "",
  "5-90 Lotto",
  "Legendary Lotto",
  "Mega 7",
  "Salary 4 Life",
  "Afrikeno",
  "Eazy Win",
  "Profit",
];
export const headCellsTwo = [
  "Amount Sold",
  "No of Ticket Sold",
  "Total Commission",
  "No. of Commission",
  "Amount Won",
  "Profit",
];

export const DAILY = "DAILY";
export const WEEKLY = "WEEKLY";
export const MONTHLY = "MONTHLY";

export const renderDate = (value) => {
  if (value === DAILY) {
    return "Date";
  }
  if (value === WEEKLY) {
    return "Week";
  }
  return "Month";
};
export const getReportSum = (array, search) => {
  let initialValue = 0;

  for (let index = 0; index < array.length; index++) {
    let element = array[index][search];
    element = parseFloat(element);
    initialValue += element;
  }
  return initialValue.toFixed(0);
};

export const downloadBoxStyles = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  paddingRight: "4px",
};
export const reportTotalStyles = {
  color: "#ffffff",
  background:
    "linear-gradient(270deg, rgba(0,157,105,1) 0%, rgba(0,115,175,1) 45%, rgba(0,178,188,1) 100%)",
};
export const reportWinningStyles = {
  background: "#FFE15D",
};

export const reportCommStyles = {
  color: "#fff",
  background: "green",
};
export const whiteColorStyles = {
  color: "#ffffff",
};

export const dailySummary = {
  sales: 490800,
  winnings: 700000,
  commission: 341000,
  profit: 45000,
};

export const weeklySummary = {
  sales: 4908000,
  winnings: 200000,
  commission: 340000,
  profit: 450000,
};
export const monthlySummary = {
  sales: 49080000,
  winnings: 9020000,
  commission: 3410000,
  profit: 4500000,
};

export const DailySalesData = [
  {
    day: new Date().getDate() - 4,
    fiveNinety: 2000,
    legendaryLotto: 3000,
    mega7: 4000,
    salary4Life: 3500,
    afrikeno: 5000,
    eazyWin: 7000,
    fiveNinetyWinning: 2000,
    legendaryLottoWinning: 1000,
    mega7Winning: 1000,
    salary4LifeWinning: 1500,
    afrikenoWinning: 1000,
    eazyWinWinning: 1000,
    fiveNinetyComm: 2000,
    legendaryLottoComm: 1000,
    mega7Comm: 1000,
    salary4LifeComm: 1500,
    afrikenoComm: 1000,
    eazyWinComm: 1000,
    profit: 80000,
  },
];
