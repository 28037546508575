import React from "react";
import Box from "@mui/material/Box";
// import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";

import { formatGameDate } from "../../utils";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import NoDataAvailable from "../EmptyTable/EmptyTable";
import PreviewGameInstanceModal from "../GameInstanceComponents/PreviewGameInstanceModal";

function GameInstancesTable({ data }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedId, setSelectedId] = React.useState("");
  const [selectedGame, setSelectedGame] = React.useState("");
  const [openPreviewDialog, setOpenPreviewDialog] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const open = Boolean(anchorEl);
  const history = useHistory();

  const handleClick = (event, game) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(game.gameId);
    setSelectedGame(game);
  };
  const handleOpenPreviewGameDialog = () => setOpenPreviewDialog(true);
  const handleClosePreviewGameDialog = () => setOpenPreviewDialog(false);
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedId("");
  };

  const headCells = [
    "S/N",
    "Name",
    "Weekday",
    "Start Time",
    "End Time",
    "Draw Method",
    "Date Created",
    // "Status",
    "Actions",
  ];

  // console.log("sssssssssss", data);
  return (
    <Box component={Paper} sx={{ padding: "10px 5px" }}>
      {openPreviewDialog && (
        <PreviewGameInstanceModal
          handleCloseDialog={handleClosePreviewGameDialog}
          openDialog={openPreviewDialog}
          selectedGame={selectedGame}
        />
      )}
      <TableContainer>
        <Table
          sx={{ minWidth: 300 }}
          aria-label="Game instances table"
          //   size="small"
        >
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell
                  key={item}
                  component="th"
                  sx={{ fontWeight: 600 }}
                  //   colSpan={item === "Actions" ? 2 : 1}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length > 0
              ? data?.map((row, idx) => (
                  <TableRow key={row?.gameId}>
                    <TableCell>{idx + 1}</TableCell>
                    <TableCell>{row?.name}</TableCell>
                    <TableCell>{row?.weekday}</TableCell>
                    <TableCell>
                      {" "}
                      {formatGameDate(row?.startTime, true)}
                    </TableCell>
                    <TableCell> {formatGameDate(row?.endTime, true)}</TableCell>
                    <TableCell> {row?.drawMethod}</TableCell>
                    <TableCell style={{ width: 160 }}>
                      {dayjs(row?.createdAt).format("MMM D, YYYY")}
                    </TableCell>
                    {/* <TableCell style={{ width: 120 }}>
                      {row?.status ? (
                        <Chip color="success" label="Active" size="small" />
                      ) : (
                        <Chip label="Not Active" color="error" size="small" />
                      )}
                    </TableCell> */}
                    <TableCell>
                      <IconButton onClick={(e) => handleClick(e, row)}>
                        <MoreHorizIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        PaperProps={{
                          elevation: 1,
                          sx: { boxShadow: "2px 2px 4px #eee" },
                        }}
                        open={open}
                        onClose={handleClose}
                      >
                        <MenuItem
                          onClick={() => handleOpenPreviewGameDialog()}
                          sx={{ fontSize: "12.7px" }}
                        >
                          Preview Game
                        </MenuItem>
                        <Divider />

                        <MenuItem
                          onClick={() =>
                            history.push(
                              `/games/game-instance/more-details/${selectedGame?.gameId}`
                            )
                          }
                          sx={{ fontSize: "12.7px" }}
                        >
                          View More Details
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
        {data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>
    </Box>
  );
}

export default GameInstancesTable;
