/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Index from "../pages/Index";
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import Admins from "../pages/Admins";
import Notfound from "../pages/Notfound";
import Noaccess from "../pages/Noaccess";
import Banners from "../pages/Banners";
import AddBanner from "../pages/AddBanner";
import Transactions from "../pages/Transactions";
import Transaction from "../pages/Transaction";
import Tickets from "../pages/Tickets";
import Games from "../pages/Games";
import Users from "../pages/Users";
import AddGame from "../pages/AddGame";
import UsersDetailsPage from "../pages/UsersDetailsPage";
import AddAdmin from "../pages/AddAdmin";
import AddGameInstance from "../pages/AddGameInstance";
import GameDetailsPage from "../pages/GameDetailsPage";
import Agents from "../pages/Agents";
import AgentDetailsPage from "../pages/AgentDetailsPage";
import GameInstances from "../pages/GameInstances";
import TicketDetails from "../pages/TicketDetails";
import EditBanner from "../pages/EditBanner";
import EditGame from "../pages/EditGame";
import EditGameInstance from "../pages/EditGameInstance";
import GameInstanceDetails from "../pages/GameInstanceDetails";
import WithdrawalRequests from "../pages/WithdrawalRequests";
import DueRemissions from "../pages/DueRemissions";
import Logs from "../pages/Logs";
import GameConfigurations from "../pages/GameConfigurations";
import SMTPConfigurations from "../pages/SMTPConfigurations";
import BonusConfigurations from "../pages/BonusConfigurations";
import AgentRoles from "../pages/AgentRoles";
import AdminRoles from "../pages/AdminRoles";
import AdminDetails from "../pages/AdminDetails";
import EditAdmin from "../pages/EditAdmin";
import AddStaticPage from "../pages/AddStaticPage";
import StaticPages from "../pages/StaticPages";
import StaticPageDetails from "../pages/StaticPageDetails";
import EditStaticPage from "../pages/EditStaticPage";
import AddBonusConfiguration from "../pages/AddBonusConfiguration";
import BonusDetails from "../pages/BonusDetails";
import EditBonusConfiguration from "../pages/EditBonusConfiguration";
import AddAgent from "../pages/AddAgent";
import AdminLogs from "../pages/AdminLogs";
import SiteSettings from "../pages/SiteSettings";
import WithdrawalRequestDetails from "../pages/WithdrawalRequestDetails";
import SiteSettingsDetails from "../pages/SiteSettingsDetails";
import BookCodes from "../pages/BookCodes";
import BookingCodeDetails from "../pages/BookingCodeDetails";
import AddSiteConfig from "../pages/AddSiteConfig";
import UpdateSiteSetting from "../pages/UpdateSiteSetting";
import AvatarSettings from "../pages/AvatarSettings";
import AddMastersPage from "../pages/AddMastersPage";
import AddHowItWorks from "../pages/AddHowItWorks";
import LotteryImagesSettings from "../pages/LotteryImagesSettings";
import Reports from "../pages/Reports";
import DeviceAnalytics from "../pages/DeviceAnalytics";
import AgentsCommission from "../pages/AgentsCommission";
import AdminTransactions from "../pages/AdminTransactions";
import AddInstantGame from "../pages/AddInstantGame";
import EditInstantGame from "../pages/EditInstantGame";
import AddInfluencer from "../pages/AddInfluencer";
import Influencers from "../pages/Influencers";
import AddBundleBonus from "../pages/AddBundleBonus";
import Payouts from "../pages/Payouts";
import BundleBonuses from "../pages/BundleBonuses";
import BundleBonusDetails from "../pages/BundleBonusDetails";
import EditBundleBonus from "../pages/EditBundleBonus";
import AgentsReports from "../pages/AgentsReports";
import PlayerReports from "../pages/PlayerReports";
import SinglePlayerReports from "../pages/SinglePlayerReports";
import SingleAgentReports from "../pages/SingleAgentReports";
import CommissionReports from "../pages/CommissionReports";
import SingleAgentCommissionReports from "../pages/SingleAgentCommissionReports";
import BonusTickets from "../pages/BonusTickets";
import ExcludeBetTypes from "../pages/ExcludeBetTypes";
import AddClient from "../pages/AddClient";
import Clients from "../pages/Clients";
import EditClient from "../pages/EditClient";
import AttachClientGames from "../pages/AttachClientGames";
import ClientDetailsPage from "../pages/ClientDetailsPage";
import DettachClientGames from "../pages/DettachClientGames";

export default function Routes() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/banners/edit-banner/:bannerId"
          component={EditBanner}
        />
        <Route
          exact
          path="/games/create-game-instance/:lotteryId/:gameCategory"
          component={AddGameInstance}
        />
        <Route
          exact
          path="/games/create-instant-game/:lotteryId/:gameCategory"
          component={AddInstantGame}
        />
        <Route
          exact
          path="/games/more-details/:lotteryId"
          component={GameDetailsPage}
        />
        <Route
          exact
          path="/users/user-details/:userId"
          component={UsersDetailsPage}
        />
        <Route
          exact
          path="/agents/agent-details/:userId"
          component={AgentDetailsPage}
        />
        <Route
          exact
          path="/tickets/ticket-details/:ticketId"
          component={TicketDetails}
        />
        <Route
          exact
          path="/booked-codes/ticket-details/:ticketId/:bookingCode"
          component={BookingCodeDetails}
        />
        <Route
          exact
          path="/transactions/transaction-details/:transactionId"
          component={Transaction}
        />
        <Route
          exact
          path="/static-pages/static-pages/more-detail/:contentId"
          component={StaticPageDetails}
        />
        <Route exact path="/admins/admin-logs/:adminId" component={AdminLogs} />
        <Route
          exact
          path="/static-pages/static-pages/edit-static-page/:contentId"
          component={EditStaticPage}
        />
        <Route
          exact
          path="/games-instance/edit-game/:gameId/:gameCategory"
          component={EditGameInstance}
        />
        <Route
          exact
          path="/games-instance/edit-instant-game/:gameId/:gameCategory"
          component={EditInstantGame}
        />
        <Route
          exact
          path="/configurations/bonus/edit-bonus-detail/:bonusId"
          component={EditBonusConfiguration}
        />
        <Route
          exact
          path="/configurations/bonus/edit-bundle-bonus-detail/:bonusId"
          component={EditBundleBonus}
        />
        <Route
          exact
          path="/configurations/bonus/bonus-detail/:bonusId"
          component={BonusDetails}
        />
        <Route
          exact
          path="/configurations/bonus/bundle-bonus-detail/:bonusId"
          component={BundleBonusDetails}
        />
        <Route
          exact
          path="/configurations/site-settings/settings-details/:settingId"
          component={SiteSettingsDetails}
        />
        <Route
          exact
          path="/admins/more-details/:adminId"
          component={AdminDetails}
        />
        <Route
          exact
          path="/withdrawal-requests/withdrawal-request-details/:requestId"
          component={WithdrawalRequestDetails}
        />
        <Route exact path="/games/edit-game/:lotteryId" component={EditGame} />
        <Route exact path="/admins/edit-admin/:adminId" component={EditAdmin} />
        <Route
          exact
          path="/games/game-instance/more-details/:gameId"
          component={GameInstanceDetails}
        />
        <Route
          exact
          path="/games/add-how-it-works/:lotterySlug"
          component={AddHowItWorks}
        />
        <Route
          path="/player-reports/single-player/:userId/:userName"
          component={SinglePlayerReports}
        />
        <Route
          path="/agent-reports/single-player/:userId/:userName"
          component={SingleAgentReports}
        />
        <Route
          exact
          path="/client-games/edit-client/:clientId"
          component={EditClient}
        />
        <Route
          exact
          path="/client-games/client-details/:clientId"
          component={ClientDetailsPage}
        />
        <Route
          exact
          path="/client-games/attach-games/:clientId"
          component={AttachClientGames}
        />
        <Route
          exact
          path="/client-games/dettach-games/:clientId"
          component={DettachClientGames}
        />
        <Route
          path="/commission-reports/single-agent/:userId/:userName"
          component={SingleAgentCommissionReports}
        />
        <Route
          exact
          path="/games/add-masters-page/:lotterySlug"
          component={AddMastersPage}
        />
        <Route
          exact
          path="/agents/exclude-bettypes/:agentId"
          component={ExcludeBetTypes}
        />
        <Route
          exact
          path="/configurations/site-settings/add-setting"
          component={AddSiteConfig}
        />
        <Route
          exact
          path="/configurations/lottery-images"
          component={LotteryImagesSettings}
        />
        <Route exact path="/agents/commission" component={AgentsCommission} />
        <Route exact path="/client-games/add-client" component={AddClient} />
        <Route exact path="/client-games/all-clients" component={Clients} />
        <Route exact path="/commission-reports" component={CommissionReports} />
        <Route
          exact
          path="/configurations/site-settings/update-details/:settingId"
          component={UpdateSiteSetting}
        />
        <Route exact path="/games/add" component={AddGame} />
        <Route exact path="/player-reports" component={PlayerReports} />
        <Route exact path="/agent-reports" component={AgentsReports} />
        <Route exact path="/influencers/add" component={AddInfluencer} />
        <Route
          exact
          path="/influencers/add-bundle"
          component={AddBundleBonus}
        />
        <Route exact path="/device-analytics" component={DeviceAnalytics} />
        <Route exact path="/admins/add" component={AddAdmin} />
        <Route exact path="/banners/add" component={AddBanner} />
        <Route exact path="/users" component={Users} />
        <Route
          exact
          path="/admins-transactions"
          component={AdminTransactions}
        />
        <Route
          exact
          path="/withdrawal-requests"
          component={WithdrawalRequests}
        />
        <Route exact path="/booked-codes" component={BookCodes} />
        <Route exact path="/payouts" component={Payouts} />
        <Route exact path="/influencers" component={Influencers} />
        <Route exact path="/static-pages/add-page" component={AddStaticPage} />
        <Route exact path="/static-pages/all-pages" component={StaticPages} />
        <Route exact path="/due-remission" component={DueRemissions} />
        <Route exact path="/logs" component={Logs} />
        <Route
          exact
          path="/configurations/game"
          component={GameConfigurations}
        />
        <Route
          exact
          path="/configurations/bundle-bonus"
          component={BundleBonuses}
        />
        <Route
          exact
          path="/configurations/smtp-email"
          component={SMTPConfigurations}
        />
        <Route
          exact
          path="/configurations/avatar-settings"
          component={AvatarSettings}
        />
        <Route
          exact
          path="/configurations/bonus/add-bonus"
          component={AddBonusConfiguration}
        />{" "}
        <Route
          exact
          path="/configurations/bonus"
          component={BonusConfigurations}
        />
        <Route
          exact
          path="/configurations/site-settings"
          component={SiteSettings}
        />
        <Route exact path="/agents/add" component={AddAgent} />
        <Route exact path="/roles/admin-roles" component={AdminRoles} />
        <Route exact path="/roles/agent-roles" component={AgentRoles} />
        <Route exact path="/agents" component={Agents} />
        <Route exact path="/admins" component={Admins} />
        <Route exact path="/banners" component={Banners} />
        <Route exact path="/tickets" component={Tickets} />
        <Route exact path="/bonus-tickets" component={BonusTickets} />
        <Route exact path="/reports" component={Reports} />
        <Route exact path="/games-instance" component={GameInstances} />
        <Route exact path="/games" component={Games} />
        <Route exact path="/transactions" component={Transactions} />
        <Route exact path="/" component={Index} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route path="/login" component={Login} />
        <Route path="/no-access" component={Noaccess} />
        <Route component={Notfound} />
      </Switch>
    </Router>
  );
}
