import * as React from "react";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";
import { useParams } from "react-router-dom";
import useSWR, { mutate } from "swr";
import { getAuthToken, checkExpiredAuthToken, limit } from "../utils";
import { AppContext } from "../context";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import PreviewLogModal from "../components/AdminLogsComponents/PreviewLogDialog";
import AdminLogsTable from "../components/AdminLogsComponents/AdminLogsTable";

function AdminLogs() {
  const [page, setPage] = React.useState(1);
  const [userName, setUserName] = React.useState("");
  const [isFiltering, setIsFiltering] = React.useState(false);
  const [hasFiltered, setHasFiltered] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(false);
  const [selectedLog, setSelectedLog] = React.useState({});

  const { adminId } = useParams();

  const { handleOpenSnackbar } = React.useContext(AppContext);

  const handleSelectLog = (user) => setSelectedLog(user);
  const handleCloseDialog = () => setOpenDialog(false);
  const handleOpenDialog = () => setOpenDialog(true);

  const url = `${process.env.API_URL}admin/fetch-activities?limit=${limit}&page=${page}&adminId=${adminId}`;

  let filterURL = `${
    process.env.API_URL
  }admin/fetch-activities?limit=${limit}&adminId=${adminId}&page=${page}${
    userName && `&search=${userName}`
  }`;

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data;
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(
        `${process.env.API_URL}admin/fetch-activities?limit=${limit}&page=${
          newPage + 1
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        mutate(url, res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { data, error } = useSWR(url, fetcher, {});

  if (error)
    return (
      <FullScreenError
        title=" Sorry, can't retrieve data at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;
  return (
    <AdminLayout>
      <MetaDecorator title="Admin Logs Table - Admin Dashboard" />
      <Container maxWidth="xl">
        <PageHeading title={`${data?.data[0]?.name} LOGS`} />
        {openDialog ? (
          <PreviewLogModal
            openDialog={openDialog}
            handleCloseDialog={handleCloseDialog}
            selectedLog={selectedLog}
          />
        ) : null}

        <AdminLogsTable
          isFiltering={isFiltering}
          hasFiltered={hasFiltered}
          selectedLog={selectedLog}
          handleChangePage={handleChangePage}
          handleSelectLog={handleSelectLog}
          handleOpenDialog={handleOpenDialog}
          userName={userName}
          page={page}
          data={data?.data}
          totalCount={data?.totalCount}
        />
      </Container>
    </AdminLayout>
  );
}

export default AdminLogs;
