import React, { useContext, useState } from "react";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import TicketsTable from "../components/TicketsComponent/TicketsTable";
import PageHeading from "../components/PageHeading/PageHeading";
import TicketsFilter from "../components/TicketsComponent/TicketsFilter";
import axios from "axios";
import dayjs from "dayjs";
import useSWR, { useSWRConfig } from "swr";
import { getAuthToken, checkExpiredAuthToken } from "../utils";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import ClearFilters from "../components/ClearFilters/ClearFilters";
import PreviewTicketModal from "../components/TicketsComponent/PreviewTicketModal";
import TicketsAnalytics from "../components/TicketsComponent/TicketsAnalytics";
import BlacklistTicketDialog from "../components/TicketsComponent/BlacklistTicketDialog";
import { AppContext } from "../context";
import HoldTicketDialog from "../components/TicketsComponent/HoldTicketDialog";

const walletType = "&walletType=bonusWallet";

function BonusTickets() {
  const [status, setStatus] = useState("");
  const [gameTitle, setGameTitle] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [isFiltering, setIsFiltering] = useState(false);
  const [hasFiltered, setHasFiltered] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState({});
  const [openPreviewTicket, setOpenPreviewTicket] = useState(false);
  const [openBlacklistDialog, setOpenBlacklistDialog] = useState(false);
  const [openHoldTicketDialog, setOpenHoldTicketDialog] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [minAmount, setMinAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);
  const [minWonAmount, setMinWonAmount] = useState(0);
  const [maxWonAmount, setMaxWonAmount] = useState(0);
  const [userName, setUserName] = useState("");

  const { handleOpenSnackbar } = useContext(AppContext);
  const { mutate } = useSWRConfig();
  let filterURL = `${process.env.API_URL}game/fetch-tickets?limit=${rowsPerPage}&page=${page}${walletType}`;

  const handleChangeRowPerpage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const rowsPerPageOptions = [20, 50, 100, 200];
  // SessionStorage Variable Declarations

  let sStatus = "status";
  let sTicketName = "search";
  let sStartDate = "startDate";
  let sEndDate = "endDate";
  let sMinAmount = "minAmount";
  let sMaxAmount = "maxAmount";
  let sMinWonAmount = "minWonAmount";
  let sMaxWonAmount = "maxWonAmount";
  let sUserName = "userName";

  // change methods for search filters
  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
    sessionStorage.setItem(sStatus, e.target.value);
  };

  const handleChangeGameTitle = (e) => {
    setGameTitle(e.target.value);
    sessionStorage.setItem(sTicketName, e.target.value);
  };

  const handleChangeStartDate = (value) => {
    setStartDate(value);
    sessionStorage.setItem(sStartDate, value);
  };
  const handleChangeEndDate = (value) => {
    setEndDate(value);
    sessionStorage.setItem(sEndDate, value);
  };

  const handleChangeMinimumAmount = (e) => {
    setMinAmount(e.target.value);
    sessionStorage.setItem(sMinAmount, e.target.value);
  };

  const handleChangeMaximumAmount = (e) => {
    setMaxAmount(e.target.value);
    sessionStorage.setItem(sMaxAmount, e.target.value);
  };
  const handleChangeMinimumWonAmount = (e) => {
    setMinWonAmount(e.target.value);
    sessionStorage.setItem(sMinWonAmount, e.target.value);
  };

  const handleChangeMaximumWonAmount = (e) => {
    setMaxWonAmount(e.target.value);
    sessionStorage.setItem(sMaxWonAmount, e.target.value);
  };
  const handleChangeUserName = (e) => {
    setUserName(e.target.value);
    sessionStorage.setItem(sUserName, e.target.value);
  };

  const handleClearSessionStorage = () => {
    sessionStorage.removeItem(sStatus);
    sessionStorage.removeItem(sTicketName);
    sessionStorage.removeItem(sStartDate);
    sessionStorage.removeItem(sEndDate);
    sessionStorage.removeItem(sMinAmount);
    sessionStorage.removeItem(sMaxAmount);
    sessionStorage.removeItem(sMinWonAmount);
    sessionStorage.removeItem(sMaxWonAmount);
    sessionStorage.removeItem(sUserName);
  };

  const handleSetSessionStorage = () => {
    let ssStatus = sessionStorage.getItem(sStatus);
    if (ssStatus) {
      setStatus(ssStatus);
      setHasFiltered(true);
    }
    let ssTicketName = sessionStorage.getItem(sTicketName);
    if (ssTicketName) {
      setGameTitle(ssTicketName);
      setHasFiltered(true);
    }

    let ssStartDate = sessionStorage.getItem(sStartDate);
    if (ssStartDate) {
      setStartDate(ssStartDate);
      setHasFiltered(true);
    }
    let ssEndDate = sessionStorage.getItem(sEndDate);
    if (ssEndDate) {
      setEndDate(ssEndDate);
      setHasFiltered(true);
    }
    let ssMinAmount = sessionStorage.getItem(sMinAmount);
    if (ssMinAmount) {
      setMinAmount(ssMinAmount);
      setHasFiltered(true);
    }
    let ssMaxAmount = sessionStorage.getItem(sMaxAmount);
    if (ssMaxAmount) {
      setMaxAmount(ssMaxAmount);
      setHasFiltered(true);
    }
    let ssMinWonAmount = sessionStorage.getItem(sMinWonAmount);
    if (ssMinWonAmount) {
      setMinWonAmount(ssMinWonAmount);
      setHasFiltered(true);
    }
    let ssMaxWonAmount = sessionStorage.getItem(sMaxWonAmount);
    if (ssMaxWonAmount) {
      setMaxWonAmount(ssMaxWonAmount);
      setHasFiltered(true);
    }

    let ssUserName = sessionStorage.getItem(sUserName);
    if (ssUserName) {
      setUserName(ssUserName);
      setHasFiltered(true);
    }
    // Updating URL

    if (status !== "") {
      if (!filterURL.includes(sStatus)) {
        filterURL = filterURL + `&status=${status}`;
      }
    }

    if (minAmount) {
      if (!filterURL.includes(sMinAmount)) {
        filterURL = filterURL + `&minAmount=${minAmount}`;
      }
    }
    if (maxAmount) {
      if (!filterURL.includes(sMaxAmount)) {
        filterURL = filterURL + `&maxAmount=${maxAmount}`;
      }
    }
    if (minWonAmount) {
      if (!filterURL.includes(sMinWonAmount)) {
        filterURL = filterURL + `&minWonAmount=${minWonAmount}`;
      }
    }
    if (maxWonAmount) {
      if (!filterURL.includes(sMaxWonAmount)) {
        filterURL = filterURL + `&maxWonAmount=${maxWonAmount}`;
      }
    }
    if (userName) {
      if (!filterURL.includes(sUserName)) {
        filterURL = filterURL + `&userName=${userName}`;
      }
    }
    if (gameTitle) {
      if (!filterURL.includes(sTicketName)) {
        filterURL = filterURL + `&search=${gameTitle}`;
      }
    }
    if (startDate) {
      if (!filterURL.includes(sStartDate)) {
        filterURL =
          filterURL + `&startDate=${dayjs(startDate).format("DD/MM/YYYY")}`;
      }
    }
    if (endDate) {
      if (!filterURL.includes(sEndDate)) {
        filterURL =
          filterURL + `&endDate=${dayjs(endDate).format("DD/MM/YYYY")}`;
      }
    }
  };

  const handleChangeFilter = (event) => setShowFilter(event.target.checked);

  React.useEffect(() => {
    handleSetSessionStorage();

    return () => {
      handleClearSessionStorage();
    };
  }, []);

  const handleSelectTicket = (ticket) => setSelectedTicket(ticket);
  const handleClosePreviewTicket = () => setOpenPreviewTicket(false);
  const handleOpenPreviewTicket = () => setOpenPreviewTicket(true);
  const handleCloseBlacklistDialog = () => setOpenBlacklistDialog(false);
  const handleOpenBlacklistDialog = () => setOpenBlacklistDialog(true);
  const handleCloseHoldTicketDialog = () => setOpenHoldTicketDialog(false);
  const handleOpenHoldTicketDialog = () => setOpenHoldTicketDialog(true);

  const url = `${process.env.API_URL}game/fetch-tickets?limit=${rowsPerPage}&page=${page}${walletType}`;

  const handleResetFields = () => {
    handleClearSessionStorage();
    setStatus("");
    setGameTitle("");
    setStartDate(null);
    setEndDate(null);
    setMinAmount(0);
    setMaxAmount(0);
    setMinWonAmount(0);
    setMaxWonAmount(0);
    setUserName("");
  };

  const fetcher = () => {
    handleSetSessionStorage();
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data);
        return res?.data?.data;
      });
  };

  const handleFilterSubmit = () =>
    mutate(filterURL, async () => {
      try {
        handleSetSessionStorage();
        checkExpiredAuthToken();
        const token = getAuthToken();
        setPage(1);
        setIsFiltering(true);
        const res = await axios.get(filterURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setIsFiltering(false);
        setHasFiltered(true);
        // await mutate(filterURL, res.data?.data);
        return res.data?.data;
      } catch (err) {
        setIsFiltering(false);
        return err;
      }
    });

  const handleToggleBlacklistTicket = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setIsSubmitting(true);
    let values = {
      ticketId: selectedTicket?.ticketId,
    };
    const submitUrl =
      selectedTicket?.status === "blacklisted"
        ? `${process.env.API_URL}game/unblacklist-ticket`
        : `${process.env.API_URL}game/blacklist-ticket`;
    axios
      .post(submitUrl, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsSubmitting(false);
        if (res?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.message,
            severity: "success",
          });
        }
        handleOpenSnackbar({
          message: "Ticket Updated Successfully",
          severity: "success",
        });
        mutate(url);
        return handleCloseBlacklistDialog();
      })
      .catch((err) => {
        setIsSubmitting(false);

        if (err?.response?.data?.data?.message) {
          return handleOpenSnackbar({
            message: err?.response?.data?.data?.message,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, Ticket Could not be blacklisted",
          severity: "error",
        });
      });
  };

  const handleToggleHoldTicket = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setIsSubmitting(true);
    let values = {
      ticketId: selectedTicket?.ticketId,
    };
    const submitUrl =
      selectedTicket?.status === "blacklisted"
        ? `${process.env.API_URL}game/unblock-ticket`
        : `${process.env.API_URL}game/block-ticket`;
    axios
      .post(submitUrl, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsSubmitting(false);
        if (res?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.message,
            severity: "success",
          });
        }
        handleOpenSnackbar({
          message: "Ticket Updated Successfully",
          severity: "success",
        });
        mutate(url);
        return handleCloseBlacklistDialog();
      })
      .catch((err) => {
        setIsSubmitting(false);

        if (err?.response?.data?.data?.message) {
          return handleOpenSnackbar({
            message: err?.response?.data?.data?.message,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, Ticket Could not be blacklisted",
          severity: "error",
        });
      });
  };

  const handleChangePage = (event, newPage) => {
    setIsFiltering(true);
    handleSetSessionStorage();
    setPage(newPage + 1);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsFiltering(false);
        mutate(filterURL, res?.data?.data);
      })
      .catch((err) => {
        setIsFiltering(false);
        console.log(err);
      });
  };

  const handleCancelFilters = async () =>
    mutate(filterURL, async () => {
      try {
        filterURL = `${process.env.API_URL}game/fetch-tickets?limit=${rowsPerPage}&page=${page}${walletType}`;
        handleResetFields();
        checkExpiredAuthToken();
        const token = getAuthToken();

        const res = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setPage((curr) => 1);
        setHasFiltered((curr) => false);
        await mutate(filterURL, res.data?.data);
        return res.data?.data;
      } catch (err) {
        setIsFiltering(false);
        return err;
      }
    });

  const { data, error } = useSWR(filterURL, fetcher, {
    // revalidateIfStale: false,
    // revalidateOnMount: false,
    // dedupingInterval: 70000,
  });

  if (error)
    return (
      <FullScreenError
        title="Sorry, we cannot retrieve data at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  return (
    <AdminLayout>
      <MetaDecorator title="Bonus Tickets Table - Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Bonus Tickets" />
        {openPreviewTicket ? (
          <PreviewTicketModal
            openDialog={openPreviewTicket}
            selectedTicket={selectedTicket}
            handleCloseDialog={handleClosePreviewTicket}
          />
        ) : null}

        {openBlacklistDialog ? (
          <BlacklistTicketDialog
            isSubmitting={isSubmitting}
            handleSubmit={handleToggleBlacklistTicket}
            openDialog={openBlacklistDialog}
            selectedTicket={selectedTicket}
            handleCloseDialog={handleCloseBlacklistDialog}
          />
        ) : null}
        {openHoldTicketDialog ? (
          <HoldTicketDialog
            isSubmitting={isSubmitting}
            handleSubmit={handleToggleHoldTicket}
            openDialog={openHoldTicketDialog}
            selectedTicket={selectedTicket}
            handleCloseDialog={handleCloseHoldTicketDialog}
          />
        ) : null}
        <TicketsAnalytics />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <>
            {hasFiltered && <ClearFilters handleClick={handleCancelFilters} />}
          </>
          <FormControlLabel
            sx={{ marginLeft: "auto" }}
            control={
              <Switch
                color="success"
                checked={showFilter}
                onChange={handleChangeFilter}
                inputProps={{ "aria-label": "Show Filters" }}
              />
            }
            label="Show Filters"
          />
        </Box>
        {showFilter && (
          <TicketsFilter
            status={status}
            gameTitle={gameTitle}
            startDate={startDate}
            endDate={endDate}
            minAmount={minAmount}
            maxAmount={maxAmount}
            minWonAmount={minWonAmount}
            maxWonAmount={maxWonAmount}
            userName={userName}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
            handleChangeMinimumAmount={handleChangeMinimumAmount}
            handleChangeMaximumAmount={handleChangeMaximumAmount}
            handleChangeStatus={handleChangeStatus}
            handleChangeGameTitle={handleChangeGameTitle}
            handleChangeMinimumWonAmount={handleChangeMinimumWonAmount}
            handleChangeMaximumWonAmount={handleChangeMaximumWonAmount}
            handleChangeUserName={handleChangeUserName}
            handleFilterSubmit={handleFilterSubmit}
          />
        )}

        <TicketsTable
          data={data?.data}
          selectedTicket={selectedTicket}
          hasFiltered={hasFiltered}
          isFiltering={isFiltering}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          handleChangeRowPerpage={handleChangeRowPerpage}
          handleChangePage={handleChangePage}
          handleSelectTicket={handleSelectTicket}
          handleOpenPreviewTicket={handleOpenPreviewTicket}
          handleOpenBlacklistDialog={handleOpenBlacklistDialog}
          handleOpenHoldTicketDialog={handleOpenHoldTicketDialog}
          page={page}
          totalCount={data?.totalCount}
        />
      </Container>
    </AdminLayout>
  );
}

export default BonusTickets;
