import * as React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import CircularProgress from "@mui/material/CircularProgress";
import { useFormik } from "formik";
import * as yup from "yup";
import { AppContext } from "../context";
import { labelStyles, getAuthToken, checkExpiredAuthToken } from "../utils";
import Container from "@mui/material/Container";
import useSWR from "swr";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";
import PageEditor from "../components/AddStaticPageComponents/PageEditor";

function AddStaticPage() {
  const [bannerContent, setBannerContent] = React.useState("");
  const { handleOpenSnackbar } = React.useContext(AppContext);
  let initialValues = {
    title: "",
    description: "",
    content: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values, helpers) => {
      values.slug = "slug";
      checkExpiredAuthToken();
      const token = getAuthToken();
      return axios
        .post(`${process.env.API_URL}content/create-content`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          helpers.setSubmitting(false);
          helpers.resetForm({ values: initialValues });
          setBannerContent("");
          if (res?.data?.message) {
            return handleOpenSnackbar({
              message: res?.data?.message,
              severity: "success",
            });
          }
          return handleOpenSnackbar({
            message: "Content Page Created Successfully",
            severity: "success",
          });
        })
        .catch((err) => {
          helpers.setSubmitting(false);

          if (err?.response?.data?.responsemessage) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage,
              severity: "error",
            });
          }
          handleOpenSnackbar({
            message: "Sorry, Page Could Not Be Created",
            severity: "error",
          });
        });
    },
    validationSchema: yup.object().shape({
      title: yup.string().required().label("Title"),
      description: yup.string().required().label("Content Description"),
      content: yup.string().required().label("Content"),
    }),
  });
  return (
    <AdminLayout>
      <MetaDecorator title={`Add New Page- Admin Dashboard`} />
      <Container maxWidth="xl">
        <PageHeading title="Add New Page" />
        <Container maxWidth="md">
          <div>
            <form noValidate onSubmit={formik.handleSubmit}>
              <Box my={1}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel variant="outlined" sx={labelStyles}>
                    Enter Title
                  </InputLabel>
                  <TextField
                    error={formik.touched.title && formik.errors.title}
                    name="title"
                    variant="outlined"
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={formik.touched.title && formik.errors.title}
                  />
                </FormControl>
              </Box>
              <Box my={1}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel variant="outlined" sx={labelStyles}>
                    Description
                  </InputLabel>
                  <TextField
                    error={
                      formik.touched.description && formik.errors.description
                    }
                    name="description"
                    multiline
                    variant="outlined"
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                </FormControl>
              </Box>
              <Box my={1}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel variant="outlined" sx={labelStyles}>
                    Content
                  </InputLabel>
                  <PageEditor
                    bannerContent={bannerContent}
                    setBannerContent={setBannerContent}
                    placeHolder={"Enter the content for the page"}
                    formik={formik}
                  />
                  <FormHelperText error>
                    {formik.touched.content && formik.errors?.content}
                  </FormHelperText>
                </FormControl>
              </Box>
              <br />
              <Button
                startIcon={
                  formik.isSubmitting && (
                    <CircularProgress severity="success" size={"1rem"} />
                  )
                }
                type="submit"
                disabled={formik.isSubmitting}
                size="large"
                variant="contained"
                color="success"
              >
                {formik.isSubmitting ? "Adding Page" : "Add Page"}
              </Button>
            </form>
          </div>
        </Container>
      </Container>
    </AdminLayout>
  );
}

export default AddStaticPage;
