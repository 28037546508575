/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import * as React from "react";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
export default function Noaccess() {
  const history = useHistory();
  return (
    <div className="container">
      <br />
      <br />
      <br />
      <br />
      <br />
      <center>
        <h3>Oops! You are not authorized to access this page.</h3>
        <br />
        <Button
          variant="contained"
          onClick={() => history.push("/")}
          color="red"
          size="lg"
        >
          &larr; Back to home
        </Button>
      </center>
    </div>
  );
}
