import React from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";

const AnalyticsCard = ({ icon, mainNumber = 0, text, error = false }) => {
  const CardStyles = {
    width: "100%",
    minHeight: "115px",
  };

  const renderBackgroundColor = (error) => {
    if (error) return "rgba(253, 253, 188, 0.35)";
    return "rgba(185, 253, 188, 0.15)";
  };
  return (
    <Card style={CardStyles}>
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              display: "flex",
              width: "60px",
              height: "60px",
              background: renderBackgroundColor(error),
              borderRadius: "50%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {icon}
          </Box>
          <Box>
            <Typography
              variant="h6"
              color="text-primary"
              align="right"
              sx={{ fontWeight: "bold" }}
            >
              {mainNumber}
            </Typography>
            <Typography variant="subtitle2" color="text-secondary">
              {text}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AnalyticsCard;
