import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import useSWR, { mutate } from "swr";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";
import { useParams } from "react-router-dom";
import { getAuthToken, checkExpiredAuthToken } from "../utils";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import { AppContext } from "../context";
import dayjs from "dayjs";
import SiteSettingsDetailsTable from "../components/SiteSettingsDetailsComponents/SiteSettingsDetailsTable";

function SiteSettingsDetails() {
  const { settingId } = useParams();
  const [openApproveDialog, setOpenApproveDialog] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const { handleOpenSnackbar } = React.useContext(AppContext);

  const url = `${process.env.API_URL}site-settings/fetch-setting/${settingId}`;

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.data?.data);
        return res?.data?.data?.data;
      });
  };
  const { data, error } = useSWR(url, fetcher);

  if (error)
    return (
      <FullScreenError
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  //   console.log("dddddddddddddddd", data);

  return (
    <AdminLayout>
      <MetaDecorator title={`Site Settings's details - Admin Dashboard`} />
      <Container maxWidth="xl">
        <PageHeading title="Site Settings's details" />
        <Box my={1}>
          <Typography variant="caption">Title:</Typography>
          <Typography>{data?.title}</Typography>
        </Box>
        <Box my={1}>
          <Typography variant="caption">Slug:</Typography>
          <Typography>{data?.slug}</Typography>
        </Box>
        <Box my={1}>
          <Typography variant="caption">Date Created:</Typography>
          <Typography>
            {dayjs(data?.createdAt).format("DD, MMM YYYY hh:mm")}
          </Typography>
        </Box>
        <Box my={1}>
          <Typography variant="caption">Date Last Updated:</Typography>
          <Typography>
            {dayjs(data?.updatedAt).format("DD, MMM YYYY hh:mm")}
          </Typography>
        </Box>
        <br />
        <Typography variant="h6">Settings Content</Typography>
        <SiteSettingsDetailsTable data={data?.content} />
      </Container>
    </AdminLayout>
  );
}

export default SiteSettingsDetails;
