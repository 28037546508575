import React, { useContext, useRef, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Container from "@mui/material/Container";
import axios from "axios";
import { useParams } from "react-router-dom";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import { AppContext } from "../context";
import {
  checkExpiredAuthToken,
  getAuthToken,
  labelStylesTwo,
  safeJSONParse,
} from "../utils";
import FormLoader from "../components/FormLoader/FormLoader";

function ExcludeBetTypes() {
  const [bonusSearchField, setBonusSearchField] = useState("");
  const [currentGameId, setCurrentGameId] = useState("");
  const [selectedGame, setSelectedGame] = useState({});
  const [betOptions, setBetOptions] = useState([]);
  const [gamesArray, setGamesArray] = useState([]);
  const [isGameSelected, setIsGameSelected] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [hasSearchedGames, setHasSearchedGames] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { handleOpenSnackbar } = useContext(AppContext);
  const optionsRef = useRef();
  const { agentId } = useParams();

  const handleSubmit = async () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setIsSubmitting(true);
    const agentRes = await axios.get(
      `${process.env.API_URL}agent/fetch-agent/${agentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const filtered = agentRes?.data?.data?.data?.excludedAgentBetTypes?.filter(
      (item) => item?.lotteryId !== selectedGame?.lotteryId
    );

    const exclussion = [
      ...filtered,
      {
        lotteryId: selectedGame?.lotteryId,
        lotteryName: selectedGame?.name,
        betTypes: betOptions,
      },
    ];

    const payload = {
      excludedAgentBetTypes: exclussion,
    };

    axios
      .put(`${process.env.API_URL}agent/update-profile/${agentId}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsSubmitting(false);
        setSelectedGame(null);
        setCurrentGameId(null);
        if (res?.data?.data?.message?.msg) {
          return handleOpenSnackbar({
            message: res?.data?.data?.message?.msg,
            severity: "success",
          });
        }
        if (res?.data?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.data?.message,
            severity: "success",
          });
        }
        return handleOpenSnackbar({
          message: "Bet Types Exclussion Was Added Successfully",
          severity: "success",
        });
      })
      .catch((err) => {
        setIsSubmitting(false);
        if (err?.response?.data?.responsemessage?.msg) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage?.msg,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, Bet Types Could Not Be Excluded",
          severity: "error",
        });
      });
  };

  const handleChangeSearch = (e) => {
    setBonusSearchField(e.target.value);
  };
  const url = `${
    process.env.API_URL
  }game/fetch-lotteries?limit=${100}&search=${bonusSearchField}`;

  const searchGamesData = async () => {
    try {
      setIsSearching(true);
      checkExpiredAuthToken();
      const token = getAuthToken();

      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setGamesArray(res?.data?.data?.data);
      setIsSearching(false);
      setHasSearchedGames(true);
      // console.log("ff", gamesArray);
    } catch (err) {
      setIsSearching(false);
      return err;
    }
  };

  const handleClick = async (game) => {
    setSelectedGame((prevState) => game);
    setCurrentGameId((prevState) => game?.lotteryId);
    setIsGameSelected((prev) => true);
    setBetOptions([]);
  };
  const handleBetOptionChange = (e) => {
    const value = e.target.value;
    const index = betOptions?.indexOf(value);

    if (index === -1) {
      setBetOptions((prevValue) => [...prevValue, value]);
    } else {
      setBetOptions((prevValue) => prevValue.filter((item) => item !== value));
    }
  };
  return (
    <AdminLayout>
      <MetaDecorator title="Exclude Bet Types - Admin Dashboard" />
      <Container maxWidth="xl">
        <PageHeading title="Exclude Bet Types" />

        <div>
          <Box my={2}>
            <Typography gutterBottom>
              To add a bonus, search for a game or click on the search button
              without any text to get the most recent 100 games, select a game
              from the result and complete filling the form, and click on the
              Add Bonus button
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={10}>
                <TextField
                  variant="outlined"
                  placeholder="Enter Name of Lottery"
                  fullWidth
                  onChange={(e) => handleChangeSearch(e)}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  size="large"
                  color="success"
                  disabled={isSearching}
                  variant="contained"
                  type="button"
                  onClick={() => searchGamesData()}
                >
                  {isSearching ? "Searching" : "Search"}
                </Button>
              </Grid>
            </Grid>
          </Box>
          {hasSearchedGames ? (
            <Box>
              {gamesArray && gamesArray?.length > 0 ? (
                <Grid container spacing={1}>
                  {gamesArray.map((item, idx) => (
                    <Grid key={item?.gameId} item xs={6} sm={3} md={2}>
                      <Chip
                        label={item?.name}
                        color={
                          currentGameId === item?.lotteryId
                            ? "success"
                            : "default"
                        }
                        variant={
                          currentGameId === item?.lotteryId
                            ? "filled"
                            : "outlined"
                        }
                        onClick={() => {
                          handleClick(item);
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography>
                  Sorry, no lottery match your search, search for a new lottery
                  again
                </Typography>
              )}
            </Box>
          ) : isSearching ? (
            <FormLoader />
          ) : null}
          <br />
          <button
            style={{ display: "none" }}
            type="button"
            ref={optionsRef}
            onClick={() => getOptions()}
          >
            Fetch Options
          </button>
          <Box my={1}>
            <Box>
              {selectedGame &&
                safeJSONParse(selectedGame?.betOptions)?.length > 0 && (
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStylesTwo}>
                        Select Bet Types to Exclude
                      </InputLabel>
                      <br />
                      <FormGroup aria-label="bet types" row name="betTypes">
                        {safeJSONParse(selectedGame?.betOptions)?.map(
                          (item, idx) => (
                            <FormControlLabel
                              key={`${item?.name}p${idx}`}
                              value={item?.name}
                              control={
                                <Checkbox
                                  color="success"
                                  onChange={handleBetOptionChange}
                                  name="betTypes"
                                  checked={betOptions?.includes(item?.name)}
                                />
                              }
                              label={item?.name}
                            />
                          )
                        )}
                      </FormGroup>
                    </FormControl>
                  </Box>
                )}
            </Box>
          </Box>

          <br />
          <Button
            startIcon={
              isSubmitting && (
                <CircularProgress severity="success" size={"1rem"} />
              )
            }
            disabled={isSubmitting}
            type="submit"
            size="large"
            variant="contained"
            color="success"
            onClick={() => handleSubmit()}
          >
            {isSubmitting ? "Processing..." : "Submit"}
          </Button>
        </div>
      </Container>
    </AdminLayout>
  );
}

export default ExcludeBetTypes;
