import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export default function ToggleAdminDialog({
  openDialog,
  handleCloseDialog,
  selectedUser,
  isSubmitting,
  handleSubmit,
}) {
  return (
    <div>
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm">
        <DialogTitle>
          {selectedUser?.status
            ? "CONFIRM ADMIN ACCOUNT DEACTIVATION"
            : "CONFIRM ADMIN ACCOUNT ACTIVATION"}
        </DialogTitle>
        <DialogContent>
          <Box my={1} sx={{ minWidth: "310px" }}>
            {selectedUser?.status ? (
              <DialogContentText>
                Do you want to deactivate this account, click on the deactivate
                button to proceed or, click on the cancel button to ignore
              </DialogContentText>
            ) : (
              <DialogContentText>
                Do you want to activate this account, click on the activate
                button to proceed or, click on the cancel button to ignore
              </DialogContentText>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isSubmitting}
            color="error"
            onClick={() => handleCloseDialog()}
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            startIcon={isSubmitting && <CircularProgress size={"1rem"} />}
            onClick={() => handleSubmit()}
            variant="contained"
            color="success"
          >
            {isSubmitting ? (
              "Processing"
            ) : (
              <> {selectedUser?.status ? "Deactivate" : "Activate"} </>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
