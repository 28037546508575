import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import dayjs from "dayjs";
import TableLoader from "../TableLoader/TableLoader";
import NoDataAvailable from "../EmptyTable/EmptyTable";
import { limit, BUNDLE_BONUS, formatBonusType } from "../../utils";
import { useHistory } from "react-router-dom";
import { menuItemsStyles } from "../../utils";
import NumberFormat from "react-number-format";
import TableCSVButton from "../TableCSVButton/TableCSVButton";

function BonusTable({
  totalCount,
  selectedBonus,
  isFiltering,
  handleChangePage,
  page,
  data,
  handleSelectBonus,
  handleOpenToggleDialog,
  handleOpenAttachDialog,
  handleOpenDialog,
}) {
  const [selectedId, setSelectedId] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();

  const open = Boolean(anchorEl);

  const handleClick = (event, bonus) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(bonus?.bonusId);
    handleSelectBonus(bonus);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedId("");
  };

  const moveToEditBonusPage = () => {
    history.push(`/configurations/bonus/edit-bonus-detail/${selectedId}`);
  };
  const moveToViewBonusPage = () => {
    history.push(`/configurations/bonus/bonus-detail/${selectedId}`);
  };

  const headCells = [
    "Title",
    "Type",
    "Bet Type",
    "Game Play Count",
    "Game Type",
    "Minimum Deposit",
    "Prize",
    "Expiration (Days)",
    "Status",
    "Date Created",
    "Actions",
  ];
  const headers = [
    "Title",
    "Type",
    "Bet Type",
    "Game Play Count",
    "Game Type",
    "Minimum Deposit",
    "Prize",
    "Expiration (Days)",
    "Status",
    "Date Created",
  ];
  let rows = [];
  if (data?.length > 0) {
    data?.forEach((item) => {
      // console.log("phone", item.phone);
      rows.push([
        item?.title,
        item?.betType,
        item?.gamePlayCount,
        item?.gameType,
        item?.minimumDeposit,
        item?.prize,
        item?.expiration,
        item?.status,
        item?.createdAt,
      ]);
    });

    rows = [headers, ...rows];
  }
  return isFiltering ? (
    <TableLoader />
  ) : (
    <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
      {data?.length > 0 ? (
        <TableCSVButton data={rows} filename="bonuses.csv" />
      ) : null}
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 300 }}
          aria-label="static pages table"
          //   size="small"
        >
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell key={item} component="th" sx={{ fontWeight: 600 }}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.length > 0 &&
              data?.map((row) => (
                <TableRow key={row?.bonusId}>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>{formatBonusType(row.type)}</TableCell>
                  <TableCell>{row.betType}</TableCell>
                  <TableCell>{row.gamePlayCount}</TableCell>
                  <TableCell>{row.gameType}</TableCell>

                  <TableCell>
                    <NumberFormat
                      value={row?.minimumDeposit}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"NGN"}
                      renderText={(value, props) => (
                        <Typography align="left" {...props}>
                          {value}
                        </Typography>
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      value={row?.prize}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"NGN"}
                      renderText={(value, props) => (
                        <Typography align="left" {...props}>
                          {value}
                        </Typography>
                      )}
                    />
                  </TableCell>
                  <TableCell style={{ width: 160 }}>{row.expiration}</TableCell>
                  <TableCell style={{ width: 160 }}>
                    {row?.status ? (
                      <Chip color="success" label="Active" size="small" />
                    ) : (
                      <Chip label="Not Active" color="error" size="small" />
                    )}
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                    {dayjs(row.createdAt).format("MMM D, YYYY")}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => handleClick(e, row)}>
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      PaperProps={{
                        elevation: 1,
                        sx: { boxShadow: "2px 2px 4px #eee" },
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => handleOpenDialog()}
                        sx={menuItemsStyles}
                      >
                        Preview Bonus
                      </MenuItem>
                      <Divider />

                      <MenuItem
                        onClick={() => moveToEditBonusPage()}
                        sx={menuItemsStyles}
                      >
                        Edit Bonus
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => handleOpenToggleDialog()}
                        sx={menuItemsStyles}
                      >
                        {selectedBonus?.status
                          ? "Deactivate Bonus"
                          : "Activate Bonus"}
                      </MenuItem>

                      <Divider />

                      <MenuItem
                        onClick={() => handleOpenAttachDialog()}
                        sx={menuItemsStyles}
                      >
                        Attach To Influencer
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => moveToViewBonusPage()}
                        sx={menuItemsStyles}
                      >
                        View More
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={totalCount}
        rowsPerPage={limit}
        page={page - 1}
        onPageChange={handleChangePage}
      />
    </Box>
  );
}

export default BonusTable;
