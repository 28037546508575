import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import useSWR from "swr";
import axios from "axios";
import { MdFlightTakeoff, MdThumbUp } from "react-icons/md";
import { AiFillDislike } from "react-icons/ai";
import { GiMissileSwarm, GiPodiumWinner } from "react-icons/gi";
import { checkExpiredAuthToken, getAuthToken } from "../../utils";
import "../../styles/analytics.scss";
import HalfErrorScreen from "../HalfErrorScreen/HalfErrorScreen";
import MoneyAnalyticsCard from "../MoneyAnalyticsCard/MoneyAnalyticsCard";

const url = `${process.env.API_URL}game/ticket/analytics`;

function WithdrawalRequestsAnalytics() {
  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };

  const { data, error } = useSWR(url, fetcher, {});

  if (error)
    return (
      <HalfErrorScreen
        title=" Sorry, tickets analytics data cannot be retrieved at this momment"
        subtitle={error.message}
      />
    );

  if (!data) return null;
  return (
    <Box mb={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <MoneyAnalyticsCard
            icon={<MdFlightTakeoff className="cardIcon" />}
            moneyText={"Total Amount"}
            countText={"Total Tickets"}
            countValue={data?.totalTickets}
            moneyValue={data?.totalSaleAmount}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MoneyAnalyticsCard
            icon={<GiPodiumWinner className="cardIcon" />}
            moneyText={"Amount"}
            countText={"Won Tickets"}
            countValue={data?.totalWonTickets}
            moneyValue={data?.totalWonTicketAmount}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MoneyAnalyticsCard
            icon={<GiMissileSwarm className="errorCardIcon" />}
            moneyText={"Amount"}
            countText={"Lost Tickets"}
            countValue={data?.totalLostTickets}
            moneyValue={
              data?.totalWonTicketAmount > 0
                ? data?.totalSaleAmount - data?.totalWonTicketAmount
                : 0
            }
            error={true}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default WithdrawalRequestsAnalytics;
