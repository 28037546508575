import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import dayjs from "dayjs";
import NumberFormat from "react-number-format";
import NoDataAvailable from "../EmptyTable/EmptyTable";

function BetSlipsTable({ data }) {
  let betSlips = JSON.parse(data);
  const headCells = [
    "Bet Type",
    "Amount",
    "Booster Method",
    "Result Type",
    "Selection",
    "Over Under",
    "Agent Commission",
    "Affiliate",
  ];

  return (
    <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="recent users table">
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell key={item} component="th" sx={{ fontWeight: 600 }}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {betSlips &&
              betSlips?.length > 0 &&
              betSlips?.map((row, idx) => (
                <TableRow key={`${row?.betType}99${idx}`}>
                  <TableCell>{row?.betType}</TableCell>
                  <TableCell>
                    <NumberFormat
                      value={row?.amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"NGN"}
                      renderText={(value, props) => (
                        <div {...props}>{value}</div>
                      )}
                    />
                  </TableCell>

                  <TableCell>
                    {row?.booster === "null" || !row?.booster
                      ? "-"
                      : row?.booster}
                  </TableCell>
                  <TableCell>
                    {row?.resultType === "null" || !row?.resultType
                      ? "-"
                      : row?.resultType}
                  </TableCell>
                  <TableCell>{row?.selections}</TableCell>
                  <TableCell>{row?.overUnder?.over}</TableCell>
                  <TableCell>{row?.agentCommission}</TableCell>
                  <TableCell>{row?.affiliate}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {betSlips && betSlips?.length <= 0 && <NoDataAvailable />}
      </TableContainer>
    </Box>
  );
}

export default BetSlipsTable;
