import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { AppContext } from "../../context";
import CircularProgress from "@mui/material/CircularProgress";
import { useFormik } from "formik";
import * as yup from "yup";
import ConfirmDebitAgentDialog from "./ConfirmDebitAgentAccount";

export default function DebitAgentAccountDialog({
  openDialog,
  handleCloseDialog,
  selectedUser,
  agentsURL,
}) {
  const { adminData } = React.useContext(AppContext);
  const [formValues, setFormValues] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [purposeWallet, setPurposeWallet] = React.useState("mainWallet");

  const handlePurposeChange = (event) => {
    setPurposeWallet(event.target.value);
  };

  const handleCloseConfirmFundingDialog = () => {
    handleCloseDialog();
    setOpen(false);
  };
  // const { handleOpenSnackbar } = React.useContext(AppContext);
  const options = [
    { value: "mainWallet", title: "Main Wallet" },
    { value: "commissionWallet", title: "Commission Wallet" },
    // { value: "bonusWallet", title: "Bonus Wallet" },
  ];

  const formik = useFormik({
    initialValues: {
      amount: 1,
      narration: "",
      debitedUserId: "",
    },
    onSubmit: (values, helpers) => {
      values.debitedUserId = selectedUser?.userId;
      values.targetWallet = purposeWallet;

      setFormValues((prev) => values);
      // helpers.setSubmitting(false);
      setOpen(true);
    },
    validationSchema: yup.object().shape({
      // payReference: yup.string().required().label("Payment Reference"),
      narration: yup.string().required().label("Narration"),
      amount: yup.number().positive().min(1).required().label("Amount"),
    }),
  });

  return (
    <div>
      {open ? (
        <ConfirmDebitAgentDialog
          values={formValues}
          selectedUser={selectedUser}
          agentsURL={agentsURL}
          handleFailure={handleCloseConfirmFundingDialog}
          openDialog={open}
        />
      ) : null}
      <Dialog
        fullWidth
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
      >
        <form noValidate onSubmit={formik.handleSubmit}>
          <DialogTitle sx={{ textAlign: "center" }}>
            DEBIT AGENT ACCOUNT
          </DialogTitle>
          <DialogContent>
            <Box my={3}>
              <FormControl>
                <FormLabel sx={{ color: "black" }} id="purposeForrFund">
                  Target account for Debiting
                </FormLabel>
                <RadioGroup
                  color="black"
                  row
                  aria-labelledby="purposeForFund"
                  name="purpose"
                  value={purposeWallet}
                  onChange={handlePurposeChange}
                >
                  {options.map((item) => (
                    <FormControlLabel
                      value={item.value}
                      control={<Radio />}
                      label={item.title}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
            <Box my={2}>
              <FormControl variant="outlined" fullWidth>
                {/* <InputLabel variant="outlined" narrati>Amount</InputLabel> */}
                <TextField
                  label="Amount"
                  error={formik.errors.amount && formik.touched.amount}
                  value={formik.values.amount}
                  name="amount"
                  type="number"
                  variant="outlined"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  helperText={formik.touched.amount && formik.errors.amount}
                />
              </FormControl>
            </Box>
            <Box my={2}>
              <FormControl variant="outlined" fullWidth>
                {/* <InputLabel variant="outlined">Narration</InputLabel> */}
                <TextField
                  label="Narration"
                  value={formik.values.narration}
                  error={formik.touched.narration && formik.errors.narration}
                  name="narration"
                  variant="outlined"
                  fullWidth
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.narration && formik.errors.narration
                  }
                />
              </FormControl>
            </Box>

            <br />
          </DialogContent>
          <DialogActions sx={{ paddingRight: "15px" }}>
            <Button
              type="button"
              disabled={formik.isSubmitting}
              color="error"
              onClick={() => handleCloseDialog()}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={formik.isSubmitting}
              startIcon={
                formik.isSubmitting && <CircularProgress size={"1rem"} />
              }
              variant="contained"
              color="primary"
            >
              {formik.isSubmitting ? "Processing" : "Debit Account"}
            </Button>
          </DialogActions>{" "}
        </form>
      </Dialog>
    </div>
  );
}
