import React, { useState } from "react";
import Container from "@mui/material/Container";
import AdminLayout from "../AdminLayout/AdminLayout";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ErrorImage from "../../images/errorpage.jpg";

function FullScreenError({
  mainTitle = "Oops, An Unexpected Error Occurred",
  title,
  subtitle,
  showMainTitle = true,
}) {
  return (
    <AdminLayout>
      <Container maxWidth="xl">
        <Box
          component={Paper}
          sx={{
            height: "80vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px 10px",
          }}
        >
          <img
            src={ErrorImage}
            alt="an error occured"
            style={{ width: "250px", height: "250px", marginBottom: "10px" }}
          />
          {showMainTitle && (
            <Typography gutterBottom variant="h5" sx={{ fontWeight: "bold" }}>
              {mainTitle}
            </Typography>
          )}
          <Typography gutterBottom variant="body1">
            {title}
          </Typography>
          <Typography gutterBottom variant="body1" sx={{ color: "red" }}>
            {subtitle}
          </Typography>
        </Box>
      </Container>
    </AdminLayout>
  );
}

export default FullScreenError;
