import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

const PageHeading = ({ title }) => {
  const theme = useTheme();
  return (
    <Box sx={{ mb: 4 }}>
      <Typography
        gutterBottom
        variant="h5"
        color="text.success"
        sx={{
          textTransform: "uppercase",
          color: `${theme.palette.success.main}`,
          fontWeight: 600,
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default PageHeading;
