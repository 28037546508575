import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import dayjs from "dayjs";
import NoDataAvailable from "../EmptyTable/EmptyTable";
import { limit } from "../../utils";
import { useHistory } from "react-router-dom";
import TableLoader from "../TableLoader/TableLoader";
import TableCSVButton from "../TableCSVButton/TableCSVButton";

function AdminsTable({
  data,
  page,
  isFiltering,
  hasFiltered,
  totalCount,
  selectedUser,
  handleChangePage,
  handleSelectUser,
  handleOpenPreviewAdminDialog,
  handleOpenToggleAdminDialog,
}) {
  const [selectedId, setSelectedId] = useState(""); // For user ID when the more icon is clicked
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const history = useHistory();

  const handleClick = (event, user) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(user?.adminId);
    handleSelectUser(user);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedId("");
  };

  const handleEditAdminRoutePage = () => {
    history.push(`/admins/edit-admin/${selectedId}`);
  };
  const handleViewAdminRoutePage = () => {
    history.push(`/admins/more-details/${selectedId}`);
  };

  const handleViewAdminLogsRoutePage = () => {
    history.push(`/admins/admin-logs/${selectedId}`);
  };

  const headCells = [
    "Full Name",
    "Email",
    "Role",
    "Date Created",
    "Status",
    "Actions",
  ];
  const headers = ["Full Name", "Email", "Role", "Date Created", "Status"];

  let rows = [];
  if (data?.length > 0) {
    data?.forEach((item) => {
      rows.push([
        item?.name,
        item?.email,
        item?.role,
        item?.createdAt,
        item?.status,
      ]);
    });

    rows = [headers, ...rows];
  }
  return isFiltering ? (
    <TableLoader />
  ) : (
    <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
      {data?.length > 0 ? (
        <TableCSVButton data={rows} filename="admins.csv" />
      ) : null}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="admin table">
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell key={item} component="th" sx={{ fontWeight: 600 }}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.length > 0 &&
              data?.map((row) => (
                <TableRow key={row.adminId}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  {/* <TableCell>{row.phone}</TableCell> */}
                  <TableCell>{row.role}</TableCell>
                  <TableCell style={{ width: 160 }}>
                    {dayjs(row.createdAt).format("MMM D, YYYY")}
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                    {row.status ? (
                      <Chip color="success" label="Active" size="small" />
                    ) : (
                      <Chip label="Not Active" color="error" size="small" />
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => handleClick(e, row)}>
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      PaperProps={{
                        elevation: 1,
                        sx: { boxShadow: "2px 2px 4px #eee" },
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => handleOpenPreviewAdminDialog()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Preview Details
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => handleOpenToggleAdminDialog()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        {!selectedUser?.status
                          ? "Activate Account"
                          : "Deactivate Account"}
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => {
                          handleEditAdminRoutePage();
                        }}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Edit Details
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => handleViewAdminLogsRoutePage()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        View Logs
                      </MenuItem>{" "}
                      <Divider />
                      <MenuItem
                        onClick={() => handleViewAdminRoutePage()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        View More Details
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={totalCount}
        rowsPerPage={limit}
        page={page - 1}
        onPageChange={handleChangePage}
      />
    </Box>
  );
}

export default AdminsTable;
