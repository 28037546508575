import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { useHistory } from "react-router-dom";
import {
  getAuthToken,
  checkExpiredAuthToken,
  AddButtonBoxStyles,
} from "../utils";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import SiteSettingsTable from "../components/SiteSettingsComponents/SiteSettingsTable";

const limit = 24;
function SiteSettings() {
  const [page, setPage] = React.useState(1);

  let filterURL = `${process.env.API_URL}site-settings/fetch-settings?limit=${limit}&page=${page}`;
  const history = useHistory();

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res?.data?.data);
        return res?.data?.data;
      });
  };
  const handleChange = (event, value) => {
    setPage(value);
  };
  const { data, error } = useSWR(filterURL, fetcher, {
    // revalidateIfStale: false,
    // revalidateOnMount: false,
    dedupingInterval: 70000,
  });

  if (error)
    return (
      <FullScreenError
        title="Sorry, we cannot retrieve data at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  return (
    <AdminLayout>
      <MetaDecorator title="Site Settings Table - Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Site Settings" />
        <Box sx={AddButtonBoxStyles}>
          <Button
            variant="contained"
            color="success"
            onClick={() =>
              history.push("/configurations/site-settings/add-setting")
            }
          >
            Add New Setting
          </Button>
        </Box>
        <br />
        <SiteSettingsTable data={data?.data} totalCount={data?.totalCount} />
        <br />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Pagination
            count={Math.round(data?.totalCount / limit)}
            page={page}
            onChange={handleChange}
          />
        </Box>
      </Container>
    </AdminLayout>
  );
}

export default SiteSettings;
