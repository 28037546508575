import * as React from "react";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Chip from "@mui/material/Chip";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import axios from "axios";
import useSWRImmutable from "swr/immutable";
import {
  checkExpiredAuthToken,
  getAuthToken,
  dialogLoaderStyles,
  previewSubTitleStyles,
  dialogMainTextStyles,
  previewDialogMainTitleStyle,
} from "../../utils";
import { AppContext } from "../../context";
import FullScreenError from "../FullScreenError/FullScreenError";
import FullScreenLoader from "../FullScreenLoader/FullScreenLoader";
import dayjs from "dayjs";

export default function GameForecastModal({
  openDialog,
  handleCloseDialog,
  selectedGame,
}) {
  const url = `${process.env.API_URL}game/fetch-forecast/${selectedGame?.gameId}`;
  const { handleOpenSnackbar } = React.useContext(AppContext);

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        handleOpenSnackbar({
          message: res.data?.data?.message,
          severity: "success",
        });
        return res?.data?.data?.data;
      });
  };

  const { data, error } = useSWRImmutable(url, fetcher);

  if (error)
    return (
      <Dialog
        open={openDialog}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleCloseDialog}
      >
        <DialogContent>
          <Box style={{ dialogLoaderStyles }}>
            <FullScreenError
              showMainTitle={
                error?.response?.data?.responsemessage ? true : true
              }
              mainTitle={
                error?.response?.data?.responsemessage &&
                error?.response?.data?.responsemessage
              }
              title={
                error?.response?.data?.responsemessage
                  ? " "
                  : "Sorry, game forecast data cannot be retrieved at this moment"
              }
              subtitle={
                error?.response?.data?.responsemessage
                  ? error?.response?.data?.responsemessage
                  : error.message
              }
            />
          </Box>
        </DialogContent>
      </Dialog>
    );

  if (!data)
    return (
      <Dialog open={true} fullWidth={true} maxWidth={"md"}>
        <DialogContent>
          <Box style={{ dialogLoaderStyles }}>
            <FullScreenLoader />
          </Box>
        </DialogContent>
      </Dialog>
    );
  console.log("selctionsijsoshskhshsjssjsk", data);
  const allSelections = data?.selections?.split("-");
  return (
    <div>
      <Dialog
        open={openDialog}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleCloseDialog}
      >
        <DialogTitle color="success" sx={previewDialogMainTitleStyle}>
          {data?.Game?.name}'s Forecast
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={2}>
              {typeof data === "object" && data?.selections?.length === 0 ? (
                <Grid item xs={12}>
                  <Box my={3}>
                    <Typography
                      align="center"
                      variant="h5"
                      sx={dialogMainTextStyles}
                    >
                      No Forecast yet
                    </Typography>
                  </Box>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Box my={1}>
                    <Box my={1}></Box>
                    <Box my={1}>
                      <Typography variant="body1" gutterBottom>
                        Forecast:
                      </Typography>

                      <Box
                        sx={{
                          width: "100%",
                          position: "relative",
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                        }}
                      >
                        {allSelections?.map((item, idx) => (
                          <Chip
                            key={item + idx}
                            label={item}
                            size="lg"
                            variant="outlined"
                            sx={{ margin: "5px" }}
                          />
                        ))}
                      </Box>

                      <br />
                      <Table>
                        <TableHead></TableHead>
                      </Table>
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
