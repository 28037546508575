import * as React from "react";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import axios from "axios";
import useSWR from "swr";
import {
  checkExpiredAuthToken,
  getAuthToken,
  dialogLoaderStyles,
  previewSubTitleStyles,
  dialogMainTextStyles,
  previewDialogMainTitleStyle,
  formatGameDate,
} from "../../utils";
import { AppContext } from "../../context";
import FullScreenError from "../FullScreenError/FullScreenError";
import FullScreenLoader from "../FullScreenLoader/FullScreenLoader";
import dayjs from "dayjs";
import NumberFormat from "react-number-format";

export default function PreviewBookedCodeModal({
  openDialog,
  handleCloseDialog,
  selectedTicket,
}) {
  const url = `${process.env.API_URL}game/fetch-ticket-by-code/${selectedTicket?.bookingCode}`;
  const modalChipTitleStyles = {
    display: "block",
  };
  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };
  // {
  //   "id": 113,
  //   "ticketId": "1648840902248",
  //   "referenceId": "TREF-2248-i9dfb",
  //   "userId": "e7880ebc-5aef-47a6-881a-00c30b2a8e7f",
  //   "bookingCode": "BNJ0902248",
  //   "linesCount": 1,
  //   "totalStakedAmount": "600.00",
  //   "totalWinAmount": null,
  //   "betSlips": "[{\"betType\":\"nap-2\",\"amount\":300,\"booster\":\"default\",\"resultType\":\"winning\",\"selections\":\"15-14\",\"overUnder\":\"\",\"potentialWinning\":\"144000\",\"agentCommission\":150,\"affiliate\":75}]",
  //   "cloned": false,
  //   "raffle": "[\"JS8DD\"]",
  //   "gameStatus": "ongoing",
  //   "winningRedemptionMethod": "wallet",
  //   "sourceWallet": "main-wallet",
  //   "details": "{}",
  //   "status": "0",
  //   "createdAt": "2022-04-01T19:21:42.000Z",
  //   "updatedAt": "2022-04-01T19:21:42.000Z",
  //   "gameId": "2c58b877-3db5-4c0e-83a2-cbb20ce14fcd",
  //   "User": {
  //     "firstname": "timi",
  //     "lastname": "dev",
  //     "phone": "08064655310",
  //     "email": "tt4@timiambaye.com",
  //     "role": "player",
  //     "isAgent": false,
  //     "status": true
  //   }
  // },
  const { data, error } = useSWR(url, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  if (error)
    return (
      <Dialog
        open={openDialog}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleCloseDialog}
      >
        <DialogContent>
          <Box style={{ dialogLoaderStyles }}>
            <FullScreenError
              title=" Sorry, ticket data cannot be retrieved at this moment"
              subtitle={error.message}
            />
          </Box>
        </DialogContent>
      </Dialog>
    );
  if (!data)
    return (
      <Dialog
        open={true}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleCloseDialog}
      >
        <DialogContent>
          <Box style={{ dialogLoaderStyles }}>
            <FullScreenLoader />
          </Box>
        </DialogContent>
      </Dialog>
    );
  return (
    <div>
      <Dialog
        open={openDialog}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleCloseDialog}
      >
        <DialogTitle color="success" sx={previewDialogMainTitleStyle}>
          BOOKED TICKET PREVIEW
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box my={1}>
                  <Typography gutterBottom sx={previewSubTitleStyles}>
                    Player Information
                  </Typography>
                  <Box my={1}>
                    <Typography variant="caption">Name:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.User?.firstname} &nbsp; {data?.User?.lastname}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Email:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.User?.email}{" "}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Phone Number:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.User?.phone}{" "}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Role:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.User?.role}{" "}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption" sx={modalChipTitleStyles}>
                      Player Status:
                    </Typography>
                    {/* <Typography sx={dialogMainTextStyles}> */}
                    {data?.User?.status ? (
                      <Chip color="success" label="Active" size="small" />
                    ) : (
                      <Chip label="Not Active" color="error" size="small" />
                    )}
                    {/* </Typography> */}
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Date Joined:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {dayjs(data?.User?.createdAt).format("D MMM, YYYY")}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box my={1}>
                  <Typography gutterBottom sx={previewSubTitleStyles}>
                    Ticket Information
                  </Typography>
                  <Box my={1}>
                    <Typography variant="caption">Ticket ID:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.ticketId}{" "}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">
                      Total Staked Amount:
                    </Typography>
                    <NumberFormat
                      value={data?.totalStakedAmount}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"NGN"}
                      renderText={(value, props) => (
                        <Typography
                          sx={dialogMainTextStyles}
                          align="left"
                          {...props}
                        >
                          {value}
                        </Typography>
                      )}
                    />
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Total Win Amount:</Typography>
                    <NumberFormat
                      value={data?.totalWinAmount}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"NGN"}
                      renderText={(value, props) => (
                        <Typography
                          sx={dialogMainTextStyles}
                          align="left"
                          {...props}
                        >
                          {value}
                        </Typography>
                      )}
                    />
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Lines Count:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.linesCount}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Reference ID:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.referenceId}{" "}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption" sx={modalChipTitleStyles}>
                      Ticket Status:
                    </Typography>

                    {data?.status ? (
                      <Chip color="success" label="Active" size="small" />
                    ) : (
                      <Chip label="Not Active" color="error" size="small" />
                    )}
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">
                      Winning Redemption Method:
                    </Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.winningRedemptionMethod}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Date Created:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {dayjs(data?.createdAt).format("D MMM, YYYY hh:mm a")}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box my={2}>
              <Typography gutterBottom sx={previewSubTitleStyles}>
                Game Information
              </Typography>
              <Box my={1}>
                <Typography variant="caption">Name:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  {data?.Game?.name}
                </Typography>
              </Box>
              <Box my={1}>
                <Typography variant="caption">Game Draw Method:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  {data?.Game?.drawMethod}
                </Typography>
              </Box>
              <Box my={1}>
                <Typography variant="caption">Start Time:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  {formatGameDate(data?.Game?.startTime, true)}
                </Typography>
              </Box>
              <Box my={1}>
                <Typography variant="caption">End Time:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  {formatGameDate(data?.Game?.endTime, true)}
                </Typography>
              </Box>
              <Box my={1}>
                <Typography variant="caption">Weekday:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  {data?.Game?.weekday}
                </Typography>
              </Box>
              <Box my={1}>
                <Typography variant="caption">Game Description:</Typography>
                <Typography sx={dialogMainTextStyles}>
                  {data?.Game?.description}
                </Typography>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
