import React, { useContext, useState } from "react";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import UsersTable from "../components/UsersComponents/UsersTable";
import PageHeading from "../components/PageHeading/PageHeading";
import UsersFilter from "../components/UsersComponents/UsersFilter";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { useHistory } from "react-router-dom";
import {
  getAuthToken,
  checkExpiredAuthToken,
  AddButtonBoxStyles,
} from "../utils";
import { AppContext } from "../context";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import ClearFilters from "../components/ClearFilters/ClearFilters";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import PreviewPlayerModal from "../components/UsersComponents/PreviewPlayerModal";
import DeactivateUserDialog from "../components/UsersComponents/DeactivateUserDialog";
import FundUserAccountDialog from "../components/UsersComponents/FundUserAccountDialog";
import DebitUserAccountDialog from "../components/UsersComponents/DebitUserAccount";
import AactivateUserDialog from "../components/UsersComponents/ActivateUserDialog";
import UsersAnalytics from "../components/UsersComponents/UsersAnalytics";
import dayjs from "dayjs";
import InfluencersTable from "../components/InfluencersComponents/InfluencersTable";
import InfluencersFilter from "../components/InfluencersComponents/InfluencersFilter";

function Influencers() {
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState("");
  const [userName, setUserName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [userRole, setUserRole] = useState("");
  const [isFiltering, setIsFiltering] = useState(false);
  const [hasFiltered, setHasFiltered] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDebitAccountDialog, setOpenDebitAccountDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openDeactivateDialog, setOpenDeactivateDialog] = useState(false);
  const [openActivateDialog, setOpenActivateDialog] = useState(false);
  const [openFundUserDialog, setOpenFundUserDialog] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedUserInfo, setSelectedUserInfo] = useState({});
  const [loginDate, setLoginDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [minimumWalletBal, setMinimumWalletBal] = useState(0);
  const [maximumWalletBal, setMaximumWalletBal] = useState(0);

  const { handleOpenSnackbar } = useContext(AppContext);
  const history = useHistory();
  // SessionStorage Variable Declarations

  let sStatus = "status";
  let sUserName = "userName";
  let sLoginDate = "loginDate";
  let sStartDate = "startDate";
  let sEndDate = "endDate";
  let sMinimumBalance = "minimumBalance";
  let sMaximumBalance = "maximumBalance";

  const url = `${process.env.API_URL}user/fetch-users?role=influencer&limit=${rowsPerPage}&page=${page}`;

  let filterURL = `${process.env.API_URL}user/fetch-users?role=influencer&limit=${rowsPerPage}&page=${page}`;

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const rowsPerPageOptions = [20, 50, 100, 200];

  // change methods for search filters
  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
    sessionStorage.setItem(sStatus, e.target.value);
  };
  const handleChangeUserName = (e) => {
    setUserName(e.target.value);
    sessionStorage.setItem(sUserName, e.target.value);
  };

  const handleChangeLoginDate = (value) => {
    setLoginDate(value);
    sessionStorage.setItem(sLoginDate, value);
  };

  const handleChangeStartDate = (value) => {
    setStartDate(value);
    sessionStorage.setItem(sStartDate, value);
  };
  const handleChangeEndDate = (value) => {
    setEndDate(value);
    sessionStorage.setItem(sEndDate, value);
  };

  const handleChangeMinimumBal = (e) => {
    setMinimumWalletBal(e.target.value);
    sessionStorage.setItem(sMinimumBalance, e.target.value);
  };

  const handleChangeMaximumBal = (e) => {
    setMaximumWalletBal(e.target.value);
    sessionStorage.setItem(sMaximumBalance, e.target.value);
  };

  const handleChangeUserRole = (e) => setUserRole(e.target.value);

  const handleClearSessionStorage = () => {
    sessionStorage.removeItem(sStatus);
    sessionStorage.removeItem(sUserName);
    sessionStorage.removeItem(sLoginDate);
    sessionStorage.removeItem(sStartDate);
    sessionStorage.removeItem(sEndDate);
    sessionStorage.removeItem(sMinimumBalance);
    sessionStorage.removeItem(sMaximumBalance);
  };

  const handleSetSessionStorage = () => {
    let ssStatus = sessionStorage.getItem(sStatus);

    if (ssStatus) {
      setStatus(ssStatus);
      setHasFiltered(true);
    }
    let ssUserName = sessionStorage.getItem(sUserName);
    if (ssUserName) {
      setUserName(ssUserName);
      setHasFiltered(true);
    }
    let ssLoginDate = sessionStorage.getItem(sLoginDate);
    if (ssLoginDate) {
      setLoginDate(ssLoginDate);
      setHasFiltered(true);
    }
    let ssStartDate = sessionStorage.getItem(sStartDate);
    if (ssStartDate) {
      setStartDate(ssStartDate);
      setHasFiltered(true);
    }
    let ssEndDate = sessionStorage.getItem(sEndDate);
    if (ssEndDate) {
      setEndDate(ssEndDate);
      setHasFiltered(true);
    }
    let ssMinimumBalance = sessionStorage.getItem(sMinimumBalance);
    if (ssMinimumBalance) {
      setMinimumWalletBal(ssMinimumBalance);
      setHasFiltered(true);
    }
    let ssMaximumBalance = sessionStorage.getItem(sMaximumBalance);
    if (ssMaximumBalance) {
      setMaximumWalletBal(ssMaximumBalance);
      setHasFiltered(true);
    }

    // Updating URL

    if (userName !== "") {
      if (!filterURL.includes(sUserName)) {
        filterURL = filterURL + `&search=${userName}`;
      }
    }
    if (status !== "") {
      if (!filterURL.includes(sStatus)) {
        filterURL = filterURL + `&status=${status}`;
      }
    }

    if (minimumWalletBal) {
      if (!filterURL.includes(sMinimumBalance)) {
        filterURL = filterURL + `&minWalletBalance=${minimumWalletBal}`;
      }
    }
    if (maximumWalletBal) {
      if (!filterURL.includes(sMaximumBalance)) {
        filterURL = filterURL + `&maxWalletBalance=${maximumWalletBal}`;
      }
    }

    if (loginDate) {
      if (!filterURL.includes(sLoginDate)) {
        filterURL =
          filterURL + `&lastLoginDate=${dayjs(loginDate).format("DD/MM/YYYY")}`;
      }
    }
    if (startDate) {
      if (!filterURL.includes(sStartDate)) {
        filterURL =
          filterURL + `&minCreateDate=${dayjs(startDate).format("DD/MM/YYYY")}`;
      }
    }
    if (endDate) {
      if (!filterURL.includes(sEndDate)) {
        filterURL =
          filterURL + `&maxCreateDate=${dayjs(endDate).format("DD/MM/YYYY")}`;
      }
    }
  };

  const handleSelectUser = (user) => setSelectedUserInfo(user);
  const handleCloseDialog = () => setOpenDialog(false);
  const handleOpenDialog = () => setOpenDialog(true);

  const handleOpenDeactivateUserDialog = () => {
    setOpenDeactivateDialog(true);
    setOpenActivateDialog(false);
  };
  const handleCloseDeactivateUserDialog = () => {
    setOpenDeactivateDialog(false);
  };

  const handleOpenActivateUserDialog = () => {
    setOpenDeactivateDialog(false);
    setOpenActivateDialog(true);
  };
  const handleCloseActivateUserDialog = () => {
    setOpenActivateDialog(false);
  };
  const handleOpenDebitAccountDialog = () => {
    setOpenDebitAccountDialog(true);
  };
  const handleCloseDebitAccountDialog = () => {
    setOpenDebitAccountDialog(false);
  };
  const handleOpenFundUserDialog = () => {
    setOpenFundUserDialog(true);
    setOpenActivateDialog(false);
  };
  const handleCloseFundUserDialog = () => {
    setOpenFundUserDialog(false);
  };

  const handleChangeFilter = (event) => setShowFilter(event.target.checked);

  React.useEffect(() => {
    handleSetSessionStorage();
    return () => {
      handleClearSessionStorage();
    };
  }, []);

  const handleResetFields = () => {
    setStatus("");
    setUserName("");
    setLoginDate(null);
    setStartDate(null);
    setEndDate(null);
    setMinimumWalletBal(0);
    setMaximumWalletBal(0);
  };

  const fetcher = () => {
    handleSetSessionStorage();
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data;
      });
  };

  const handleFiterSubmit = () =>
    mutate(filterURL, async () => {
      try {
        handleSetSessionStorage();
        checkExpiredAuthToken();
        const token = getAuthToken();
        setPage(1);
        setIsFiltering(true);
        const res = await axios.get(filterURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setIsFiltering(false);
        setHasFiltered(true);
        return res.data?.data;
      } catch (err) {
        setIsFiltering(false);
        return err;
      }
    });

  const handleSubmitDeactivateUser = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    let values = {
      status: false,
      userId: selectedUserInfo?.userId,
    };

    axios
      .post(`${process.env.API_URL}user/toggle-account-status`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsSubmitting(false);
        if (res?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.message,
            severity: "success",
          });
        }
        handleOpenSnackbar({
          message: "Player's Account Was Successfully Deactivated",
          severity: "success",
        });
        mutate(url);
        return handleCloseDeactivateUserDialog();
      })
      .catch((err) => {
        setIsSubmitting(false);

        if (err?.response?.data?.responsemessage?.msg) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage?.msg,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, User Could Not Be Deactivated",
          severity: "error",
        });
      });
  };

  const handleSubmitActivateUser = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    let values = {
      status: true,
      userId: selectedUserInfo?.userId,
    };

    axios
      .post(`${process.env.API_URL}user/toggle-account-status`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsSubmitting(false);
        if (res?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.message,
            severity: "success",
          });
        }
        handleOpenSnackbar({
          message: "Player's Account Was Successfully Activated",
          severity: "success",
        });
        mutate(url);
        return handleCloseActivateUserDialog();
      })
      .catch((err) => {
        setIsSubmitting(false);

        if (err?.response?.data?.data?.message) {
          return handleOpenSnackbar({
            message: err?.response?.data?.data?.message,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, Player's Account Could Not Be Activated",
          severity: "error",
        });
      });
  };

  const handleChangePage = (event, newPage) => {
    setIsFiltering(true);
    handleSetSessionStorage();
    setPage(newPage + 1);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsFiltering(false);
        mutate(filterURL, res?.data?.data);
      })
      .catch((err) => {
        setIsFiltering(false);
        console.log(err);
      });
  };
  const handleCancelFilters = () => {
    handleClearSessionStorage();
    handleResetFields();
    setHasFiltered(false);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return mutate(url, res.data?.data, false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { data, error } = useSWR(filterURL, fetcher, {
    // revalidateIfStale: false,
    // revalidateOnFocus: false,
    dedupingInterval: 70000,
  });

  if (error)
    return (
      <FullScreenError
        title=" Sorry, can't retrieve players data at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;
  return (
    <AdminLayout>
      <MetaDecorator title="Influencers Table - Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Influencers" />
        {openDialog ? (
          <PreviewPlayerModal
            openDialog={openDialog}
            handleCloseDialog={handleCloseDialog}
            selectedUser={selectedUserInfo}
          />
        ) : null}
        {openDeactivateDialog ? (
          <DeactivateUserDialog
            openDialog={openDeactivateDialog}
            selectedUser={selectedUserInfo}
            isSubmitting={isSubmitting}
            handleCloseDialog={handleCloseDeactivateUserDialog}
            handleSubmit={handleSubmitDeactivateUser}
          />
        ) : null}
        {openActivateDialog ? (
          <AactivateUserDialog
            openDialog={openActivateDialog}
            selectedUser={selectedUserInfo}
            isSubmitting={isSubmitting}
            handleCloseDialog={handleCloseActivateUserDialog}
            handleSubmit={handleSubmitActivateUser}
          />
        ) : null}
        {openFundUserDialog ? (
          <FundUserAccountDialog
            openDialog={openFundUserDialog}
            usersURL={url}
            selectedUser={selectedUserInfo}
            isSubmitting={isSubmitting}
            handleCloseDialog={handleCloseFundUserDialog}
          />
        ) : null}
        {openDebitAccountDialog ? (
          <DebitUserAccountDialog
            openDialog={openDebitAccountDialog}
            usersURL={url}
            selectedUser={selectedUserInfo}
            isSubmitting={isSubmitting}
            handleCloseDialog={handleCloseDebitAccountDialog}
          />
        ) : null}
        {/* <UsersAnalytics /> */}
        {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <>
            {hasFiltered && <ClearFilters handleClick={handleCancelFilters} />}
          </>
          <FormControlLabel
            sx={{ marginLeft: "auto" }}
            control={
              <Switch
                color="success"
                checked={showFilter}
                onChange={handleChangeFilter}
                inputProps={{ "aria-label": "Show Filters" }}
              />
            }
            label="Show Filters"
          />
        </Box> */}

        {showFilter && (
          <InfluencersFilter
            minimumWalletBal={minimumWalletBal}
            maximumWalletBal={maximumWalletBal}
            status={status}
            userName={userName}
            userRole={userRole}
            loginDate={loginDate}
            startDate={startDate}
            endDate={endDate}
            handleChangeStatus={handleChangeStatus}
            handleChangeUserName={handleChangeUserName}
            handleChangeUserRole={handleChangeUserRole}
            handleChangeLoginDate={handleChangeLoginDate}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
            handleChangeMinimumBal={handleChangeMinimumBal}
            handleChangeMaximumBal={handleChangeMaximumBal}
            handleFiterSubmit={handleFiterSubmit}
          />
        )}
        <Box sx={AddButtonBoxStyles}>
          <Button
            variant="contained"
            color="success"
            onClick={() => history.push("/influencers/add")}
          >
            Add New Influencer
          </Button>
        </Box>
        <InfluencersTable
          status={status}
          isFiltering={isFiltering}
          hasFiltered={hasFiltered}
          selectedUser={selectedUserInfo}
          handleChangePage={handleChangePage}
          handleSelectUser={handleSelectUser}
          handleOpenDialog={handleOpenDialog}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleOpenDeactivateUserDialog={handleOpenDeactivateUserDialog}
          handleOpenActivateUserDialog={handleOpenActivateUserDialog}
          handleOpenFundUserDialog={handleOpenFundUserDialog}
          handleOpenDebitAccountDialog={handleOpenDebitAccountDialog}
          userName={userName}
          userRole={userRole}
          page={page}
          data={data?.data}
          totalCount={data?.totalCount}
        />
      </Container>
    </AdminLayout>
  );
}

export default Influencers;
