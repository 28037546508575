import * as React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import useSWRImmutable from "swr/immutable";
import {
  checkExpiredAuthToken,
  getAuthToken,
  dialogLoaderStyles,
  previewDialogMainTitleStyle,
} from "../../utils";
import { AppContext } from "../../context";
import FullScreenError from "../FullScreenError/FullScreenError";
import FullScreenLoader from "../FullScreenLoader/FullScreenLoader";

export default function GameStatisticsModal({
  openDialog,
  handleCloseDialog,
  selectedGame,
}) {
  const url = `${process.env.API_URL}game/fetch-result-statistics?category=${selectedGame?.Lottery?.category}`;
  const { handleOpenSnackbar } = React.useContext(AppContext);
  const HeadCells = ["S/N", "Selection", "Frequency"];
  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        handleOpenSnackbar({
          message: res?.data?.data?.message,
          severity: "success",
        });
        return res?.data?.data?.data;
      });
  };

  const { data, error } = useSWRImmutable(url, fetcher);

  if (error)
    return (
      <Dialog
        open={openDialog}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleCloseDialog}
      >
        <DialogContent>
          <Box style={{ dialogLoaderStyles }}>
            <FullScreenError
              title=" Sorry, game statistics data cannot be retrieved at this momment"
              subtitle={error.message}
            />
          </Box>
        </DialogContent>
      </Dialog>
    );

  if (!data)
    return (
      <Dialog open={true} fullWidth={true} maxWidth={"md"}>
        <DialogContent>
          <Box style={{ dialogLoaderStyles }}>
            <FullScreenLoader />
          </Box>
        </DialogContent>
      </Dialog>
    );
  return (
    <div>
      <Dialog
        open={openDialog}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleCloseDialog}
      >
        <DialogTitle color="success" sx={previewDialogMainTitleStyle}>
          GAME STATISTICS
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6}>
                <Box my={1}>
                  <Box my={2}>
                    <Typography variant="h6" color="chocolate" gutterBottom>
                      Low Frequency Numbers:
                    </Typography>
                    <TableContainer component={Paper}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            {HeadCells.map((item) => (
                              <TableCell>{item}</TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data?.lowFrequencyNumbers?.map((item, index) => (
                            <TableRow key={`${index}qw`}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{item?.selection}</TableCell>
                              <TableCell>{item?.frequency}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box my={1}>
                  <Box my={2}>
                    <Typography variant="h6" color="green" gutterBottom>
                      High Frequency Numbers:
                    </Typography>
                    <TableContainer component={Paper}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            {HeadCells.map((item) => (
                              <TableCell>{item}</TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data?.highFrequencyNumbers?.map((item, index) => (
                            <TableRow key={`${index}opw`}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{item?.selection}</TableCell>
                              <TableCell>{item?.frequency}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
