import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import axios from "axios";
import { AppContext } from "../../context";
import CircularProgress from "@mui/material/CircularProgress";
import { checkExpiredAuthToken, getAuthToken } from "../../utils";
import { useFormik } from "formik";
import { mutate } from "swr";
import * as yup from "yup";

export default function EditConfigDialog({
  openDialog,
  handleCloseDialog,
  selectedGame,
  configURL,
}) {
  const { handleOpenSnackbar } = React.useContext(AppContext);
  let initialValues = {};

  initialValues.odds = JSON.parse(selectedGame?.config)?.odds;
  initialValues.minOdds = JSON.parse(selectedGame?.config)?.minOdds;
  initialValues.maxOdds = JSON.parse(selectedGame?.config)?.maxOdds;
  initialValues.affiliate = JSON.parse(selectedGame?.config)?.affiliate;
  initialValues.agentCommission = JSON.parse(
    selectedGame?.config
  )?.agentCommission;

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      let config = {};
      let data = {};
      config.odds = values.odds;
      config.minOdds = values.minOdds;
      config.maxOdds = values.maxOdds;
      config.affiliate = values.affiliate;
      config.agentCommission = values.agentCommission;
      config.name = JSON.parse(selectedGame?.config)?.name;

      data.category = selectedGame?.category;
      data.betType = selectedGame?.betType;
      data.booster = selectedGame?.booster;
      data.resultType = selectedGame?.resultType;
      data.config = JSON.stringify(config);

      setSubmitting(true);
      checkExpiredAuthToken();
      const token = getAuthToken();
      return axios
        .put(`${process.env.API_URL}game-config/update-game-config`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          mutate(configURL);
          setSubmitting(false);
          handleCloseDialog();
          if (res?.data?.message) {
            return handleOpenSnackbar({
              message: res?.data?.message,
              severity: "success",
            });
          }
          return handleOpenSnackbar({
            message: "Game Confg Updated Successfully",
            severity: "success",
          });
        })
        .catch((err) => {
          setSubmitting(false);
          if (err?.response?.data?.responsemessage) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage,
              severity: "error",
            });
          }
          if (err?.response?.data?.data?.responsemessage) {
            return handleOpenSnackbar({
              message: err?.response?.data?.data?.responsemessage,
              severity: "error",
            });
          }
          handleOpenSnackbar({
            message: "Sorry, Game Confg Could Not Be Updated",
            severity: "error",
          });
        });
    },
    // validationSchema: yup.object().shape({
    //   // payReference: yup.string().required().label("Payment Reference"),

    // }),
  });

  return (
    <div>
      <Dialog
        fullWidth
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
      >
        <form noValidate onSubmit={formik.handleSubmit}>
          <DialogTitle sx={{ textAlign: "center" }}>
            UPDATE GAME CONFIG
          </DialogTitle>
          <DialogContent>
            <Box my={2}>
              <FormControl variant="outlined" fullWidth>
                {/* <InputLabel variant="outlined" narrati>Amount</InputLabel> */}
                <TextField
                  label="Odds"
                  error={formik.errors.odds && formik.touched.odds}
                  value={formik.values.odds}
                  name="odds"
                  type="number"
                  variant="outlined"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  helperText={formik.touched.odds && formik.errors.odds}
                />
              </FormControl>
            </Box>
            <Box my={2}>
              <FormControl variant="outlined" fullWidth>
                {/* <InputLabel variant="outlined" narrati>Amount</InputLabel> */}
                <TextField
                  label="Minimum Odds"
                  error={formik.errors.minOdds && formik.touched.minOdds}
                  value={formik.values.minOdds}
                  name="minOdds"
                  type="number"
                  variant="outlined"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  helperText={formik.touched.minOdds && formik.errors.minOdds}
                />
              </FormControl>
            </Box>
            <Box my={2}>
              <FormControl variant="outlined" fullWidth>
                {/* <InputLabel variant="outlined" narrati>Amount</InputLabel> */}
                <TextField
                  label="Maximum Odds"
                  error={formik.errors.maxOdds && formik.touched.maxOdds}
                  value={formik.values.maxOdds}
                  name="maxOdds"
                  type="number"
                  variant="outlined"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  helperText={formik.touched.maxOdds && formik.errors.maxOdds}
                />
              </FormControl>
            </Box>
            <Box my={2}>
              <FormControl variant="outlined" fullWidth>
                {/* <InputLabel variant="outlined" narrati>Amount</InputLabel> */}
                <TextField
                  label="Affiliate's Commission"
                  error={formik.errors.affiliate && formik.touched.affiliate}
                  value={formik.values.affiliate}
                  name="affiliate"
                  type="number"
                  variant="outlined"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.affiliate && formik.errors.affiliate
                  }
                />
              </FormControl>
            </Box>
            <Box my={2}>
              <FormControl variant="outlined" fullWidth>
                {/* <InputLabel variant="outlined" narrati>Amount</InputLabel> */}
                <TextField
                  label="Agent's Commission"
                  error={
                    formik.errors.agentCommission &&
                    formik.touched.agentCommission
                  }
                  value={formik.values.agentCommission}
                  name="agentCommission"
                  type="number"
                  variant="outlined"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.agentCommission &&
                    formik.errors.agentCommission
                  }
                />
              </FormControl>
            </Box>

            <br />
          </DialogContent>
          <DialogActions sx={{ paddingRight: "10px" }}>
            <Button
              type="button"
              disabled={formik.isSubmitting}
              color="error"
              onClick={() => handleCloseDialog()}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={formik.isSubmitting}
              startIcon={
                formik.isSubmitting && <CircularProgress size={"1rem"} />
              }
              variant="contained"
              color="success"
            >
              {formik.isSubmitting ? "Updating" : "Update"}
            </Button>
          </DialogActions>{" "}
        </form>
      </Dialog>
    </div>
  );
}
