import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import dayjs from "dayjs";
import TableLoader from "../TableLoader/TableLoader";
import NoDataAvailable from "../EmptyTable/EmptyTable";
import { useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";
import Typography from "@mui/material/Typography";
import {
  boldTextCalculationStyles,
  getCommisionSum,
  getWalletSum,
} from "../../utils";
import TableCSVButton from "../TableCSVButton/TableCSVButton";

function AgentsTable({
  totalCount,
  hasFiltered,
  selectedUser,
  isFiltering,
  rowsPerPage,
  rowsPerPageOptions,
  handleChangeRowsPerPage,
  handleOpenReverseFundDialog,
  handleChangePage,
  handleSelectUser,
  handleOpenDialog,
  handleOpenToggleAgentDialog,
  handleOpenFundAgentDialog,
  handleOpenDebitAccountDialog,
  page,
  data,
}) {
  const [selectedId, setSelectedId] = React.useState(""); // For user ID when the more icon is clicked
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const history = useHistory();

  const handleClick = (event, agent) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(agent?.userId);
    handleSelectUser(agent);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedId("");
  };

  const moveToAgentDetailsPage = () => {
    history.push(`agents/agent-details/${selectedId}`);
  };
  const moveToExcludeBetTypesPage = () => {
    history.push(`agents/exclude-bettypes/${selectedId}`);
  };

  const headCells = [
    "First Name",
    "Last Name",
    "Email",
    "Phone Number",
    "Role",
    "Date Joined",
    "Status",
    "Wallet Balance",
    "Commission Balance",
    "Actions",
  ];
  const headers = [
    "First Name",
    "Last Name",
    "Email",
    "Phone Number",
    "Role",
    "Date Joined",
    "Status",
    "Wallet Balance",
    "Commission Balance",
  ];

  let rows = [];
  if (data?.length > 0) {
    data?.forEach((item) => {
      // console.log("phone", item.phone);
      rows.push([
        item?.firstname,
        item?.lastname,
        item?.email,
        item?.phone,
        item?.role,
        item?.createdAt,
        item?.status,
        item?.walletBalance,
        item?.commissionBalance,
      ]);
    });

    rows = [headers, ...rows];
  }
  return isFiltering ? (
    <TableLoader />
  ) : (
    <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
      {data?.length > 0 ? (
        <TableCSVButton data={rows} filename="agents.csv" />
      ) : null}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="recent users table">
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell key={item} component="th" sx={{ fontWeight: 600 }}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.length > 0 &&
              data?.map((row) => (
                <TableRow key={row.userId}>
                  <TableCell>{row.firstname}</TableCell>
                  <TableCell>{row.lastname}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.phone}</TableCell>
                  <TableCell>{row.role}</TableCell>
                  <TableCell style={{ width: 160 }}>
                    {dayjs(row.createdAt).format("MMM D, YYYY")}
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                    {row.status ? (
                      <Chip color="success" label="Active" size="small" />
                    ) : (
                      <Chip label="Not Active" color="error" size="small" />
                    )}
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      value={row?.walletBalance}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"NGN"}
                      renderText={(value, props) => (
                        <Typography align="left" {...props}>
                          {value}
                        </Typography>
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      value={row?.commissionBalance}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"NGN"}
                      renderText={(value, props) => (
                        <Typography align="left" {...props}>
                          {value}
                        </Typography>
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => handleClick(e, row)}>
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      PaperProps={{
                        elevation: 1,
                        sx: { boxShadow: "2px 2px 4px #eee" },
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => handleOpenFundAgentDialog()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Fund Account
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => handleOpenDebitAccountDialog()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Debit Account
                      </MenuItem>
                      <Divider />

                      {selectedUser?.status ? (
                        <MenuItem
                          onClick={() => handleOpenToggleAgentDialog()}
                          sx={{ fontSize: "12.7px" }}
                        >
                          Deactivate Account
                        </MenuItem>
                      ) : null}

                      {!Boolean(selectedUser?.status) ? (
                        <MenuItem
                          onClick={() => handleOpenToggleAgentDialog()}
                          sx={{ fontSize: "12.7px" }}
                        >
                          Activate Account
                        </MenuItem>
                      ) : null}
                      <Divider />
                      <MenuItem
                        onClick={() => handleOpenDialog()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Preview Agent
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => moveToAgentDetailsPage()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        View More Details
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => moveToExcludeBetTypesPage()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Exclude Bet Types
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            {data && data?.length > 0 && (
              <TableRow>
                <TableCell sx={boldTextCalculationStyles} colSpan={7}>
                  Total
                </TableCell>
                <TableCell align="right">
                  <NumberFormat
                    value={getWalletSum(data)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"NGN"}
                    renderText={(value, props) => (
                      <Typography
                        sx={boldTextCalculationStyles}
                        align="left"
                        {...props}
                      >
                        {value}
                      </Typography>
                    )}
                  />
                </TableCell>
                <TableCell align="right">
                  <NumberFormat
                    value={getCommisionSum(data)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"NGN"}
                    renderText={(value, props) => (
                      <Typography
                        sx={boldTextCalculationStyles}
                        align="left"
                        {...props}
                      >
                        {value}
                      </Typography>
                    )}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>
      {data && data?.length <= 0 ? null : (
        <Box>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onPageChange={handleChangePage}
          />
        </Box>
      )}
    </Box>
  );
}

export default AgentsTable;
