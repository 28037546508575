import React from "react";
import ReactDOM from "react-dom";
import Routes from "./components/Routes";
import "./styles/styles.scss";
import CssBaseline from "@mui/material/CssBaseline";
import { AppProvider } from "./context";
import CustomizedSnackbars from "./components/CustomSnackbar/CustomSnackbar";
import { setDefaultHeaders } from "./utils";

const App = () => {
  React.useEffect(() => {
    setDefaultHeaders();
  }, []);
  return <Routes />;
};

var mountNode = document.getElementById("app");
ReactDOM.render(
  <>
    <AppProvider>
      <CustomizedSnackbars />
      <CssBaseline />
      <App />
    </AppProvider>
  </>,
  mountNode
);
