import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import InputLabel from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import { Formik, Form } from "formik";
import * as yup from "yup";
import axios from "axios";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import useSWR, { mutate } from "swr";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import { AppContext } from "../context";
import {
  BUNDLE_BONUS,
  checkExpiredAuthToken,
  getAuthToken,
  labelStyles,
} from "../utils";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";

let bonusSchema = yup.object().shape({
  title: yup.string().required(),
  expiration: yup.string().required().label("Expiration"),
  winCount: yup.number().positive().min(1).required().label("Win Count"),
  prize: yup.number().positive().min(1).required().label("Prize"),
  gamePlayCount: yup
    .number()
    .positive()
    .min(1)
    .required()
    .label("Game Play Count"),
});

function EditBundleBonus() {
  const [endTime, setEndTime] = React.useState(new Date());
  const { bonusId } = useParams();
  const { handleOpenSnackbar } = React.useContext(AppContext);

  const url = `${process.env.API_URL}bonus/fetch-bonus/${bonusId}`;

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };

  let initialValues = {
    title: "",
    winCount: 1,
    gameType: "",
    betType: "",
    expiration: "",
    prize: 1,
    gamePlayCount: 1,
    description: "",
    type: BUNDLE_BONUS,
    unitCost: 0,
  };

  const handleSubmit = (values, helpers) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    // values.expiration = dayjs(values?.expiration).format("DD-MM-YYYY");

    axios
      .put(`${process.env.API_URL}bonus/update-bonus/${bonusId}`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        helpers.setSubmitting(false);
        helpers.resetForm(initialValues);
        mutate(url);
        if (res?.data?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.data?.message,
            severity: "success",
          });
        }
        return handleOpenSnackbar({
          message: "Bonus Updated Successfully",
          severity: "success",
        });
      })
      .catch((err) => {
        console.log(err?.response?.data?.responsemessage);
        helpers.setSubmitting(false);
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, Bonus Could Not Be Updated",
          severity: "error",
        });
      });
  };

  const { data, error } = useSWR(url, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  if (error)
    return (
      <FullScreenError
        title=" Sorry, bonus cannot be retrieved at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  if (data?.title) {
    initialValues.title = data?.title;
    initialValues.winCount = data?.winCount;
    initialValues.gameType = data?.gameType;
    initialValues.betType = data?.betType;
    initialValues.expiration = data?.expiration;
    initialValues.prize = data?.prize;
    initialValues.gamePlayCount = data?.gamePlayCount;
    initialValues.description = data?.description;
    initialValues.type = data?.type;
    initialValues.unitCost = data?.unitCost;
  }

  return (
    <AdminLayout>
      <MetaDecorator title="Update Bundle Bonus Configuration - Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Update Bundle Bonus" />

        <div>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={bonusSchema}
            validateOnBlur={true}
            validateOnChange={true}
            //   onSubmit={(values) => console.log(values)}
            onSubmit={(values, helpers) => handleSubmit(values, helpers)}
          >
            {({
              handleChange,
              handleBlur,
              touched,
              errors,
              setFieldValue,
              isSubmitting,
              values,
            }) => (
              <Form noValidate>
                <Box my={1}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      label="Enter Bonus Title"
                      error={touched.title && errors.title}
                      value={values.title}
                      name="title"
                      variant="outlined"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.title && errors.title}
                    />
                  </FormControl>
                </Box>

                <Box mt={2} mb={1}>
                  <Grid container spacing={2}>
                    <Grid xs={12} sm={6} item>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          label="Prize"
                          name="prize"
                          value={values.prize}
                          type="number"
                          variant="outlined"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.prize && errors.prize}
                          helperText={touched.prize && errors.prize}
                        />
                      </FormControl>
                    </Grid>
                    <Grid xs={12} sm={6} item>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          label="Unit Cost"
                          value={values.unitCost}
                          name="unitCost"
                          type="number"
                          variant="outlined"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.unitCost && errors.unitCost}
                          helperText={touched.unitCost && errors.unitCost}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={2} mb={2}>
                  <Grid container spacing={2}>
                    <Grid xs={12} sm={6} item>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          label="Win Count"
                          name="winCount"
                          value={values.winCount}
                          type="number"
                          variant="outlined"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.winCount && errors.winCount}
                          helperText={touched.winCount && errors.winCount}
                        />
                      </FormControl>
                    </Grid>
                    <Grid xs={12} sm={6} item>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          label="Game Play Count"
                          name="gamePlayCount"
                          value={values.gamePlayCount}
                          type="number"
                          variant="outlined"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.gamePlayCount && errors.gamePlayCount}
                          helperText={
                            touched.gamePlayCount && errors.gamePlayCount
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>

                <Box mt={2} my={1}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      label="Bonus Expiration Period (In Days)"
                      value={values.expiration}
                      name="expiration"
                      type="number"
                      variant="outlined"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={touched.expiration && errors.expiration}
                      helperText={touched.expiration && errors.expiration}
                    />
                  </FormControl>
                </Box>
                <Box mt={2} my={1}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      label="Description"
                      multiline
                      name="description"
                      variant="outlined"
                      fullWidth
                      value={values.description}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={touched.description && errors.description}
                      helperText={touched.description && errors.description}
                    />
                  </FormControl>
                </Box>
                <br />
                <Button
                  startIcon={
                    isSubmitting && (
                      <CircularProgress severity="success" size={"1rem"} />
                    )
                  }
                  disabled={isSubmitting}
                  type="submit"
                  size="large"
                  variant="contained"
                  color="success"
                >
                  {isSubmitting ? "Updating Bonus" : "Update Bonus"}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </AdminLayout>
  );
}

export default EditBundleBonus;
