import * as React from "react";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import useSWR from "swr";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import axios from "axios";
import { useParams } from "react-router-dom";
import { getAuthToken, checkExpiredAuthToken } from "../utils";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import BetSlipsTable from "../components/BookedCodeDetailsComponents/BetSlipsTable";
import TicketInformation from "../components/BookedCodeDetailsComponents/TicketInformation";
import GameInformation from "../components/BookedCodeDetailsComponents/GameInformation";
import TicketPlayerInformation from "../components/BookedCodeDetailsComponents/TicketUserInformation";
import BookingCodeUsersTable from "../components/BookedCodeDetailsComponents/BookingCodeUsersTable";

function BookingCodeDetails() {
  const { ticketId, bookingCode } = useParams();

  const url = `${process.env.API_URL}game/fetch-ticket-by-code/${bookingCode}`;
  // const potentialURL = `${process.env.API_URL}game/ticket/get-potential-winning`;
  const bookingCodeUsersURL = `${process.env.API_URL}game/fetch-booking-code-users/${bookingCode}`;

  const fetcher = async () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    let result = {};
    let ticketData = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    let req = {
      category: ticketData?.data?.data?.data?.Game?.Lottery?.category,
      betSlips: ticketData?.data?.data?.data?.betSlips,
    };

    // let potentialData = await axios.post(potentialURL, req, {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // });

    result.ticketData = ticketData?.data?.data?.data;
    // result.potentialWinningData = potentialData?.data?.data?.data;
    // result.bookingCodeUsers = potentialData?.data?.data?.data;

    return result;
  };
  const itemBoxStyle = {
    maxWidth: "600px",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #eee",
  };

  const renderGameStatus = (value) => {
    if (value.toLowerCase() === "ongoing") {
      return <Chip color="info" label="Ongoing" size="small" />;
    }
    if (value.toLowerCase() === "won") {
      return <Chip color="success" label={"Won"} size="small" />;
    }
    return <Chip color="error" label={"Lost"} size="small" />;
  };

  const { data, error } = useSWR(url, fetcher);

  if (error)
    return (
      <FullScreenError
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  return (
    <AdminLayout>
      <MetaDecorator
        title={`Booked Code Ticket ${ticketId} details - Admin Dashboard`}
      />
      <Container maxWidth="xl">
        <PageHeading title="Booked Code's details" />
        <Grid container spacing={2}>
          <TicketInformation
            renderGameStatus={renderGameStatus}
            data={data?.ticketData}
            itemBoxStyle={itemBoxStyle}
            potentialWinningData={data?.potentialWinningData}
          />
          <GameInformation
            data={data?.ticketData}
            itemBoxStyle={itemBoxStyle}
          />
        </Grid>
        <Grid container spacing={2}>
          <TicketPlayerInformation
            renderGameStatus={renderGameStatus}
            data={data?.ticketData?.User}
            itemBoxStyle={itemBoxStyle}
          />
        </Grid>

        <Box my={2}>
          <Typography gutterBottom variant="h6" sx={{ fontWeight: 500 }}>
            Bet Slips Information
          </Typography>
          <BetSlipsTable
            data={data?.ticketData?.betSlips}
            totalStakedAmount={data?.ticketData?.totalStakedAmount}
          />
        </Box>
        <Box my={2}>
          <Typography gutterBottom variant="h6" sx={{ fontWeight: 500 }}>
            Booking Code Users Information
          </Typography>
          <BookingCodeUsersTable bookingCode={bookingCode} />
        </Box>
      </Container>
    </AdminLayout>
  );
}

export default BookingCodeDetails;
