import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import {
  currencyFormater,
  formatGameDate,
  IMAGE_PREVIEW_HEIGHT,
  IMAGE_PREVIEW_WIDTH,
  POOL_OF_FAME,
  singleDetailsBoxStyle,
} from "../../utils";

import dayjs from "dayjs";

function GameInformation({ data }) {
  return (
    <Box>
      <Typography gutterBottom variant="h6" sx={{ fontWeight: 500 }}>
        Game Information
      </Typography>
      <Box py={2}>
        {data?.imageUrl && (
          <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
            <img
              src={data?.imageUrl}
              alt={data?.name}
              width={IMAGE_PREVIEW_WIDTH}
              height={IMAGE_PREVIEW_HEIGHT}
              style={{ objectFit: "contain" }}
            />
          </Box>
        )}
        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Game Instance Name:</Typography>
          <Typography align="left" sx={{ textTransform: "capitalize" }}>
            {data && data?.name}
          </Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Start Time:</Typography>
          <Typography align="left">
            {data && formatGameDate(data?.startTime, true)}
          </Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">End Time:</Typography>
          <Typography align="left">
            {data && formatGameDate(data?.endTime, true)}
          </Typography>
        </Box>
        {data?.totalFundPool && data?.Lottery?.category === POOL_OF_FAME && (
          <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
            <Typography color="text.secondary">Total Fund Pool:</Typography>
            <Typography align="left">
              {data && currencyFormater(data?.totalFundPool)}NGN
            </Typography>
          </Box>
        )}
        {data?.currentPoolAmount && data?.Lottery?.category === POOL_OF_FAME && (
          <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
            <Typography color="text.secondary">Current Pool Amount:</Typography>
            <Typography align="left">
              {data && currencyFormater(data?.currentPoolAmount)}NGN
            </Typography>
          </Box>
        )}
        {data?.alternateStartDate && data?.Lottery?.category === POOL_OF_FAME && (
          <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
            <Typography color="text.secondary">Alternate Draw Date:</Typography>
            <Typography align="left">
              {dayjs(data?.alternateStartDate).format("DD MMM YYYY")}
            </Typography>
          </Box>
        )}

        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Day of the Week:</Typography>
          <Typography align="left">{data && data?.weekday}</Typography>
        </Box>
        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Game Status:</Typography>
          {data?.status ? (
            <Chip color="success" label="Active" size="small" />
          ) : (
            <Chip label="Not Active" color="error" size="small" />
          )}
        </Box>

        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Date Created:</Typography>
          <Typography align="left">
            {dayjs(data?.createdAt).format("DD MMM YYYY")}
          </Typography>
        </Box>

        <Box mb={1} pb={1} pr={2} sx={singleDetailsBoxStyle}>
          <Typography color="text.secondary">Last Updated:</Typography>
          <Typography align="left">
            {dayjs(data?.updatedAt).format("DD MMM YYYY")}
          </Typography>
        </Box>
        <Box mb={1} pb={1} pr={2}>
          <Typography color="text.secondary">Description:</Typography>
          <Typography align="left">{data?.description}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default GameInformation;
