import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import NumberFormat from "react-number-format";
import NoDataAvailable from "../EmptyTable/EmptyTable";
import { limit } from "../../utils";
import TableLoader from "../TableLoader/TableLoader";
import TableCSVButton from "../TableCSVButton/TableCSVButton";

function BookedCodesTable({
  rowsPerPageOptions,
  rowsPerPage,
  handleChangeRowsPerPage,
  isFiltering,
  data,
  page,
  totalCount,
  selectedTicket,
  handleChangePage,
  handleSelectTicket,
  handleOpenPreviewTicket,
}) {
  const [selectedId, setSelectedId] = useState(""); // For user ID when the more icon is clicked
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const open = Boolean(anchorEl);

  const handleClick = (event, ticket) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(ticket?.bookingCode);
    handleSelectTicket(ticket);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedId("");
  };

  const headCells = [
    "Player",
    "Booking Code",
    "Ticket ID",
    "Staked Amount",
    "Redemption Method",
    "Source Wallet",
    "Lines Count",
    "Status",
    "Date Created",
    "Actions",
  ];
  const headers = [
    "Player",
    "Booking Code",
    "Ticket ID",
    "Staked Amount",
    "Redemption Method",
    "Source Wallet",
    "Lines Count",
    "Status",
    "Date Created",
  ];

  let rows = [];
  if (data?.length > 0) {
    data?.forEach((item) => {
      rows.push([
        `${item?.User?.firstname} ${item?.User?.lastname}`,
        item?.bookingCode,
        item?.ticketId,
        item?.totalStakedAmount,
        item?.winningRedemptionMethod,
        item?.sourceWallet,
        item?.linesCount,
        item?.status,
        item?.createdAt,
      ]);
    });

    rows = [headers, ...rows];
  }
  return isFiltering ? (
    <TableLoader />
  ) : (
    <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
      {data?.length > 0 ? (
        <TableCSVButton data={rows} filename="booked_codes.csv" />
      ) : null}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="booked codes table">
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell key={item} component="th" sx={{ fontWeight: 600 }}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.length > 0 &&
              data?.map((row) => (
                <TableRow key={row?.ticketId}>
                  <TableCell>
                    {row?.User?.firstname} {row?.User?.lastname}
                  </TableCell>
                  <TableCell>{row?.bookingCode}</TableCell>{" "}
                  <TableCell>{row?.ticketId}</TableCell>
                  <TableCell>
                    <NumberFormat
                      value={row?.totalStakedAmount}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"NGN"}
                      renderText={(value, props) => (
                        <div {...props}>{value}</div>
                      )}
                    />
                  </TableCell>
                  <TableCell>{row.winningRedemptionMethod}</TableCell>
                  <TableCell>{row?.sourceWallet}</TableCell>
                  <TableCell>{row?.linesCount}</TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell style={{ width: 160 }}>
                    {dayjs(row.createdAt).format("MMM D, YYYY")}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => handleClick(e, row)}>
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      PaperProps={{
                        elevation: 1,
                        sx: { boxShadow: "2px 2px 4px #eee" },
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      {" "}
                      <MenuItem
                        onClick={() => handleOpenPreviewTicket()}
                        sx={{ fontSize: "12.7px" }}
                      >
                        Preview Details
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() =>
                          history.push(
                            `/booked-codes/ticket-details/${selectedId}/${selectedTicket?.bookingCode}`
                          )
                        }
                        sx={{ fontSize: "12.7px" }}
                      >
                        View More Details
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

export default BookedCodesTable;
