import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { FaRegImage } from "react-icons/fa";
import axios from "axios";
import { AppContext } from "../../context";
import CircularProgress from "@mui/material/CircularProgress";
import { checkExpiredAuthToken, getAuthToken } from "../../utils";
import { useFormik } from "formik";
import { mutate } from "swr";
import * as yup from "yup";
import "../../styles/avatarsettings.scss";

export default function UpdateLotteryImageForm({
  openDialog,
  handleCloseDialog,
  selectedId,
  url,
}) {
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [imageName, setImageName] = React.useState("");

  const handleImageSelect = (file) => {
    const objetURL = URL.createObjectURL(file);
    setSelectedImage(objetURL);
    setImageName(file?.name);
  };

  const imgRef = React.useRef();
  const handleOpenImage = () => {
    imgRef.current.click();
  };
  const { handleOpenSnackbar } = React.useContext(AppContext);

  React.useEffect(() => {
    return () => {
      URL.revokeObjectURL(selectedImage);
    };
  }, []);
  const formik = useFormik({
    initialValues: {
      image: "",
    },
    onSubmit: async (values, helpers) => {
      // console.log("values", values);

      try {
        checkExpiredAuthToken();
        const token = getAuthToken();
        const formData = new FormData();
        formData.append("categoryImage", values.image);
        formData.append("category", selectedId);

        const res = await axios.put(
          `${process.env.API_URL}site-settings/update-game-category-icon`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        helpers.setSubmitting(false);
        handleCloseDialog();
      } catch (err) {
        console.log(err);
        if (err?.response?.data?.responsemessage?.msg) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage?.msg,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, Lottery Category Image Could Not Be Uploaded",
          severity: "error",
        });
      }
    },
    validationSchema: yup.object().shape({
      image: yup.mixed().required().label("Image"),
    }),
  });

  return (
    <Dialog
      fullWidth
      open={openDialog}
      onClose={handleCloseDialog}
      maxWidth="md"
    >
      <form noValidate onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ textAlign: "center" }}>
          LOTTERY CATEGORY IMAGE UPLOAD
        </DialogTitle>
        <DialogContent>
          <Box my={2} p={1}>
            <div
              style={{
                cursor: "pointer",
                height: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "4px dotted #eee",
              }}
              onClick={() => handleOpenImage()}
            >
              <FaRegImage className="uploadImage" />
            </div>
            <Typography align="center">Select Image</Typography>
          </Box>

          <Box my={2}>
            {selectedImage && (
              <img
                src={selectedImage}
                style={{
                  width: "120px",
                  height: "120px",
                  objectFit: "contain",
                }}
                alt="selected image"
              />
            )}
            <input
              accept="image/*"
              style={{ display: "none" }}
              ref={imgRef}
              type="file"
              name="image"
              onChange={(e) => {
                handleImageSelect(e.target.files[0]);
                formik.setFieldValue("image", e.target.files[0]);
              }}
            />
          </Box>
          <br />
        </DialogContent>
        <DialogActions sx={{ paddingRight: "15px" }}>
          <Button
            type="button"
            disabled={formik.isSubmitting}
            color="error"
            onClick={() => handleCloseDialog()}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={formik.isSubmitting || !selectedImage}
            startIcon={
              formik.isSubmitting && <CircularProgress size={"1rem"} />
            }
            variant="contained"
            color="success"
          >
            {formik.isSubmitting ? "Uploading" : "Upload"}
          </Button>
        </DialogActions>{" "}
      </form>
    </Dialog>
  );
}
