import React from "react";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import axios from "axios";
import useSWR from "swr";
import {
  checkExpiredAuthToken,
  getAuthToken,
  dialogLoaderStyles,
  previewSubTitleStyles,
  dialogMainTextStyles,
  previewDialogMainTitleStyle,
} from "../../utils";
import FullScreenError from "../FullScreenError/FullScreenError";
import FullScreenLoader from "../FullScreenLoader/FullScreenLoader";
import dayjs from "dayjs";
import NumberFormat from "react-number-format";

export default function PreviewBonusModal({
  openDialog,
  handleCloseDialog,
  selectedBonus,
}) {
  const url = `${process.env.API_URL}bonus/fetch-bonus/${selectedBonus?.bonusId}`;

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };

  const { data, error } = useSWR(url, fetcher);

  if (error)
    return (
      <Dialog open={openDialog} fullWidth={true} maxWidth={"md"}>
        <DialogContent>
          <Box style={{ dialogLoaderStyles }}>
            <FullScreenError
              title=" Sorry, bonus cannot be retrieved at this moment"
              subtitle={error.message}
            />
          </Box>
        </DialogContent>
      </Dialog>
    );

  if (!data)
    return (
      <Dialog open={true} fullWidth={true} maxWidth={"md"}>
        <DialogContent>
          <Box style={{ dialogLoaderStyles }}>
            <FullScreenLoader />
          </Box>
        </DialogContent>
      </Dialog>
    );

  return (
    <div>
      <Dialog
        open={openDialog}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleCloseDialog}
      >
        <DialogTitle color="success" sx={previewDialogMainTitleStyle}>
          BONUS PREVIEW
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box my={1}>
                  <Box my={1}>
                    <Typography variant="caption">Title:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.title}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Bet Type:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.betType}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Deposit Round:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.depositRound}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Game Type:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.gameType}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption" sx={{ display: "block" }}>
                      Bonus Status:
                    </Typography>
                    {data?.status ? (
                      <Chip color="success" label="Active" size="small" />
                    ) : (
                      <Chip label="Not Active" color="error" size="small" />
                    )}
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Expiration Date:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {dayjs(data?.expiration).format("MMM D, YYYY")}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box my={1}>
                  <Box my={1}>
                    <Typography variant="caption">Prize:</Typography>
                    <NumberFormat
                      value={data?.prize}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"NGN"}
                      renderText={(value, props) => (
                        <Typography
                          sx={dialogMainTextStyles}
                          align="left"
                          {...props}
                        >
                          {value}
                        </Typography>
                      )}
                    />
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Minimum Deposit:</Typography>
                    <NumberFormat
                      value={data?.minimumDeposit}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"NGN"}
                      renderText={(value, props) => (
                        <Typography
                          sx={dialogMainTextStyles}
                          align="left"
                          {...props}
                        >
                          {value}
                        </Typography>
                      )}
                    />
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Game Play Count:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.gamePlayCount}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">Win Count:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {data?.winCount}
                    </Typography>
                  </Box>

                  <Box my={1}>
                    <Typography variant="caption">Date Created:</Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {dayjs(data?.createdAt).format("MMM D, YYYY")}
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Typography variant="caption">
                      Last Updated Date:
                    </Typography>
                    <Typography sx={dialogMainTextStyles}>
                      {dayjs(data?.updatedAt).format("MMM D, YYYY")}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
