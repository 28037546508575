import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import NumberFormat from "react-number-format";
import TableLoader from "../../TableLoader/TableLoader";
import TableCSVButton from "../../TableCSVButton/TableCSVButton";
import NoDataAvailable from "../../EmptyTable/EmptyTable";
import { renderStatus } from "../../../utils/statusChip";
import {
  boldTextCalculationStyles,
  getCommissionSalesSum,
  getCommissionSum,
  getCommissionWonSum,
} from "../../../utils";
function CommissionTable({
  hasFiltered,
  isFiltering,
  data,
  page,
  totalCount,
  selectedTicket,
  handleChangePage,
  handleSelectTicket,
  rowsPerPage,
  rowsPerPageOptions,
  handleChangeRowPerpage,
}) {
  const [selectedId, setSelectedId] = useState(""); // For user ID when the more icon is clicked
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const headCells = [
    "S/N",
    "User",
    // "Total Sales",
    "Total Comm.",
    "Date/Time",
    "Debt Status",
    "Agent Level",
    "Upline Username",
    "Payment Status",
    // "Win Total",
  ];

  let rows = [];
  if (data?.length > 0) {
    data?.forEach((item) => {
      rows.push([
        `${item?.User?.firstname} ${item?.User?.lastname}`,
        item?.ticketId,
        item?.totalStakedAmount,
        `${item?.resultId ? `${item?.totalWinAmount}` : `No Result Yet`}`,
        item?.winningRedemptionMethod,
        item?.Game?.name,
        item?.gameStatus,
        item?.createdAt,
        item?.status,
      ]);
    });

    rows = [headCells, ...rows];
  }
  return isFiltering ? (
    <TableLoader />
  ) : (
    <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
      {/* {data?.length > 0 ? (
        <TableCSVButton data={rows} filename="commission.csv" />
      ) : null} */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="recent users table">
          <TableHead>
            <TableRow>
              {headCells.map((item) => (
                <TableCell key={item} component="th" sx={{ fontWeight: 600 }}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.length > 0 &&
              data?.map((row, idx) => (
                <TableRow key={row?.recipientId}>
                  <TableCell>{idx + 1}</TableCell>
                  <TableCell>{row?.name}</TableCell>
                  {/* <TableCell>
                    <NumberFormat
                      value={parseFloat(row?.totalSales).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"NGN"}
                      renderText={(value, props) => (
                        <div {...props}>{value}</div>
                      )}
                    />
                  </TableCell> */}
                  <TableCell>
                    <NumberFormat
                      value={parseFloat(row?.totalCommission).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"NGN"}
                      renderText={(value, props) => (
                        <div {...props}>{value}</div>
                      )}
                    />
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                    {dayjs(row.createdAt).format("MMM D, YYYY, hh:mm a")}
                  </TableCell>
                  <TableCell>{row?.debtStatus}</TableCell>
                  <TableCell>{row?.agentLevel}</TableCell>
                  <TableCell>{row?.uplineName}</TableCell>

                  <TableCell style={{ width: 160 }}>
                    {renderStatus(row?.status)}
                  </TableCell>
                  {/* <TableCell>
                    <NumberFormat
                      value={parseFloat(row?.totalWon).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"NGN"}
                      renderText={(value, props) => (
                        <div {...props}>{value}</div>
                      )}
                    />
                  </TableCell> */}
                </TableRow>
              ))}
            {data && data?.length > 0 && (
              <TableRow>
                <TableCell sx={boldTextCalculationStyles} colSpan={2}>
                  Total
                </TableCell>

                {/* <TableCell align="right">
                  <NumberFormat
                    value={getCommissionSalesSum(data)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={""}
                    renderText={(value, props) => (
                      <Typography
                        sx={boldTextCalculationStyles}
                        align="left"
                        {...props}
                      >
                        &#8358;{value}
                      </Typography>
                    )}
                  />
                </TableCell> */}
                <TableCell align="right" colSpan={4}>
                  <NumberFormat
                    value={getCommissionSum(data)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={""}
                    renderText={(value, props) => (
                      <Typography
                        sx={boldTextCalculationStyles}
                        align="left"
                        {...props}
                      >
                        &#8358;{value}
                      </Typography>
                    )}
                  />
                </TableCell>
                {/* <TableCell align="right">
                  <NumberFormat
                    value={getCommissionWonSum(data)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={""}
                    renderText={(value, props) => (
                      <Typography
                        sx={boldTextCalculationStyles}
                        align="left"
                        {...props}
                      >
                        &#8358;{value}
                      </Typography>
                    )}
                  />
                </TableCell> */}
              </TableRow>
            )}
          </TableBody>
        </Table>
        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowPerpage}
      />
    </Box>
  );
}

export default CommissionTable;
