import * as React from "react";
import Button from "@mui/material/Button";

export default function ClearFilters({ handleClick }) {
  return (
    <Button
      color="success"
      variant="outlined"
      size="small"
      onClick={() => handleClick()}
      sx={{ textTransform: "capitalize", borderRadius: "20px", my: 1 }}
    >
      Clear Filters
    </Button>
  );
}
