import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import { AppContext } from "../context";
import {
  IMAGE_PREVIEW_HEIGHT,
  IMAGE_PREVIEW_WIDTH,
  checkExpiredAuthToken,
  getAuthToken,
  imageBox,
  imagePreviewStyle,
  labelStyles,
} from "../utils";

function AddInstantGame() {
  const [foregroundImage, setForegroundImage] = useState("");
  const [foregroundImagePreview, setForegroundImagePreview] = useState("");

  const { handleOpenSnackbar } = useContext(AppContext);

  const { lotteryId } = useParams();
  const history = useHistory();

  const handleSubmitImage = async (value, errorMessage) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    const formData = new FormData();
    formData.append("image", value);
    try {
      const res = await axios.post(`${process.env.API_URL}upload`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res?.data?.data?.data?.imageUrl;
    } catch (error) {
      console.log(error);
      handleOpenSnackbar({
        message: errorMessage,
        severity: "error",
      });
    }
  };
  let initialValues = {
    name: "",
    description: "",
    lotteryId: "",
    recurringInterval: 5,
  };

  let gameSchema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
    isRecurring: yup.string(),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values, helpers) => {
      checkExpiredAuthToken();
      const token = getAuthToken();
      values.lotteryId = lotteryId;
      if (foregroundImage) {
        const foreground = await handleSubmitImage(
          foregroundImage,
          "Sorry, an error occured while uploading the image"
        );
        values.imageUrl = foreground;
      }
      axios
        .post(`${process.env.API_URL}game/create-instant-game`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          helpers.setSubmitting(false);
          helpers.resetForm({ values: initialValues });
          if (res?.data?.message) {
            handleOpenSnackbar({
              message: res?.data?.message,
              severity: "success",
            });
            setTimeout(() => {
              return history.push("/games-instance");
            }, 2000);
          }
          handleOpenSnackbar({
            message: "Game Created Successfully",
            severity: "success",
          });
          setTimeout(() => {
            return history.push("/games-instance");
          }, 2000);
        })
        .catch((err) => {
          console.log(err?.response?.data?.responsemessage?.msg);
          helpers.setSubmitting(false);
          if (err?.response?.data?.responsemessage?.msg) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage?.msg,
              severity: "error",
            });
          }
          if (err?.response?.data?.responsemessage) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage,
              severity: "error",
            });
          }
          handleOpenSnackbar({
            message: "Sorry, Game Could Not Be Created",
            severity: "error",
          });
        });
    },
    validationSchema: gameSchema,
  });

  return (
    <AdminLayout>
      <MetaDecorator title="Add New Instant Game - Admin Dashboard" />
      <Container maxWidth="xl">
        <PageHeading title="Add Instant Game" />

        <form onSubmit={formik.handleSubmit}>
          <Box my={1} sx={imageBox}>
            <FormControl variant="outlined">
              <p>Select image to upload</p>
              <input
                accept="image/*"
                type="file"
                variant="outlined"
                onChange={(e) => {
                  setForegroundImage(e.target.files[0]);
                  setForegroundImagePreview(
                    URL.createObjectURL(e.target.files[0])
                  );
                }}
              />
            </FormControl>
            <div>
              {foregroundImagePreview && (
                <img
                  src={foregroundImagePreview}
                  alt="uploaded background"
                  width={IMAGE_PREVIEW_WIDTH}
                  height={IMAGE_PREVIEW_HEIGHT}
                  style={imagePreviewStyle}
                />
              )}
            </div>
          </Box>
          <Box my={1}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel variant="outlined" sx={labelStyles}>
                Enter Game Title
              </InputLabel>
              <TextField
                error={formik.touched.name && formik.errors.name}
                value={formik.values.name}
                name="name"
                variant="outlined"
                fullWidth
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                helperText={formik.touched.name && formik.errors.name}
              />
            </FormControl>
          </Box>

          <Box my={1}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="Description"
                name="description"
                variant="outlined"
                value={formik.values.description}
                fullWidth
                multiline
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.description && formik.errors.description}
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
            </FormControl>
          </Box>

          <Box my={1}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel variant="outlined" sx={labelStyles}>
                Recuring Interval (In Minutes)
              </InputLabel>
              <TextField
                value={formik.values.recurringInterval}
                name="recurringInterval"
                variant="outlined"
                fullWidth
                type="number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.recurringInterval &&
                  formik.errors.recurringInterval
                }
                helperText={
                  formik.touched.recurringInterval &&
                  formik.errors.recurringInterval
                }
              />
            </FormControl>
          </Box>

          <Button
            startIcon={
              formik.isSubmitting && (
                <CircularProgress severity="success" size={"1rem"} />
              )
            }
            disabled={formik.isSubmitting}
            type="submit"
            size="large"
            variant="contained"
            color="success"
          >
            {formik.isSubmitting ? "Adding Game" : " Add Game"}
          </Button>
        </form>
      </Container>
    </AdminLayout>
  );
}

export default AddInstantGame;
