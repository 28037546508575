import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import axios from "axios";
import useSWR from "swr";
import TableLoader from "../TableLoader/TableLoader";
import { getAuthToken } from "../../utils";
import dayjs from "dayjs";
import HalfErrorScreen from "../HalfErrorScreen/HalfErrorScreen";
import NoDataAvailable from "../EmptyTable/EmptyTable";

function RecentUsers({ headerStyles }) {
  const url = `${process.env.API_URL}user/fetch-users`;

  const fetcher = () => {
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data;
      });
  };
  const { data, error } = useSWR(url, fetcher);

  if (error)
    return (
      <HalfErrorScreen
        title=" Sorry, cant retrieve recent users at this momment"
        subtitle={error.message}
      />
    );

  if (!data) return <TableLoader />;
  return (
    <Box component={Paper} sx={{ padding: "10px 5px" }}>
      <Box sx={{ paddingLeft: "15px" }}>
        <Typography gutterBottom color="success" sx={headerStyles}>
          Recent Users
        </Typography>
      </Box>
      <TableContainer>
        <Table
          sx={{ minWidth: 300 }}
          aria-label="recent users table"
          //   size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell component="th" sx={{ fontWeight: 600 }}>
                Name
              </TableCell>
              <TableCell component="th" sx={{ fontWeight: 600 }}>
                Email
              </TableCell>
              <TableCell component="th" sx={{ fontWeight: 600 }}>
                Date Joined
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data?.data?.length > 0
              ? data?.data?.map((row) => (
                  <TableRow key={row.userId}>
                    <TableCell>
                      {row.firstname} &nbsp; {row.lastname}
                    </TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell style={{ width: 160 }}>
                      {dayjs(row.createdAt).format("MMM D, YYYY")}
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
        {data && data?.data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>
    </Box>
  );
}

export default RecentUsers;
