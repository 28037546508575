import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import axios from "axios";
import { AppContext } from "../../context";
import CircularProgress from "@mui/material/CircularProgress";

import { useFormik } from "formik";
import { mutate } from "swr";
import * as yup from "yup";
import ConfirmAgentFundingDialog from "./ConfirmAgentFundingDialog";
import dayjs from "dayjs";

export default function FundAgentAccountDialog({
  openDialog,
  handleCloseDialog,
  selectedUser,
  agentsURL,
}) {
  const { adminData } = React.useContext(AppContext);
  const [formValues, setFormValues] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [dueDate, setDueDate] = React.useState(new Date());
  const [purpose, setPurpose] = React.useState("topup");

  const handlePurposeChange = (event) => {
    setPurpose(event.target.value);
  };
  const handleCloseConfirmFundingDialog = () => {
    handleCloseDialog();
    setOpen(false);
  };
  // const { handleOpenSnackbar } = React.useContext(AppContext);
  const formik = useFormik({
    initialValues: {
      amount: 1,
      narration: "",
      userId: "",
      payReference: "",
      provider: "",
      expiresAt: "",
    },
    onSubmit: (values, helpers) => {
      values.userId = selectedUser?.userId;
      values.receipientId = selectedUser?.userId;
      values.provider = "source";
      if (purpose === "overdraft") {
        values.expiresAt = dueDate?.toISOString();
      } else {
        values.expiresAt = "";
      }
      if (purpose === "topup") {
        values.adminId = adminData?.adminId;
      }
      setFormValues((prev) => values);
      // helpers.setSubmitting(false);
      setOpen(true);
    },
    validationSchema: yup.object().shape({
      // payReference: yup.string().required().label("Payment Reference"),
      narration: yup.string().required().label("Narration"),
      amount: yup.number().positive().min(1).required().label("Amount"),
      expiresAt: yup.date().when(purpose, {
        is: "overdraft",
        then: yup.date().label("Due date for remission"),
      }),
    }),
  });

  return (
    <div>
      {open ? (
        <ConfirmAgentFundingDialog
          values={formValues}
          selectedUser={selectedUser}
          agentsURL={agentsURL}
          handleFailure={handleCloseConfirmFundingDialog}
          openDialog={open}
          purpose={purpose}
        />
      ) : null}
      <Dialog
        fullWidth
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
      >
        <form noValidate onSubmit={formik.handleSubmit}>
          <DialogTitle sx={{ textAlign: "center" }}>
            FUND AGENT ACCOUNT
          </DialogTitle>
          <DialogContent>
            <Box my={3}>
              <FormControl>
                <FormLabel color="success" id="demo-controlled">
                  Purpose for Funding
                </FormLabel>
                <RadioGroup
                  color="success"
                  row
                  aria-labelledby="demo-controlled"
                  name="controlled"
                  value={purpose}
                  onChange={handlePurposeChange}
                >
                  <FormControlLabel
                    value="topup"
                    control={<Radio color="success" />}
                    label="Top Up Account"
                  />
                  <FormControlLabel
                    value="overdraft"
                    control={<Radio color="success" />}
                    label="Send Overdraft"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box my={2}>
              <FormControl variant="outlined" fullWidth>
                {/* <InputLabel variant="outlined" narrati>Amount</InputLabel> */}
                <TextField
                  label="Amount"
                  error={formik.errors.amount && formik.touched.amount}
                  value={formik.values.amount}
                  name="amount"
                  type="number"
                  variant="outlined"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  helperText={formik.touched.amount && formik.errors.amount}
                />
              </FormControl>
            </Box>
            {/* <Box my={2}>
              <FormControl variant="outlined" fullWidth>
                              <TextField
                  label="Payment Reference"
                  value={formik.values.payReference}
                  error={
                    formik.touched.payReference && formik.errors.payReference
                  }
                  name="payReference"
                  variant="outlined"
                  fullWidth
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.payReference && formik.errors.payReference
                  }
                />
              </FormControl>
            </Box> */}
            <Box my={2}>
              <FormControl variant="outlined" fullWidth>
                {/* <InputLabel variant="outlined">Narration</InputLabel> */}
                <TextField
                  label="Narration"
                  value={formik.values.narration}
                  error={formik.touched.narration && formik.errors.narration}
                  name="narration"
                  variant="outlined"
                  fullWidth
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.narration && formik.errors.narration
                  }
                />
              </FormControl>
            </Box>
            {purpose === "overdraft" ? (
              <Box my={2}>
                <FormControl variant="outlined" fullWidth>
                  {/* <InputLabel variant="outlined" id="statusSelect" sx={labelStyles}>
            Due Date for Remission (Optional)
            </InputLabel> */}
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Due Date for Remission (Optional)"
                      name="expiresAt"
                      value={dueDate}
                      minDate={new Date()}
                      onChange={(value) => {
                        setDueDate((prev) => value);
                        formik.setFieldValue("expiresAt", value);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth name="expiresAt" />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Box>
            ) : null}
            <br />
          </DialogContent>
          <DialogActions sx={{ paddingRight: "15px" }}>
            <Button
              type="button"
              disabled={formik.isSubmitting}
              color="error"
              onClick={() => handleCloseDialog()}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={formik.isSubmitting}
              startIcon={
                formik.isSubmitting && <CircularProgress size={"1rem"} />
              }
              variant="contained"
              color="success"
            >
              {formik.isSubmitting ? "Processing" : "Fund"}
            </Button>
          </DialogActions>{" "}
        </form>
      </Dialog>
    </div>
  );
}
