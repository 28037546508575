import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/CardContent";
import MenuItem from "@mui/material/MenuItem";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Formik, Form } from "formik";
import * as yup from "yup";
import axios from "axios";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import { AppContext } from "../context";
import {
  labelStyles,
  getAuthToken,
  checkExpiredAuthToken,
  BannerCategories,
} from "../utils";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/FullScreenError/FullScreenError";

let initialValues = {
  title: "",
  content: "",
  imageUrl: "",
  category: "",
  url: "",
};
let bannerSchema = yup.object().shape({
  title: yup.string().required().label("Title"),
  content: yup.string().required().label("Content"),
  imageUrl: yup.string().required().label("Image URL"),
  category: yup.string().required(),
  url: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    )
    .required(),
});

function EditBanner() {
  const [uploadedImageURL, setUploadedImageURL] = React.useState("");
  const [image, setImage] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState({});
  const [errorValue, setErrorValue] = React.useState({});

  const { bannerId } = useParams();

  const { handleOpenSnackbar } = React.useContext(AppContext);
  const copyToClipboard = () => {
    navigator.clipboard.writeText(uploadedImageURL);
    handleOpenSnackbar({
      message: "Image URL Copied",
      severity: "info",
    });
  };

  React.useEffect(() => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    axios
      .get(`${process.env.API_URL}banner/fetch-banner/${bannerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(res.data?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorValue(err);
      });
  }, []);

  const handleSubmitImage = (values, helpers) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    const formData = new FormData();

    formData.append("image", image);

    axios
      .post(`${process.env.API_URL}upload`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setUploadedImageURL(res?.data?.data?.data?.imageUrl);
        handleOpenSnackbar({
          message: "Image Uploaded Successfully",
          severity: "success",
        });
        helpers.setSubmitting(false);
      })
      .catch((err) => {
        console.log(err);
        handleOpenSnackbar({
          message: "Image Could Not Be Uploaded, try again",
          severity: "error",
        });
        helpers.setSubmitting(false);
      });
  };

  const handleSubmit = (values, helpers) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    axios
      .put(`${process.env.API_URL}banner/update-banner/${bannerId}`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        handleOpenSnackbar({
          message: "Banner Updated Successfully",
          severity: "success",
        });
        helpers.setSubmitting(false);
      })
      .catch((err) => {
        console.log(err);
        handleOpenSnackbar({
          message: "Sorry, Banner Could Not Be Updated",
          severity: "error",
        });
        helpers.setSubmitting(false);
      });
  };

  if (errorValue && errorValue?.message) {
    return (
      <FullScreenError
        title="Sorry, the requested data cannot be fetched at this momment"
        subtitle={errorValue?.message}
      />
    );
  }
  if (loading) {
    return <FullScreenLoader />;
  }
  if (!loading && data?.title) {
    initialValues.title = data?.title;
    initialValues.content = data?.content;
    initialValues.imageUrl = data?.imageUrl;
    initialValues.category = data?.category;
    initialValues.url = data?.url;
  }
  return (
    <AdminLayout>
      <MetaDecorator title="Update Banner - Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Update Banner" />
        <Container maxWidth="lg">
          <Box my={1}>
            <Typography gutterBottom>
              To edit a Banner, first upload the image, then copy the uploaded
              image URL and paste in the field for the image URL
            </Typography>
          </Box>
        </Container>
        <Container maxWidth="lg">
          <div>
            <Formik
              initialValues={{ image: "" }}
              onSubmit={(values, helpers) => handleSubmitImage(values, helpers)}
            >
              {({ isSubmitting }) => (
                <Form noValidate>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStyles}>
                        Select Image to upload
                      </InputLabel>
                      <TextField
                        type="file"
                        variant="outlined"
                        fullWidth
                        onChange={(e) => {
                          setImage(e.target.files[0]);
                        }}
                      />
                    </FormControl>
                  </Box>
                  {uploadedImageURL && (
                    <Box my={1}>
                      <Box
                        my={2}
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "space-betwwen",
                          justifyCotent: "space-between",
                        }}
                      >
                        <Typography
                          gutterBottom
                          color="text.primary"
                          sx={{
                            flexGrow: 1,
                          }}
                        >
                          Uploaded Image URL
                        </Typography>{" "}
                        <Button
                          type="button"
                          size="small"
                          variant="contained"
                          color="success"
                          startIcon={<ContentCopyIcon />}
                          onClick={() => copyToClipboard()}
                        >
                          Copy Image URL
                        </Button>
                      </Box>
                      <Typography color="primary">
                        {uploadedImageURL}
                      </Typography>
                    </Box>
                  )}
                  <br />
                  <Button
                    type="submit"
                    variant="contained"
                    color="success"
                    startIcon={<CameraAltIcon />}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Uploading Image" : "Upload Image"}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </Container>
        <br />
        <br />
        <Container maxWidth="lg">
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={bannerSchema}
              enableReinitialize={true}
              validateOnBlur={true}
              validateOnChange={true}
              onSubmit={(values, helpers) => handleSubmit(values, helpers)}
            >
              {({
                handleChange,
                handleBlur,
                touched,
                errors,
                setFieldValue,
                isSubmitting,
                values,
              }) => (
                <Form noValidate>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStyles}>
                        Enter the banner title
                      </InputLabel>
                      <TextField
                        error={touched.title && errors.title}
                        value={values?.title || ""}
                        name="title"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.title && errors.title}
                      />
                    </FormControl>
                  </Box>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel
                        variant="outlined"
                        id="roleSelect"
                        sx={labelStyles}
                      >
                        Category
                      </InputLabel>
                      <Select
                        name="category"
                        value={values?.category || ""}
                        labelId="roleSelect"
                        variant="outlined"
                        id="roleSelect"
                        onChange={handleChange}
                      >
                        {BannerCategories.map((item) => (
                          <MenuItem key={item?.value} value={item?.value}>
                            {item?.title}{" "}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStyles}>
                        Enter the content for the banner
                      </InputLabel>
                      <TextField
                        error={touched.content && errors.content}
                        value={values?.content || ""}
                        name="content"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.content && errors.content}
                      />
                    </FormControl>
                  </Box>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStyles}>
                        Base URL (e.g https://example.com)
                      </InputLabel>
                      <TextField
                        error={touched.url && errors.url}
                        value={values?.url || ""}
                        name="url"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.url && errors.url}
                      />
                    </FormControl>
                  </Box>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStyles}>
                        Banner Image
                      </InputLabel>
                      <TextField
                        error={touched.imageUrl && errors.imageUrl}
                        value={values?.imageUrl || ""}
                        name="imageUrl"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.imageUrl && errors.imageUrl}
                      />
                    </FormControl>
                  </Box>

                  <br />
                  <Button
                    disabled={isSubmitting}
                    startIcon={
                      isSubmitting && <CircularProgress size={"1rem"} />
                    }
                    type="submit"
                    size="large"
                    variant="contained"
                    color="success"
                  >
                    {isSubmitting ? "Updating Banner" : "Update Banner"}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </Container>
    </AdminLayout>
  );
}

export default EditBanner;

// https://lottery-api.zendost.co/static/image-1646826327990-977469812.jpg
