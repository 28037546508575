import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
// import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { useHistory, useLocation } from "react-router-dom";
import { AppContext } from "../context";
import Logo from "../images/logo1.jpg";
import { getAuthToken } from "../utils";

export default function Login() {
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();

  const { handleOpenSnackbar, handleSetAdminData, auth } =
    React.useContext(AppContext);

  React.useEffect(() => {
    let token = getAuthToken();
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken) {
        history.push("/dashboard");
      }
    }
  }, []);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values, helpers) => {
      return axios
        .post(`${process.env.API_URL}admin/login`, values)
        .then((res) => {
          let decodedToken = jwtDecode(res.data?.data?.data?.token);
          // handleSetAdminData(decodedToken);
          localStorage.setItem("token", res?.data.data?.data?.token);

          return history.replace("/dashboard");
          // helpers.setSubmitting(false);
          // helpers.resetForm();
        })
        .catch((err) => {
          helpers.setSubmitting(false);
          if (err?.response?.data?.responsemessage) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage,
              severity: "error",
            });
          }
          if (err?.message) {
            return handleOpenSnackbar({
              message: err?.message,
              severity: "error",
            });
          }
        });
    },
    validationSchema: yup.object().shape({
      email: yup.string().required().label("Email"),
      password: yup.string().min(6).required().label("Password"),
    }),
  });

  const formBoxStyle = {
    width: "100%",
  };
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "600px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        component={Paper}
      >
        <Box
          mb={2}
          py={3}
          sx={{
            width: "100%",
            background: `${theme.palette.success.main}`,
            background: `linear-gradient(300deg, rgba(1,94,21,1) 0%, rgba(47,120,0,1) 9%, rgba(1,94,21,1) 71%)`,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "8px",
            }}
          >
            <img src={Logo} alt="bet 9ja" style={{ width: "70px" }} />
          </div>
          <Typography
            align="center"
            gutterBottom
            variant="h5"
            sx={{ color: "#ffffff", fontWeight: 500 }}
          >
            ADMIN LOGIN
          </Typography>
        </Box>
        <form
          style={{ width: "85%" }}
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <Typography align="center" gutterBottom sx={{ fontWeight: 500 }}>
            Welcome Back, Sign In to Continue
          </Typography>
          <br />
          <Box sx={formBoxStyle} my={3}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="Email"
                type="email"
                error={formik.errors.email && formik.touched.email}
                name="email"
                variant="outlined"
                fullWidth
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                helperText={formik.touched.email && formik.errors.email}
              />
            </FormControl>
          </Box>
          <Box sx={formBoxStyle} my={3}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="Password"
                type="password"
                error={formik.errors.password && formik.touched.password}
                name="password"
                variant="outlined"
                fullWidth
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                helperText={formik.touched.password && formik.errors.password}
              />
            </FormControl>
          </Box>

          <Box mt={2} mb={5}>
            <Button
              color="success"
              fullWidth
              variant="contained"
              size="large"
              type="submit"
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? "Logging in..." : "Sign in"}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}
