import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useSWR from "swr";
import axios from "axios";
import {
  checkExpiredAuthToken,
  getAuthToken,
  getSumFromObj,
} from "../../../utils";
import HalfErrorScreen from "../../HalfErrorScreen/HalfErrorScreen";
import ReportTable from "./ReportTable";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import { useParams } from "react-router-dom";
import NoDataAvailable from "../../EmptyTable/EmptyTable";
import TableLoader from "../../TableLoader/TableLoader";

dayjs.extend(weekday);

let days = [];
// console.log("ggggggg");
for (let index = 0; index < 7; index++) {
  const element = dayjs().day(index);
  days.push(element);
}

// for (let index = 0; index < dayjs().daysInMonth(); index++) {
//   const element = dayjs().day(index);
//   days.push(element);
//   console.log(
//     " dayjs().startOf('month').add(index,'day')",
//     dayjs().startOf("month").add(index, "day")
//   );
// }

const DailySalesView = ({ value }) => {
  const [selectedDay, setSelectedDay] = useState(dayjs()?.format("DD-MM-YYYY"));
  const params = useParams();
  // console.log("pppppppppppppppppppppp", params);
  const url = `${process.env.API_URL}admin/commissions/fetch-specific-single-agent-report?interval=daily&userId=${params?.userId}&specificDate=${selectedDay}`;

  // const url = `${process.env.API_URL}admin/fetch-specific-player-report?interval=daily&specificDate=10-04-2023`;
  const handleChange = (event, newValue) => {
    setSelectedDay((prev) => newValue);
  };
  const TabsSection = () => {
    return (
      <Box sx={{ width: "auto" }}>
        <Tabs
          centered
          value={selectedDay}
          onChange={handleChange}
          indicatorColor="secondary"
          sx={{ color: "green" }}
        >
          {days.map((item, idx) => (
            <Tab
              value={item?.format("DD-MM-YYYY")}
              label={item?.format("ddd")}
              key={idx}
              title={item?.format("DD/MM/YYYY")}
              disabled={item?.isAfter(dayjs())}
            />
          ))}
        </Tabs>
      </Box>
    );
  };
  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.data?.data);
        return res?.data?.data?.data;
      });
  };
  const { data, error } = useSWR(url, fetcher);

  if (error)
    return (
      <HalfErrorScreen
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );
  if (typeof data === "boolean" && data === false)
    return (
      <>
        <TabsSection />
        <NoDataAvailable />{" "}
      </>
    );
  if (!data) return <TableLoader />;

  return (
    <>
      <TabsSection />
      <ReportTable data={data} value={value} />
    </>
  );
};

export default DailySalesView;
