import "babel-polyfill";
import React, {
  useCallback,
  useContext,
  useRef,
  useState,
  useEffect,
} from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useFormik, Formik, Form, Field } from "formik";
import * as yup from "yup";
import axios from "axios";
import useSWR from "swr";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import { AppContext } from "../context";
import {
  checkExpiredAuthToken,
  getAuthToken,
  labelStyles,
  labelStylesTwo,
  labelStylesThree,
  SALARY_FOR_LIFE,
  MEGA_7,
  LEGENDARY_LOTTO,
  FIVE_OF_NINETY,
  AFRIKENO,
  EAZY_WIN,
  KENO,
  SIX_FOUR_NINE,
  META4,
  CASH_OUT,
  PERFECT_BALLS,
  METRO,
  LOTTO,
  SIX49_BETOPTIONS,
  SIX49_OVEROPTIONS,
  SIX49_UNDEROPTIONS,
  SEVEN49_BETOPTIONS,
  SEVEN49_OVEROPTIONS,
  SEVEN49_UNDEROPTIONS,
  SEVEN_FOUR_NINE,
  POOL_OF_FAME_BETOPTIONS,
  POOL_OF_FAME,
  INDOOR_GAME,
  GHANA_GAME,
  LOTTO_CONTINENTAL_BET_OPTIONS,
  LOTTO_CONTINENTAL_RESULT_OPTIONS,
  MARIO_KENO_BETOPTIONS,
  BINGO_BALLS_BETOPTIONS,
  BINGO_BALLS_OVER_OPTIONS,
  BINGO_BALLS_UNDER_OPTIONS,
  MARIO_KENO,
  LOTTO_CONTINENTAL,
  BINGO_BALLS,
  MARIO_KENO_OVER_OPTIONS,
  MARIO_KENO_UNDER_OPTIONS,
  METRO_OVEROPTIONS,
  METRO_UNDEROPTIONS,
  SIX49_RESULT_OPTIONS,
} from "../utils";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import FormLoader from "../components/FormLoader/FormLoader";
import produce from "immer";

function AddGame() {
  const [selectedSlug, setSelectedSlug] = useState("");
  const [booster, setBoosterOption] = useState([]);
  const [betOption, setBetOption] = useState([]);
  const [checkedBetOptions, setCheckedBetOptions] = useState([]);
  const [betOptionsValues, setBetOptionsValues] = useState([]);
  const [over, setOverOption] = useState([]);
  const [under, setUnderOption] = useState([]);
  const [isSubmitting, setisSubmitting] = useState(false);
  const [loadingbooster, setLoadingBoosterOption] = useState(false);
  const [loadingbetOption, setLoadingBetOption] = useState(false);
  const [loadingover, setLoadingOverOption] = useState(false);
  const [loadingunder, setLoadingUnderOption] = useState(false);
  const [resultTypes, setResultTypes] = useState([]);
  const [loadingResultTypes, setLoadingResultTypes] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { handleOpenSnackbar } = useContext(AppContext);
  const specialInputRef = useRef();
  const history = useHistory();
  // const settingsURL = `${process.env.API_URL}site-settings/fetch-settings`;
  const settingsURL = `${process.env.API_URL}site-settings/fetch-setting-by-slug/game-categories`;

  // const isOptionChecked = useCallback((list = [], value = "") => {
  //   console.log("ssssssssssssssss", list);
  //   if (list?.find?.((item) => item?.name === value)) {
  //     return true;
  //   }
  //   return false;
  // }, []);
  const handleBetOptionChange = (e) => {
    const value = e.target.value;
    const index = betOptionsValues?.indexOf(value);

    if (index === -1) {
      setBetOptionsValues((prevValue) => [...prevValue, value]);
      setCheckedBetOptions((prevState) => [
        ...prevState,
        { name: value, multiplier: "1" },
      ]);
    } else {
      setBetOptionsValues((prevValue) =>
        prevValue.filter((item) => item !== value)
      );
      setCheckedBetOptions((prevState) =>
        prevState.filter((item) => item?.name !== value)
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      lotteryName: "",
      category: "",
      slug: "",
      imageUrl: "",
      audioUrl: "",
      resultOptions: [],
      betOptions: [],
      boosterOptions: [],
      overOptions: [],
      underOptions: [],
      setA: false,
      setB: false,
    },
    onSubmit: async (values, helpers) => {
      let lotterySlug = values.lotteryName;
      // console.log("values", values.overOptions);
      let overSubmitOptions = [];
      let underSubmitOptions = [];
      let betSubmitOptions = [];
      over?.map((item) => {
        if (values.overOptions?.includes(item?.name)) {
          overSubmitOptions.push(item);
        }
      });
      under?.map((item) => {
        if (values.underOptions?.includes(item?.name)) {
          underSubmitOptions.push(item);
        }
      });
      for (let index = 0; index < checkedBetOptions.length; index++) {
        const element = checkedBetOptions[index];

        let found = betOption?.find(
          (item) =>
            item?.name?.toLowerCase() === element?.name?.toLowerCase() ||
            item?.value?.toLowerCase() === element?.name?.toLowerCase()
        );

        if (found) {
          betSubmitOptions.push({
            ...found,
            name: found?.value,
            multiplier: element?.multiplier,
          });
        }
        found = null;
      }
      // console.log("under options values", underSubmitOptions);
      // console.log("over options values", overSubmitOptions);
      lotterySlug = lotterySlug.replaceAll(" ", "-");
      lotterySlug = lotterySlug.toLowerCase();
      setisSubmitting((prev) => true);
      checkExpiredAuthToken();
      const token = getAuthToken();

      values.overOptions = JSON.stringify(overSubmitOptions);
      values.underOptions = JSON.stringify(underSubmitOptions);
      values.betOptions = JSON.stringify(betSubmitOptions);
      values.boosterOptions = JSON.stringify(values.boosterOptions);
      values.resultOptions = JSON.stringify(values.resultOptions);
      values.category = selectedSlug;

      if (
        selectedSlug === LEGENDARY_LOTTO ||
        selectedSlug === INDOOR_GAME ||
        selectedSlug === FIVE_OF_NINETY ||
        selectedSlug === GHANA_GAME
      ) {
        values.setA = true;
        values.setB = true;
      }
      const formData = new FormData();

      formData.append("lotteryName", values.lotteryName);
      formData.append("category", values.category);
      formData.append("slug", lotterySlug);
      formData.append("imageUrl", values.imageUrl);
      formData.append("audioUrl", values.audioUrl);
      formData.append("resultOptions", values.resultOptions);
      formData.append("betOptions", values.betOptions);
      formData.append("boosterOptions", values.boosterOptions);
      formData.append("overOptions", values.overOptions);
      formData.append("underOptions", values.underOptions);
      formData.append("setA", values.setA);
      formData.append("setB", values.setB);

      axios
        .post(`${process.env.API_URL}game/create-lottery`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          helpers.setSubmitting(false);
          setisSubmitting((prev) => false);
          helpers.resetForm();
          if (res?.data?.message) {
            handleOpenSnackbar({
              message: res?.data?.message,
              severity: "success",
            });
            setTimeout(() => {
              return history.push("/games");
            }, 1500);
          }
          handleOpenSnackbar({
            message: "Game Created Successfully",
            severity: "success",
          });
          setTimeout(() => {
            return history.push("/games");
          }, 1500);
        })
        .catch((err) => {
          helpers.setSubmitting(false);
          setisSubmitting((prev) => false);
          if (err?.response?.data?.responsemessage?.msg) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage?.msg,
              severity: "error",
            });
          }
          if (err?.response?.data?.responsemessage) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage,
              severity: "error",
            });
          }
          handleOpenSnackbar({
            message: "Sorry, Game Category Could Not Be Created",
            severity: "error",
          });
        });
    },
    validationSchema: yup.object().shape({
      lotteryName: yup.string().required().label("Lottery Name"),
      // slug: yup.string().required().label("Alternate Name"),
    }),
  });

  const fetcher = async () => {
    try {
      checkExpiredAuthToken();
      const token = getAuthToken();
      const res = await axios.get(settingsURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(res?.data?.data?.data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetcher();
  }, []);

  const resetFields = () => {
    setCheckedBetOptions((prevState) => []);
    setBetOptionsValues((prevState) => []);
    setBoosterOption((prevState) => []);
    setResultTypes((prevState) => []);
    setOverOption((prevState) => []);
    setUnderOption((prevState) => []);
  };
  const getOptions = () => {
    // console.log("zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz");
    resetFields();
    if (selectedSlug === FIVE_OF_NINETY) {
      getFiveNinetyOptions();
    } else if (selectedSlug === SALARY_FOR_LIFE) {
      getSalary4LifeOption();
    } else if (selectedSlug === LEGENDARY_LOTTO) {
      getLegendaryLottoOption();
    } else if (selectedSlug === MEGA_7) {
      getMega7Option();
    } else if (selectedSlug === AFRIKENO) {
      getAfrikenoOption();
    } else if (selectedSlug === EAZY_WIN) {
      getEazyWinOption();
    } else if (selectedSlug === KENO) {
      getKenoOption();
    } else if (selectedSlug === SIX_FOUR_NINE) {
      get649Option();
    } else if (selectedSlug === META4) {
      getmeta4Options();
    } else if (selectedSlug === CASH_OUT) {
      getCashOutOptions();
    } else if (selectedSlug === PERFECT_BALLS) {
      getPerfectBallsOptions();
    } else if (selectedSlug === METRO) {
      getMetroOptions();
    } else if (selectedSlug === LOTTO) {
      getLottoOptions();
    } else if (selectedSlug === SEVEN_FOUR_NINE) {
      get749Option();
    } else if (selectedSlug === POOL_OF_FAME) {
      getPoolofFameOptions();
    } else if (selectedSlug === MARIO_KENO) {
      getMariokenoOptions();
    } else if (selectedSlug === LOTTO_CONTINENTAL) {
      getLottoContinetalOptions();
    } else if (selectedSlug === BINGO_BALLS) {
      getBingoBallsOptions();
    }
  };

  const getFiveNinetyOptions = () => {
    getResultTypes("five-ninety-result-types");
    getBetOption("five-ninety-bet-options");
    getBoosterOption("five-ninety-booster-options");
    getOverOption("over-bet-options");
    getUnderOption("under-bet-options");
  };

  const getSalary4LifeOption = () => {
    getBetOption("salary-for-life-bet-options");
    setBoosterOption([]);
    setResultTypes([]);
    setOverOption([]);
    setUnderOption([]);
  };

  const getLegendaryLottoOption = () => {
    getBetOption("legendary-lotto-bet-options");
    getResultTypes("legendary-lotto-result-types");
    getBoosterOption("legendary-lotto-booster-options");
    getOverOption("over-bet-options");
    getUnderOption("under-bet-options");
  };

  const getMega7Option = () => {
    getBetOption("mega-7-bet-options");
    setResultTypes([]);
    setBoosterOption([]);
    setOverOption([]);
    setUnderOption([]);
  };
  const getAfrikenoOption = () => {
    getBetOption("afrikeno-bet-options");
    setResultTypes([]);
    setBoosterOption([]);
    getOverOption("afrikeno-over-bet-options");
    getUnderOption("afrikeno-under-bet-options");
  };
  const getEazyWinOption = () => {
    getBetOption("eazy-win-bet-options");
    setResultTypes([]);
    setBoosterOption([]);
    getOverOption("eazy-win-over-bet-options");
    getUnderOption("eazy-win-under-bet-options");
  };
  const get649Option = () => {
    setBoosterOption([]);
    setResultTypes([]);
    getBetOption(SIX49_BETOPTIONS);
    getOverOption(SIX49_OVEROPTIONS);
    getUnderOption(SIX49_UNDEROPTIONS);
  };
  const get749Option = () => {
    setResultTypes([]);
    setBoosterOption([]);
    getBetOption(SEVEN49_BETOPTIONS);
    getOverOption(SEVEN49_OVEROPTIONS);
    getUnderOption(SEVEN49_UNDEROPTIONS);
  };

  const getKenoOption = () => {
    setResultTypes([]);
    setBoosterOption([]);
    getBetOption("keno-bet-options");
    getOverOption("keno-over-bet-options");
    getUnderOption("keno-under-bet-options");
  };

  const getmeta4Options = () => {
    setResultTypes([]);
    getBetOption("meta4-bet-options");
    setBoosterOption([]);
    getOverOption("meta4-over-bet-options");
    getUnderOption("meta4-under-bet-options");
  };

  const getCashOutOptions = () => {
    getBetOption("cash-out-bet-options");
    setBoosterOption([]);
    setResultTypes([]);
    setOverOption([]);
    setUnderOption([]);
  };

  const getPerfectBallsOptions = () => {
    getBetOption("perfect-balls-bet-options");
    setResultTypes([]);
    setBoosterOption([]);
    setOverOption([]);
    setUnderOption([]);
  };

  const getMetroOptions = () => {
    getBetOption("metro-bet-options");
    setResultTypes([]);
    setBoosterOption([]);
    getOverOption(METRO_OVEROPTIONS);
    getUnderOption(METRO_UNDEROPTIONS);
  };
  const getLottoOptions = () => {
    getBetOption("lotto-bet-options");
    getResultTypes("lotto-result-options");
    getOverOption("lotto-over-bet-options");
    getUnderOption("lotto-under-bet-options");
    setBoosterOption([]);
  };
  const getMariokenoOptions = () => {
    getBetOption(MARIO_KENO_BETOPTIONS);
    setResultTypes([]);
    getOverOption(MARIO_KENO_OVER_OPTIONS);
    getUnderOption(MARIO_KENO_UNDER_OPTIONS);
    setBoosterOption([]);
  };
  const getLottoContinetalOptions = () => {
    getBetOption(LOTTO_CONTINENTAL_BET_OPTIONS);
    getResultTypes(LOTTO_CONTINENTAL_RESULT_OPTIONS);
    setOverOption([]);
    setUnderOption([]);
    setBoosterOption([]);
  };
  const getBingoBallsOptions = () => {
    getBetOption(BINGO_BALLS_BETOPTIONS);
    getOverOption(BINGO_BALLS_OVER_OPTIONS);
    getUnderOption(BINGO_BALLS_UNDER_OPTIONS);
    setResultTypes([]);
    setBoosterOption([]);
  };

  const getPoolofFameOptions = () => {
    getBetOption(POOL_OF_FAME_BETOPTIONS);
    setResultTypes([]);
    setBoosterOption([]);
    setOverOption([]);
    setUnderOption([]);
  };
  const getBetOption = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingBetOption(true);
    return axios
      .get(
        `${process.env.API_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let { content } = res?.data?.data?.data;
        content = JSON.parse(content);
        setBetOption(content);
        setLoadingBetOption(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const getResultTypes = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingResultTypes(true);
    return axios
      .get(
        `${process.env.API_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let { content } = res?.data?.data?.data;
        content = JSON.parse(content);
        setResultTypes(content);
        setLoadingResultTypes(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const getBoosterOption = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingBoosterOption(true);
    return axios
      .get(
        `${process.env.API_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let { content } = res?.data?.data?.data;
        content = JSON.parse(content);
        setBoosterOption(content);
        setLoadingBoosterOption(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const getOverOption = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingOverOption(true);
    return axios
      .get(
        `${process.env.API_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let { content } = res?.data?.data?.data;
        let newContent = JSON.parse(content);
        setOverOption(newContent);
        setLoadingOverOption(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const getUnderOption = async (value) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    setLoadingUnderOption(true);
    return axios
      .get(
        `${process.env.API_URL}site-settings/fetch-setting-by-slug/${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        let { content } = res?.data?.data?.data;
        let newContent = JSON.parse(content);

        setUnderOption(newContent);
        setLoadingUnderOption(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const handleSelectCategory = (e) => {
    const value = e.target.value;
    setCheckedBetOptions([]);
    setSelectedSlug(value);
    setTimeout(() => {
      specialInputRef.current.click();
    }, 1000);

    // return getOptions();
  };
  const specialOnchange = () => {
    getOptions();
  };

  const formPaperStyle = { padding: 3 };

  if (error)
    return (
      <FullScreenError
        title=" Sorry, data cannot be retrieved at this moment"
        subtitle={error.message}
      />
    );

  if (loading) return <FullScreenLoader />;

  let Categories = data && data?.content ? JSON.parse(data?.content) : [];

  return (
    <AdminLayout>
      <MetaDecorator title="Add New Game Category- Admin Dashboard" />
      <Container maxWidth="xl">
        <PageHeading title="Add New Game Category" />
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={7}>
              <Paper sx={formPaperStyle}>
                <div>
                  <form noValidate onSubmit={formik.handleSubmit}>
                    <Box my={1}>
                      <FormControl>
                        <FormLabel sx={labelStyles}>
                          Select Display Image
                        </FormLabel>
                        <br />
                        <input
                          type="file"
                          name="imageUrl"
                          accept="image/*"
                          onBlur={formik.handleBlur}
                          onChange={(e) =>
                            formik.setFieldValue("imageUrl", e.target.files[0])
                          }
                        />
                      </FormControl>
                    </Box>
                    <Box my={2}>
                      <FormControl>
                        <FormLabel sx={labelStyles}>
                          Select Lottery TV Screen Music (Max of 2mb)
                        </FormLabel>
                        <br />
                        <input
                          type="file"
                          name="audioUrl"
                          accept=".mp3,audio/*"
                          onBlur={formik.handleBlur}
                          onChange={(e) => {
                            formik.setFieldValue("audioUrl", e.target.files[0]);
                          }}
                        />
                      </FormControl>
                    </Box>
                    <Box my={1}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          label="Enter Game Category Title"
                          error={
                            formik.touched.lotteryName &&
                            formik.errors.lotteryName
                          }
                          name="lotteryName"
                          variant="outlined"
                          fullWidth
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          helperText={
                            formik.touched.lotteryName &&
                            formik.errors.lotteryName
                          }
                        />
                      </FormControl>
                    </Box>

                    <Box my={1}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        error={
                          formik.touched.category && formik.errors.category
                        }
                      >
                        <InputLabel variant="outlined" sx={labelStylesThree}>
                          Select Category
                        </InputLabel>
                        {/* <br />
                  <br /> */}
                        <Select
                          // label="Select Category"
                          name="category"
                          // variant="outlined"
                          value={selectedSlug}
                          onChange={handleSelectCategory}
                        >
                          {Categories?.map((category, idx) => (
                            <MenuItem
                              key={`222${idx}${category}`}
                              value={category}
                            >
                              {category}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {formik.touched.category && formik.errors.category}
                        </FormHelperText>
                      </FormControl>
                    </Box>

                    <button
                      style={{ display: "none" }}
                      type="button"
                      ref={specialInputRef}
                      onClick={specialOnchange}
                    >
                      Click
                    </button>
                    {selectedSlug !== SALARY_FOR_LIFE &&
                    selectedSlug !== MEGA_7 ? (
                      <>
                        {loadingResultTypes ? (
                          <FormLoader />
                        ) : (
                          <Box>
                            {resultTypes && resultTypes?.length > 0 && (
                              <Box my={1}>
                                <FormControl variant="outlined" fullWidth>
                                  <InputLabel
                                    variant="outlined"
                                    sx={labelStylesTwo}
                                  >
                                    Result Options
                                  </InputLabel>
                                  <br />
                                  <FormGroup
                                    aria-label="result options"
                                    row
                                    name="resultOptions"
                                  >
                                    {resultTypes &&
                                      resultTypes?.length > 0 &&
                                      resultTypes?.map((item, idx) => (
                                        <FormControlLabel
                                          key={`${item?.value}a0${item?.name}${idx}`}
                                          value={item?.value}
                                          control={
                                            <Checkbox
                                              color="success"
                                              onChange={formik.handleChange}
                                              name="resultOptions"
                                            />
                                          }
                                          label={item?.name}
                                        />
                                      ))}
                                  </FormGroup>
                                </FormControl>
                              </Box>
                            )}
                          </Box>
                        )}
                      </>
                    ) : null}
                    {loadingbetOption ? (
                      <FormLoader />
                    ) : (
                      <Box>
                        {betOption && betOption?.length > 0 && (
                          <Box my={1}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel
                                variant="outlined"
                                sx={labelStylesTwo}
                              >
                                Bet Options
                              </InputLabel>
                              <br />
                              <FormGroup
                                aria-label="bet options"
                                row
                                name="betOptions"
                              >
                                {betOption &&
                                  betOption?.length > 0 &&
                                  betOption?.map((item, idx) => (
                                    <FormControlLabel
                                      key={`${item?.value}0${item?.name}${idx}`}
                                      value={item?.value}
                                      control={
                                        <Checkbox
                                          color="success"
                                          checked={betOptionsValues?.includes(
                                            item?.value
                                          )}
                                          onChange={handleBetOptionChange}
                                          name="betOptions"
                                        />
                                      }
                                      label={item?.name}
                                    />
                                  ))}
                              </FormGroup>
                            </FormControl>
                          </Box>
                        )}
                      </Box>
                    )}

                    {loadingbooster ? (
                      <FormLoader />
                    ) : (
                      <Box>
                        {booster && booster?.length > 0 && (
                          <Box my={1}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel
                                variant="outlined"
                                sx={labelStylesTwo}
                              >
                                Booster Options
                              </InputLabel>
                              <br />
                              <FormGroup
                                aria-label="booster options"
                                row
                                name="boosterOptions"
                                onChange={formik.handleChange}
                              >
                                {booster &&
                                  booster?.length > 0 &&
                                  booster?.map((item) => (
                                    <FormControlLabel
                                      key={item.value}
                                      value={item.value}
                                      control={
                                        <Checkbox
                                          color="success"
                                          onChange={formik.handleChange}
                                          name="boosterOptions"
                                        />
                                      }
                                      label={item.name}
                                    />
                                  ))}
                              </FormGroup>
                            </FormControl>
                          </Box>
                        )}
                      </Box>
                    )}

                    {loadingover ? (
                      <FormLoader />
                    ) : (
                      <Box>
                        {over && over?.length > 0 && (
                          <Box my={1}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel
                                variant="outlined"
                                sx={labelStylesTwo}
                              >
                                Over Options
                              </InputLabel>
                              <br />
                              <FormGroup
                                aria-label="over options"
                                row
                                name="overOptions"
                              >
                                {over &&
                                  over?.length > 0 &&
                                  over?.map((item, idx) => (
                                    <FormControlLabel
                                      key={`${item?.name}p12${idx}`}
                                      value={item?.name}
                                      control={
                                        <Checkbox
                                          color="success"
                                          onChange={formik.handleChange}
                                          name="overOptions"
                                        />
                                      }
                                      label={item?.name}
                                    />
                                  ))}
                              </FormGroup>
                            </FormControl>
                          </Box>
                        )}
                      </Box>
                    )}

                    {loadingunder ? (
                      <FormLoader />
                    ) : (
                      <Box>
                        {under && under?.length > 0 && (
                          <Box my={1}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel
                                variant="outlined"
                                sx={labelStylesTwo}
                              >
                                Under Options
                              </InputLabel>
                              <br />
                              <FormGroup
                                aria-label="under options"
                                row
                                name="underOptions"
                                onChange={formik.handleChange}
                              >
                                {under &&
                                  under?.length > 0 &&
                                  under?.map((item, idx) => (
                                    <FormControlLabel
                                      key={`${item?.name}ew${idx}`}
                                      value={item?.name}
                                      control={
                                        <Checkbox
                                          color="success"
                                          onChange={formik.handleChange}
                                          name="underOptions"
                                        />
                                      }
                                      label={item?.name}
                                    />
                                  ))}
                              </FormGroup>
                            </FormControl>
                          </Box>
                        )}
                      </Box>
                    )}

                    <br />
                    <Button
                      startIcon={
                        isSubmitting && (
                          <CircularProgress severity="success" size={"1rem"} />
                        )
                      }
                      type="submit"
                      disabled={isSubmitting}
                      size="large"
                      variant="contained"
                      color="success"
                    >
                      {isSubmitting ? "Adding Game" : " Add Game"}
                    </Button>
                  </form>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={5}>
              <Paper sx={formPaperStyle}>
                {checkedBetOptions?.length > 0 &&
                  checkedBetOptions?.map((item, idx) => (
                    <Box
                      key={`${item?.name}pp`}
                      my={1}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormControl variant="outlined">
                        <TextField
                          label="Bet Option"
                          variant="outlined"
                          size="small"
                          value={item?.name}
                          readOnly
                        />
                      </FormControl>
                      &nbsp;
                      <FormControl variant="outlined">
                        <TextField
                          label="Multiplier"
                          variant="outlined"
                          size="small"
                          value={item?.multiplier}
                          onChange={(e) => {
                            const value = e.target.value;
                            setCheckedBetOptions((currentValue) =>
                              produce(currentValue, (draft) => {
                                draft[idx].multiplier = value;
                              })
                            );
                          }}
                        />
                      </FormControl>
                    </Box>
                  ))}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </AdminLayout>
  );
}

export default React.memo(AddGame);
