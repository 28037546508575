import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import DatePicker from "@mui/lab/DatePicker";
import InputLabel from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import { AppContext } from "../context";
import {
  checkExpiredAuthToken,
  getAuthToken,
  IMAGE_PREVIEW_HEIGHT,
  IMAGE_PREVIEW_WIDTH,
  imageBox,
  imagePreviewStyle,
  labelStyles,
  POOL_OF_FAME,
  WeekdayList,
} from "../utils";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/FullScreenError/FullScreenError";

function EditInstantGame() {
  const [data, setData] = React.useState(undefined);
  const [error, setError] = React.useState(undefined);
  const [startTime, setStartTime] = React.useState(new Date());
  const [endTime, setEndTime] = React.useState(new Date());
  const [alternateDate, setAlternateDate] = React.useState(new Date());
  const [foregroundImage, setForegroundImage] = useState("");
  const [foregroundImagePreview, setForegroundImagePreview] = useState("");

  const { handleOpenSnackbar } = React.useContext(AppContext);

  const { lotteryId, gameId, gameCategory } = useParams();
  const url = `${process.env.API_URL}game/fetch-game/${gameId}`;

  const handleSubmitImage = async (value, errorMessage) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    const formData = new FormData();
    formData.append("image", value);
    try {
      const res = await axios.post(`${process.env.API_URL}upload`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res?.data?.data?.data?.imageUrl;
    } catch (error) {
      console.log(error);
      handleOpenSnackbar({
        message: errorMessage,
        severity: "error",
      });
    }
  };

  let initialValues = {
    name: "",
    description: "",
    recurringInterval: 1,
  };
  let gameSchema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required().label("Description"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values, helpers) => {
      checkExpiredAuthToken();
      const token = getAuthToken();
      if (foregroundImage) {
        const foreground = await handleSubmitImage(
          foregroundImage,
          "Sorry, an error occured while uploading the image"
        );
        values.imageUrl = foreground;
      }
      axios
        .put(`${process.env.API_URL}game/update-game/${gameId}`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          helpers.setSubmitting(false);
          helpers.resetForm({ values: initialValues });
          if (res?.data?.message) {
            return handleOpenSnackbar({
              message: res?.data?.message,
              severity: "success",
            });
          }
          handleOpenSnackbar({
            message: "Game Updated Successfully",
            severity: "success",
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((err) => {
          console.log(err?.response?.data?.responsemessage?.msg);
          helpers.setSubmitting(false);
          if (err?.response?.data?.responsemessage?.msg) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage?.msg,
              severity: "error",
            });
          }
          if (err?.response?.data?.responsemessage) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage,
              severity: "error",
            });
          }
          handleOpenSnackbar({
            message: "Sorry, Game Could Not Be Updated",
            severity: "error",
          });
        });
    },
    validationSchema: gameSchema,
  });
  const fetcher = async () => {
    try {
      checkExpiredAuthToken();
      const token = getAuthToken();
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      initialValues.name = res?.data?.data?.data?.name;
      initialValues.description = res?.data?.data?.data?.description;
      initialValues.recurringInterval =
        res?.data?.data?.data?.recurringInterval;
      initialValues.imageUrl = res?.data?.data?.data?.imageUrl;

      setData(res?.data?.data?.data);
      // setLoading(false)
      return res?.data?.data?.data;
    } catch (error) {
      setError(error);
    }
  };

  React.useEffect(() => {
    fetcher();
  }, []);

  if (error)
    return (
      <FullScreenError
        title=" Sorry, cant retrieve game data at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  return (
    <AdminLayout>
      <MetaDecorator title="Update Instant Game Instance - Admin Dashboard" />
      <Container maxWidth="xl">
        <PageHeading title="Update Instant Game" />
        <form onSubmit={formik.handleSubmit}>
          <Box my={1} sx={imageBox}>
            <FormControl variant="outlined">
              <p>Select image to upload</p>
              <input
                accept="image/*"
                type="file"
                variant="outlined"
                onChange={(e) => {
                  setForegroundImage(e.target.files[0]);
                  setForegroundImagePreview(
                    URL.createObjectURL(e.target.files[0])
                  );
                }}
              />
            </FormControl>
            {data?.imageUrl && !foregroundImagePreview ? (
              <img
                src={data?.imageUrl}
                alt="game"
                width={IMAGE_PREVIEW_WIDTH}
                height={IMAGE_PREVIEW_HEIGHT}
                style={imagePreviewStyle}
              />
            ) : (
              <div>
                {foregroundImagePreview && (
                  <img
                    src={foregroundImagePreview}
                    alt="game"
                    width={IMAGE_PREVIEW_WIDTH}
                    height={IMAGE_PREVIEW_HEIGHT}
                    style={imagePreviewStyle}
                  />
                )}
              </div>
            )}
          </Box>
          <Box my={1}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel variant="outlined" sx={labelStyles}>
                Enter Game Title
              </InputLabel>
              <TextField
                error={formik.touched.name && formik.errors.name}
                value={formik.values.name}
                name="name"
                variant="outlined"
                fullWidth
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                helperText={formik.touched.name && formik.errors.name}
              />
            </FormControl>
          </Box>

          <Box my={1}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="Description"
                name="description"
                variant="outlined"
                value={formik.values.description}
                fullWidth
                multiline
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.description && formik.errors.description}
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
            </FormControl>
          </Box>

          <Box my={1}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel variant="outlined" sx={labelStyles}>
                Recuring Interval (In Minutes)
              </InputLabel>
              <TextField
                value={formik.values.recurringInterval}
                disabled
                name="recurringInterval"
                variant="outlined"
                fullWidth
                type="number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.recurringInterval &&
                  formik.errors.recurringInterval
                }
                helperText={
                  formik.touched.recurringInterval &&
                  formik.errors.recurringInterval
                }
              />
            </FormControl>
          </Box>

          <br />
          <Button
            startIcon={
              formik.isSubmitting && (
                <CircularProgress severity="success" size={"1rem"} />
              )
            }
            disabled={formik.isSubmitting}
            type="submit"
            size="large"
            variant="contained"
            color="success"
          >
            {formik.isSubmitting ? "Updating Game" : "Update Game"}
          </Button>
        </form>
      </Container>
    </AdminLayout>
  );
}

export default EditInstantGame;
