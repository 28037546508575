import { BUNDLE_BONUS, NORMAL_BONUS } from "../../utils";

export const initialValues = {
  title: "",
  depositRound: "",
  winCount: 1,
  gameType: "",
  betType: "",
  expiration: "",
  minimumDeposit: 1,
  prize: 1,
  gamePlayCount: 1,
  type: "",
  quantity: 0,
  unitCost: 0,
  attachTo: "",
  quantityToAttach: 0,
};
export const bundleInitialValues = {
  title: "",
  winCount: 1,
  gameType: "",
  betType: "",
  expiration: "",
  prize: 1,
  gamePlayCount: 1,
  type: "",
  unitCost: 0,
};

export const depositRoundOptions = [
  "1st",
  "2nd",
  "3rd",
  "4th",
  "5th",
  "6th",
  "7th",
  "8th",
  "9th",
  "10th",
  "every deposit",
  "next deposit",
];

export const bonusTypes = [NORMAL_BONUS, BUNDLE_BONUS];
