import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import { AppContext } from "../../context";
import CircularProgress from "@mui/material/CircularProgress";
import { checkExpiredAuthToken, getAuthToken, labelStyles } from "../../utils";
import { useFormik } from "formik";
import { mutate } from "swr";
import * as yup from "yup";

export default function ModifyMultiplierDialog({
  openDialog,
  handleCloseDialog,
  userId,
  agentURL,
}) {
  const { handleOpenSnackbar } = React.useContext(AppContext);
  const formik = useFormik({
    initialValues: {
      multiplier: 1,
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      checkExpiredAuthToken();
      const token = getAuthToken();
      return axios
        .put(
          `${process.env.API_URL}agent/update-multiplier/${userId}`,
          values,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          mutate(agentURL);
          setSubmitting(false);
          handleCloseDialog();
          if (res?.data?.message) {
            return handleOpenSnackbar({
              message: res?.data?.message,
              severity: "success",
            });
          }
          return handleOpenSnackbar({
            message: "Agent Multiplier Modified Successfully",
            severity: "success",
          });
        })
        .catch((err) => {
          setSubmitting(false);
          if (err?.response?.data?.responsemessage) {
            return handleOpenSnackbar({
              message: err?.response?.data?.responsemessage,
              severity: "error",
            });
          }
          if (err?.response?.data?.data?.responsemessage) {
            return handleOpenSnackbar({
              message: err?.response?.data?.data?.responsemessage,
              severity: "error",
            });
          }
          handleOpenSnackbar({
            message: "Sorry, Agent Multiplier Cound Not Be Modified",
            severity: "error",
          });
        });
    },
    validationSchema: yup.object().shape({
      multiplier: yup.number().positive().min(1).required().label("Multiplier"),
    }),
  });

  return (
    <Dialog
      fullWidth
      open={openDialog}
      onClose={handleCloseDialog}
      maxWidth="sm"
    >
      <form noValidate onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ textAlign: "center" }}>
          MODIFY AGENT MULTIPLIER
        </DialogTitle>
        <DialogContent>
          <Box my={2}>
            <FormControl variant="outlined" fullWidth>
              {/* <InputLabel variant="outlined" >Multiplier</InputLabel> */}
              <TextField
                label="Multiplier"
                error={formik.errors.multiplier && formik.touched.multiplier}
                value={formik.values.multiplier}
                name="multiplier"
                type="number"
                variant="outlined"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                helperText={
                  formik.touched.multiplier && formik.errors.multiplier
                }
              />
            </FormControl>
          </Box>
          <br />
        </DialogContent>
        <DialogActions sx={{ paddingRight: "15px" }}>
          <Button
            type="button"
            disabled={formik.isSubmitting}
            color="error"
            onClick={() => handleCloseDialog()}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={formik.isSubmitting}
            startIcon={
              formik.isSubmitting && <CircularProgress size={"1rem"} />
            }
            variant="contained"
            color="success"
          >
            {formik.isSubmitting ? "Processing" : "Change"}
          </Button>
        </DialogActions>{" "}
      </form>
    </Dialog>
  );
}
