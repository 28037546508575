import * as React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { menuItemsStyles, safeJSONParse } from "../utils";
import axios from "axios";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
// import { AppContext } from "../context";
import {
  checkExpiredAuthToken,
  getAuthToken,
  AddButtonBoxStyles,
} from "../utils";
import useSWR from "swr";
import AddAvatarFormDialog from "../components/AvatarSettingsComponents/AddAvatarFormDialog";
import "../styles/avatarsettings.scss";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import { mutate } from "swr";
import NoDataAvailable from "../components/EmptyTable/EmptyTable";
import UpdateLotteryImageForm from "../components/LotteryImagesComponents/UpdateLotteryImageForm";

function LotteryImagesSettings() {
  const url = `${process.env.API_URL}site-settings/fetch-setting-by-slug/game-categories-with-icons`;

  const [openAvatarForm, setOpenAvatarForm] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event, category) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(category?.name);
  };
  const handleClose = () => {
    setAnchorEl((prev) => null);
    setSelectedId("");
  };

  const handleOpenImageForm = () => setOpenAvatarForm(true);

  const handleCloseUploadForm = async () => {
    await setOpenAvatarForm(false);
    handleClose();
    window.location.reload();
    // mutate(url, async () => {
    //   try {
    //     handleSetSessionStorage();
    //     checkExpiredAuthToken();
    //     const token = getAuthToken();

    //     const res = await axios.get(url, {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     });
    //     return res.data?.data?.data;
    //   } catch (err) {
    //     return err;
    //   }
    // });
  };

  const fetcher = async () => {
    checkExpiredAuthToken();
    const token = getAuthToken();

    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res?.data?.data?.data;
  };

  const { data, error } = useSWR(url, fetcher);

  if (error) {
    return (
      <FullScreenError
        title={"Sorry, we cannot retrieve the data at this moment"}
        subtitle={error.message}
      />
    );
  }

  if (!data) return <FullScreenLoader />;
  const headCells = ["Lottery Category", "Image", "Actions"];
  const newData = safeJSONParse(data?.content);
  //   console.log("dataaa", newData);
  return (
    <AdminLayout>
      <MetaDecorator title="Lottery Image Settings Configuration - Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Lottery Image Settings" />
        <UpdateLotteryImageForm
          openDialog={openAvatarForm}
          selectedId={selectedId}
          handleCloseDialog={handleCloseUploadForm}
          url={url}
        />

        {/* <Box mt={2} mb={1}> */}
        <Box sx={{ padding: "10px 0px", marginBottom: "10px" }}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 300 }}
              aria-label="static pages table"
              //   size="small"
            >
              <TableHead>
                <TableRow>
                  {headCells.map((item) => (
                    <TableCell
                      key={item}
                      component="th"
                      sx={{ fontWeight: 600 }}
                    >
                      {item}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {newData &&
                  newData?.length > 0 &&
                  newData?.map((row) => (
                    <TableRow key={row?.name}>
                      <TableCell>{row?.name}</TableCell>
                      <TableCell>
                        <img
                          src={row?.icon}
                          alt={row?.name}
                          className="lotteryImage"
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={(e) => handleClick(e, row)}>
                          <MoreHorizIcon />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          PaperProps={{
                            elevation: 1,
                            sx: { boxShadow: "2px 2px 4px #eee" },
                          }}
                          open={open}
                          onClose={handleClose}
                        >
                          <MenuItem
                            onClick={() => handleOpenImageForm()}
                            sx={menuItemsStyles}
                          >
                            Update Image
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {newData && newData?.length <= 0 && <NoDataAvailable />}
          </TableContainer>
        </Box>
      </Container>
    </AdminLayout>
  );
}

export default LotteryImagesSettings;
