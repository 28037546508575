import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ReportChart from "../ReportChart";
import useSWR from "swr";
import axios from "axios";
import {
  CASH_OUT,
  checkExpiredAuthToken,
  getAuthToken,
  getSumFromObj,
  FIVE_OF_NINETY,
  KENO,
  LOTTO,
  META4,
  METRO,
  PERFECT_BALLS,
  POOL_OF_FAME,
  SEVEN_FOUR_NINE,
  SIX_FOUR_NINE,
  LOTTO_CONTINENTAL,
  MARIO_KENO,
  BINGO_BALLS,
} from "../../../utils";
import FormLoader from "../../FormLoader/FormLoader";
import HalfErrorScreen from "../../HalfErrorScreen/HalfErrorScreen";
import AfrikenoTable from "./AfrikenoTable";
import EazywinTable from "./EazywinTable";
import LegendaryLottoTable from "./LegendaryLottoTable";
import FiveNinetyTable from "./FiveNinetyTable";
import Mega7Table from "./Mega7Table";
import Salary4LifeTable from "./Salary4LifeTable";
import CashoutTable from "./CashoutTable";
import MetroSalesTable from "./MetroSalesTable";
import Meta4SalesTable from "./Meta4SalesTable";
import PerfectBallsTable from "./PerfectBallsSalesTable";
import Six49Table from "./Six49Table";
import Seven49SalesTable from "./Seven49Table";
import LottoSalesTable from "./LottoSalesTable";
import KenoSalesTable from "./KenoSalesTable";
import PoolOfFameSales from "./PoolOfFameSales";
import LottoContinentalTable from "./LottoContinentalTable";
import MarioKenoTable from "./MarioKenoTable";
import BingoBallsTable from "./BingoBallsTable";

const DailySalesView = ({ value }) => {
  const url = `${process.env.API_URL}admin/fetch-sales-report?interval=daily`;

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.data?.data);
        return res?.data?.data?.data;
      });
  };
  const { data, error } = useSWR(url, fetcher);

  if (error)
    return (
      <HalfErrorScreen
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );

  if (!data)
    return (
      <>
        <FormLoader />
        <FormLoader />
        <FormLoader />
      </>
    );

  // console.log("ddddddddddddd", data);
  let sales, winnings, commission, profit;
  let dataSummary = {};
  sales = getSumFromObj(data?.totalSales);
  winnings = getSumFromObj(data?.totalWinnings);
  commission = getSumFromObj(data?.totalCommissions);
  profit = getSumFromObj(data?.totalProfits);

  dataSummary.sales = sales;
  dataSummary.winnings = winnings;
  dataSummary.commission = commission;
  dataSummary.profit = profit;

  return (
    <>
      <Box mb={2}>
        <ReportChart data={dataSummary} />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <AfrikenoTable data={data?.categories?.afrikeno} value={value} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Salary4LifeTable
            data={data?.categories["salary4life"]}
            value={value}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <LegendaryLottoTable
            data={data?.categories["legendarylotto"]}
            value={value}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <EazywinTable data={data?.categories["eazy-win"]} value={value} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FiveNinetyTable
            data={data?.categories[FIVE_OF_NINETY]}
            value={value}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Mega7Table data={data?.categories["mega-7"]} value={value} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <CashoutTable data={data?.categories[CASH_OUT]} value={value} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <MetroSalesTable data={data?.categories[METRO]} value={value} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Meta4SalesTable data={data?.categories[META4]} value={value} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <PerfectBallsTable
            data={data?.categories[PERFECT_BALLS]}
            value={value}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Six49Table data={data?.categories[SIX_FOUR_NINE]} value={value} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Seven49SalesTable
            data={data?.categories[SEVEN_FOUR_NINE]}
            value={value}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <LottoSalesTable data={data?.categories[LOTTO]} value={value} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <KenoSalesTable data={data?.categories[KENO]} value={value} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <PoolOfFameSales
            data={data?.categories[POOL_OF_FAME]}
            value={value}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <LottoContinentalTable
            data={data?.categories[LOTTO_CONTINENTAL]}
            value={value}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <MarioKenoTable data={data?.categories[MARIO_KENO]} value={value} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <BingoBallsTable data={data?.categories[BINGO_BALLS]} value={value} />
        </Grid>
      </Grid>
    </>
  );
};

export default DailySalesView;
