import jwtDecode from "jwt-decode";
import { createBrowserHistory } from "history";
import axios from "axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
const history = createBrowserHistory();
export const DAILY = "DAILY";
export const WEEKLY = "WEEKLY";
export const MONTHLY = "MONTHLY";

export const SALARY_FOR_LIFE = "salary4life";
export const MEGA_7 = "mega-7";
export const LEGENDARY_LOTTO = "legendarylotto";
export const FIVE_OF_NINETY = "5of90";
export const AFRIKENO = "afrikeno";
export const EAZY_WIN = "eazy-win";
export const EAZY_WIN_OVEROPTIONS = "eazy-win-over-bet-options";
export const EAZY_WIN_UNDEROPTIONS = "eazy-win-under-bet-options";

export const KENO = "keno";
export const KENO_OVEROPTIONS = "keno-over-bet-options";
export const KENO_UNDEROPTIONS = "keno-under-bet-options";
export const SIX_FOUR_NINE = "six49";
export const SIX49_BETOPTIONS = "six49-bet-options";
export const SIX49_OVEROPTIONS = "six49-over-bet-options";
export const SIX49_UNDEROPTIONS = "six49-under-bet-options";
export const SIX49_RESULT_OPTIONS = "six49-result-types";

export const SEVEN_FOUR_NINE = "seven49";
export const SEVEN49_BETOPTIONS = "seven49-bet-options";
export const SEVEN49_OVEROPTIONS = "seven49-over-bet-options";
export const SEVEN49_UNDEROPTIONS = "seven49-under-bet-options";

export const META4 = "meta4";
export const CASH_OUT = "cash-out";
export const PERFECT_BALLS = "perfect-balls";
export const LOTTO = "lotto";
export const METRO = "metro";
export const METRO_OVEROPTIONS = "metro-over-bet-options";
export const METRO_UNDEROPTIONS = "metro-under-bet-options";

export const INDOOR_GAME = "indoor-game";
export const INDOOR_BET_OPTIONS = "indoor-game-bet-options";
export const INDOOR_RESULT_OPTIONS = "indoor-game-result-types";
export const INDOOR_OVER_OPTIONS = "indoor-game-over-bet-options";
export const INDOOR_UNDER_OPTIONS = "indoor-game-under-bet-options";
export const INDOOR_BOOSTER_OPTIONS = "indoor-game-booster-options";

export const GHANA_GAME = "ghana-game";
export const GHANA_BET_OPTIONS = "ghana-game-bet-options";
export const GHANA_RESULT_OPTIONS = "ghana-game-result-types";
export const GHANA_OVER_OPTIONS = "ghana-game-over-bet-options";
export const GHANA_UNDER_OPTIONS = "ghana-game-under-bet-options";
export const GHANA_BOOSTER_OPTIONS = "ghana-game-booster-options";

export const POOL_OF_FAME = "pool-of-fame";
export const POOL_OF_FAME_BETOPTIONS = "pool-of-fame-bet-options";

export const LOTTO_CONTINENTAL = "lotto-continental";
export const LOTTO_CONTINENTAL_RESULT_OPTIONS =
  "lotto-continental-result-options";
export const LOTTO_CONTINENTAL_BET_OPTIONS = "lotto-continental-bet-options";

export const MARIO_KENO = "mario-keno";
export const MARIO_KENO_BETOPTIONS = "mario-keno-bet-options";
export const MARIO_KENO_OVER_OPTIONS = "mario-keno-over-bet-options";
export const MARIO_KENO_UNDER_OPTIONS = "mario-keno-under-bet-options";

export const BINGO_BALLS = "bingo-balls";
export const BINGO_BALLS_OVER_OPTIONS = "bingo-balls-over-bet-options";
export const BINGO_BALLS_UNDER_OPTIONS = "bingo-balls-under-bet-options";
export const BINGO_BALLS_BETOPTIONS = "bingo-balls-bet-options";

export const INFLUENCER = "influencer";
export const BUNDLE_BONUS = "bundle-bonus";
export const NORMAL_BONUS = "normal-bonus";

export const IMAGE_PREVIEW_WIDTH = 70;
export const IMAGE_PREVIEW_HEIGHT = 70;
export const imagePreviewStyle = {
  objectFit: "contain",
};
export const imageBox = {
  display: "flex",
  justifyContent: "space-between",
};
export const formatBonusType = (type = NORMAL_BONUS) => {
  if (type === BUNDLE_BONUS) return "Bundle Bonus";
  return "Normal Bonus";
};
export const getAuthToken = () => {
  setDefaultHeaders();
  const token = localStorage.getItem("token");
  return token;
};

export const checkExpiredAuthToken = () => {
  try {
    const decodedToken = jwtDecode(getAuthToken());

    const tokenExpired = decodedToken.exp < new Date().getTime() / 1000;

    if (tokenExpired) {
      // showToast("Your Login Session Have Expired", "info");
      console.log("Your Login Session Have Expired");

      localStorage.removeItem("token");
      history.push("/login", {
        location: history.location,
      });
      return (window.location.href = "/login");
    }
  } catch (error) {
    console.log("err", error);
    localStorage.removeItem("token");
    history.push("/login", {
      location: history.location,
    });
    return (window.location.href = "/login");
  }
};

export const logOutUser = () => {
  localStorage.removeItem("token");
  window.location.href = "/login";
};

export const labelStyles = {
  paddingLeft: 0,
  marginLeft: 0,
  marginBottom: "-9px",
  fontSize: "13.5px",
};
export const labelStylesTwo = {
  paddingLeft: 0,
  marginLeft: 0,
  marginBottom: "7.5px",
  fontSize: "13.5px",
};
export const labelStylesThree = {
  paddingLeft: 0,
  marginLeft: 0,
  marginBottom: "65px",
  fontSize: "13.5px",
};

export const seeMoreBoxStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "5px 0px",
};
export const csvBoxStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  marginBottom: "5px",
};

export const csvLinkStyle = { textDecoration: "none" };
export const menuItemsStyles = { fontSize: "12.7px" };
export const dialogLoaderStyles = { width: "330px", minWidth: "330px" };
export const headerStyles = { fontWeight: 600 };
export const previewSubTitleStyles = { fontWeight: 600 };
export const boldTextCalculationStyles = { fontWeight: 600 };
export const dialogMainTextStyles = { color: "black" };
export const previewDialogMainTitleStyle = {
  textAlign: "center",
  fontWeight: 600,
};
export const singleDetailsBoxStyle = {
  maxWidth: "500px",
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "1px solid #eee",
};

export const singleDetailsBoxStyleTwo = {
  maxWidth: "800px",
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "1px solid #eee",
};
export const singleDetailsBoxStyle3 = {
  idth: "100%",
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "1px solid #eee",
};
export const AddButtonBoxStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  marginBottom: "5px",
  marginTop: "5px",
};

export const tableButtonStyles = {
  textTransform: "capitalize",
  fontSize: "12px",
  padding: "2px",
};
export const detailsActionBoxStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

export const userRoles = [
  {
    title: "Ordinary User",
    value: "ordinary User",
  },
  {
    title: "First Class User",
    value: "first class user",
  },
  {
    title: "VIP User",
    value: "vip User",
  },
];

export const allAdminRoles = [
  {
    name: "generaladmin",
  },
  {
    name: "superadmin",
  },
  {
    name: "riskmanager",
  },
  {
    name: "mediamarketingadmin",
  },
  {
    name: "financeadmin",
  },
  {
    name: "customercareadmin",
  },
  {
    name: "accountmanager",
  },
  {
    name: "accountant",
  },
  {
    name: "superaccountant",
  },
];
export const agentRoles = [
  {
    title: "Cashier",
    value: "cashier",
  },
  {
    title: "Ordinary Agent",
    value: "ordinaryagent",
  },
  {
    title: "Super Agent",
    value: "superagent",
  },
  {
    title: "Principal Agent",
    value: "principalagent",
  },
];
export const BannerCategories = [
  {
    title: "Top-Desktop",
    value: "top-desktop",
  },
  {
    title: "Top-Mobile",
    value: "top-mobile",
  },
  {
    title: "* Left-Desktop *",
    value: "left-desktop",
  },
  {
    title: "Right-Desktop",
    value: "right-desktop",
  },

  {
    title: "Box-mobile",
    value: "box-mobile",
  },
  {
    title: "* Slider *",
    value: "slider",
  },
];
export const ProviderList = [
  {
    title: "InterSwitch",
    value: "interswitch",
  },
  {
    title: "PayStack",
    value: "paystack",
  },
  {
    title: "Source",
    value: "source",
  },
];
export const WeekdayList = [
  {
    title: "0 - Sunday",
    day: "Sunday",
    value: 0,
  },
  {
    title: "1 - Monday",
    day: "Monday",
    value: 1,
  },
  {
    title: "2 - Tuesday",
    day: "Tuesday",
    value: 2,
  },
  {
    title: "3 - Wednesday",
    day: "Wednesday",
    value: 3,
  },
  {
    title: "4 - Thursday",
    day: "Thursday",
    value: 4,
  },
  {
    title: "5 - Friday",
    day: "Friday",
    value: 5,
  },
  {
    title: "6 - Saturday",
    day: "Saturday",
    value: 6,
  },
];
export const limit = 20;

export const getWalletSum = (array) => {
  let initialValue = 0;

  for (let index = 0; index < array.length; index++) {
    let element = array[index].walletBalance;
    element = parseFloat(element);
    initialValue += element;
  }
  return initialValue.toFixed(2);
};

export const getCommisionSum = (array) => {
  let initialValue = 0;

  for (let index = 0; index < array.length; index++) {
    let element = array[index].commissionBalance;
    element = parseFloat(element);
    initialValue += element;
  }
  return initialValue.toFixed(2);
};

export const getTransactionSum = (array) => {
  let initialValue = 0;
  for (let index = 0; index < array.length; index++) {
    let element = array[index].amount;
    element = parseFloat(element);
    initialValue += element;
  }

  return initialValue.toFixed(2);
};

export const getOverdraftAmountSum = (array) => {
  let initialValue = 0;
  for (let index = 0; index < array.length; index++) {
    let element = array[index]?.initialAmount;
    element = parseFloat(element);
    initialValue += element;
  }

  return initialValue.toFixed(2);
};

export const getCommissionSalesSum = (array) => {
  let initialValue = 0;

  for (let index = 0; index < array.length; index++) {
    let element = array[index].totalSales;
    element = parseFloat(element);
    initialValue += element;
  }
  return initialValue.toFixed(2);
};
export const getCommissionSum = (array) => {
  let initialValue = 0;

  for (let index = 0; index < array.length; index++) {
    let element = array[index].totalCommission;
    element = parseFloat(element);
    initialValue += element;
  }
  return initialValue.toFixed(2);
};
export const getStakedAmountSum = (array) => {
  let initialValue = 0;

  for (let index = 0; index < array.length; index++) {
    let element = array[index].totalStakedAmount;
    element = parseFloat(element);
    initialValue += element;
  }
  return initialValue.toFixed(2);
};
export const getWinningAmountSum = (array) => {
  let initialValue = 0;

  for (let index = 0; index < array.length; index++) {
    let element = array[index].totalWinAmount || 0;
    element = parseFloat(element);
    initialValue += element;
  }
  return initialValue.toFixed(2);
};
export const getCommissionWalletSum = (array) => {
  let initialValue = 0;

  for (let index = 0; index < array.length; index++) {
    let element = array[index].walletBalance;
    element = parseFloat(element);
    initialValue += element;
  }
  return initialValue.toFixed(2);
};

export const getCommissionWonSum = (array) => {
  let initialValue = 0;

  for (let index = 0; index < array.length; index++) {
    let element = array[index].totalWon;
    element = parseFloat(element);
    initialValue += element;
  }
  return initialValue.toFixed(2);
};

// To safely JSON.parse a string without breaking the application
export const safeJSONParse = (str, expectedType = "array") => {
  let result = [];
  try {
    if (expectedType === "array") {
      result = JSON.parse(str);
      return result;
    }
    if (expectedType === "object") {
      result = {};
      result = JSON.parse(str);
      return result;
    }
  } catch (error) {
    console.log("err while parseing", error);
    return [];
  }
};

// Function to set default headers
export const setDefaultHeaders = () => {
  axios.defaults.headers.common["x-api-key"] = `${process.env.ADMIN_API_KEY}`;
};

export function currencyFormater(x, fixed = 0) {
  if (!x) {
    return 0;
  }
  let newX = parseFloat(x).toFixed(fixed);
  return newX.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getSumFromObj = (data) => {
  let total = 0;

  for (const item in data) {
    let elem = data[item];
    total += parseInt(elem);
  }

  return total;
};

export const formatGameDate = (time, addSeconds = false) => {
  if (time) {
    let splitedTime = time.split(":");
    let date = new Date();
    date.setHours(Number(splitedTime[0]) + 1);
    date.setMinutes(Number(splitedTime[1]));
    date.setSeconds(Number(splitedTime[2]));

    if (addSeconds) {
      return dayjs(date).utc().tz("Africa/Lagos").format("HH:mm:ss");
    }
    return dayjs(date).utc().tz("Africa/Lagos").format("HH:mm");
  }
};

export const getWindowQueryString = (name) => {
  let result = "";
  if (typeof window === "object") {
    const urlParams = new URLSearchParams(window.location.search);
    result = urlParams.get(name);
  }
  return result;
};
