/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import React from "react";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
export default function Notfound() {
  const history = useHistory();
  return (
    <AdminLayout>
      <MetaDecorator title="404 - Page Not Found" />

      <div className="container">
        <br />
        <br />
        <br />
        <br />
        <br />
        <center>
          <h3>Oops! Page not found or has been deleted</h3>
          <br />
          <br />
          <Button
            onClick={() => history.push("/")}
            variant="contained"
            color="error"
            size="lg"
          >
            &larr; Back to home
          </Button>
        </center>
      </div>
    </AdminLayout>
  );
}
