import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import { Formik, Form } from "formik";
import * as yup from "yup";
import axios from "axios";
import useSWR from "swr";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import { labelStyles, getAuthToken, checkExpiredAuthToken } from "../utils";
import { AppContext } from "../context";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";

const initialValues = {
  firstname: "",
  lastname: "",
  email: "",
  password: "",
  confirmPassword: "",
  role: "",
};
let adminSchema = yup.object().shape({
  firstname: yup.string().required().label("First name"),
  lastname: yup.string().required().label("Last name"),
  email: yup.string().email().required().label("Email"),
  password: yup.string().required(),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref("password"), null], "Both passwords must match")
    .label("Password"),
  role: yup.string().required(),
});

const url = `${process.env.API_URL}fetch-roles?userType=admin`;

function AddAdmin() {
  const { handleOpenSnackbar } = React.useContext(AppContext);

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.data?.data);
        return res?.data?.data?.data;
      });
  };
  const { data: roles, error } = useSWR(url, fetcher);

  if (error)
    return (
      <FullScreenError
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );

  if (!roles) return <FullScreenLoader />;

  const handleSubmit = (values, helpers) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    axios
      .post(`${process.env.API_URL}admin/signup`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        helpers.resetForm({});
        helpers.setSubmitting(false);
        handleOpenSnackbar({
          message: "Admin Created Successfully",
          severity: "success",
        });
      })
      .catch((err) => {
        console.log(err?.response?.data?.responsemessage?.msg);
        helpers.setSubmitting(false);
        if (err?.response?.data?.responsemessage?.msg) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage?.msg,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, Admin Could Not Be Created",
          severity: "error",
        });
      });
  };
  return (
    <AdminLayout>
      <MetaDecorator title="Add New Admin - Admin Dashboard" />
      <Container maxWidth="xl">
        <PageHeading title="Add New Admin" />

        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={adminSchema}
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={(values, helpers) => handleSubmit(values, helpers)}
          >
            {({
              handleChange,
              handleBlur,
              touched,
              errors,
              setFieldValue,
              isSubmitting,
            }) => (
              <Form noValidate>
                <Box my={1}>
                  <FormControl variant="outlined" fullWidth>
                    {/* <InputLabel variant="outlined" sx={labelStyles}>
                        Enter the first name
                      </InputLabel> */}
                    <TextField
                      label="Enter the first name"
                      error={touched.firstname && errors.firstname}
                      name="firstname"
                      variant="outlined"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.firstname && errors.firstname}
                    />
                  </FormControl>
                </Box>
                <Box my={1}>
                  <FormControl variant="outlined" fullWidth>
                    {/* <InputLabel variant="outlined" sx={labelStyles}>
                        Enter the last name
                      </InputLabel> */}
                    <TextField
                      label="Enter the last name"
                      error={touched.lastname && errors.lastname}
                      name="lastname"
                      variant="outlined"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.lastname && errors.lastname}
                    />
                  </FormControl>
                </Box>
                <Box my={1}>
                  <FormControl variant="outlined" fullWidth>
                    {/* <InputLabel variant="outlined" sx={labelStyles}>
                        Enter the email
                      </InputLabel> */}
                    <TextField
                      label="Enter the email"
                      error={touched.email && errors.email}
                      name="email"
                      type="email"
                      variant="outlined"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.email && errors.email}
                    />
                  </FormControl>
                </Box>
                <Box my={1}>
                  <FormControl variant="outlined" fullWidth>
                    {/* <InputLabel variant="outlined" sx={labelStyles}>
                        Enter the password
                      </InputLabel> */}
                    <TextField
                      label="Enter the password"
                      error={touched.password && errors.password}
                      name="password"
                      type="password"
                      variant="outlined"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.password && errors.password}
                    />
                  </FormControl>
                </Box>
                <Box my={1}>
                  <FormControl variant="outlined" fullWidth>
                    {/* <InputLabel variant="outlined" sx={labelStyles}>
                        Confirm the password
                      </InputLabel> */}
                    <TextField
                      label="Confirm the password"
                      error={touched.confirmPassword && errors.confirmPassword}
                      name="confirmPassword"
                      type="password"
                      variant="outlined"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                    />
                  </FormControl>
                </Box>
                <Box my={1}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={touched.role && errors.role}
                  >
                    <InputLabel variant="outlined" sx={labelStyles}>
                      Select Role
                    </InputLabel>
                    <Select
                      name="role"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      {roles.map((role) => (
                        <MenuItem key={role} value={role}>
                          {role}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {touched.role && errors.role}
                    </FormHelperText>
                  </FormControl>
                </Box>

                <br />
                <Button
                  startIcon={
                    isSubmitting && (
                      <CircularProgress
                        style={{ color: "white" }}
                        size={"1rem"}
                      />
                    )
                  }
                  type="submit"
                  size="large"
                  variant="contained"
                  color="success"
                >
                  {isSubmitting ? "Submitting" : " Add Admin"}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </AdminLayout>
  );
}

export default AddAdmin;
