import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import axios from "axios";
import { useSWR } from "swr";
import { useParams } from "react-router-dom";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import { AppContext } from "../context";
import { labelStyles, getAuthToken, checkExpiredAuthToken } from "../utils";
import Typography from "@mui/material/Typography";
import "../styles/addsiteconfig.scss";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";

function UpdateSiteSetting() {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [settingData, setSettingData] = React.useState({});

  const { settingId } = useParams();
  const url = `${process.env.API_URL}site-settings/fetch-setting/${settingId}`;

  const { handleOpenSnackbar } = React.useContext(AppContext);

  let initialValues = {
    title: "",
    slug: "",
    content: [{ name: "", value: "", number: "", description: "" }],
  };
  let siteSchema = yup.object().shape({
    title: yup.string().required().label("Title"),
    slug: yup.string().required().label("Slug"),
    content: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string().required("Required"),
          value: yup.string().required("Required"),
          number: yup.number(),
          description: yup.string(),
        })
      )
      .required("Settings Content is Required")
      .min(1, "Settings Content is Required"),
  });

  const handleSubmit = (values, helpers) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    values.content = JSON.stringify(values?.content);
    axios
      .put(
        `${process.env.API_URL}site-settings/update-setting/${settingId}`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        handleOpenSnackbar({
          message: "Site Settings Updated Successfully",
          severity: "success",
        });
        helpers.setSubmitting(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        handleOpenSnackbar({
          message: "Sorry, Site Settings Could Not Be Updated",
          severity: "error",
        });
        helpers.setSubmitting(false);
      });
  };

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res?.data?.data?.data);
        setSettingData(res?.data?.data?.data);
        return setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  React.useEffect(() => {
    fetcher();
  }, []);
  // const { data, error } = useSWR(url, fetcher);

  if (error)
    return (
      <FullScreenError
        title="Sorry, we can't retrieve the requested data this moment"
        subtitle={error.message}
      />
    );

  if (loading) return <FullScreenLoader />;

  if (!loading && settingData?.title) {
    let initialContents = JSON.parse(settingData?.content);

    initialValues.title = settingData?.title;
    (initialValues.slug = settingData?.slug),
      (initialValues.content = initialContents);
  }

  return (
    <AdminLayout>
      <MetaDecorator title="Update Site Setting - Admin" />
      <Container maxWidth="lg">
        <PageHeading title="Update Site Setting" />

        <Box my={1}>
          <Typography gutterBottom>
            To edit a site settings, fill every required fields, click on the
            add more button to add more setting fields, the display title,
            value, and number are required and finally on the update button to
            save the settings
          </Typography>
        </Box>
      </Container>
      <br />
      <Container maxWidth="lg">
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={siteSchema}
            enableReinitialize={true}
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={(values, helpers) => handleSubmit(values, helpers)}
          >
            {({
              handleChange,
              handleBlur,
              touched,
              errors,
              values,
              setFieldValue,
              isSubmitting,
            }) => (
              <Form noValidate>
                <Box my={1}>
                  <FormControl variant="outlined" fullWidth>
                    {/* <InputLabel variant="outlined" sx={labelStyles}>
                        Enter Setting's Title
                      </InputLabel> */}
                    <TextField
                      label="Enter Setting's Title"
                      error={touched.title && errors.title}
                      value={values?.title}
                      name="title"
                      variant="outlined"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.title && errors.title}
                    />
                  </FormControl>
                </Box>

                <Box my={2}>
                  <Typography gutterBottom>
                    Fill the Site Setting Contents
                  </Typography>
                  <FieldArray name="content">
                    {({ insert, remove, push }) => (
                      <div>
                        {values.content &&
                          values?.content?.length > 0 &&
                          Array.isArray(values?.content) &&
                          values?.content?.map((row, index) => (
                            <Grid container spacing={2} key={index}>
                              <Grid item xs={12} sm={6} md={3}>
                                <label style={labelStyles}>Display Title</label>
                                <Field
                                  className="fieldStyle"
                                  name={`content[${index}].name`}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  placeholder=""
                                  type="text"
                                />
                                <ErrorMessage
                                  name={`content.${index}.name`}
                                  component="div"
                                  className="field-error"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <label style={labelStyles}>Value</label>
                                <Field
                                  className="fieldStyle"
                                  name={`content[${index}].value`}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  placeholder=""
                                  type="text"
                                />
                                <ErrorMessage
                                  name={`content.${index}.value`}
                                  component="div"
                                  className="field-error"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={2}>
                                <label style={labelStyles}>Number</label>
                                <Field
                                  className="fieldStyle"
                                  name={`content[${index}].number`}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  placeholder=""
                                  type="number"
                                />
                                <ErrorMessage
                                  name={`content.${index}.number`}
                                  component="div"
                                  className="field-error"
                                />
                              </Grid>
                              <Grid item sm={6} md={3}>
                                <label style={labelStyles}>Description</label>
                                <Field
                                  className="fieldStyle"
                                  name={`content[${index}].description`}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  placeholder=""
                                  type="text"
                                />
                              </Grid>
                              <Grid item sm={6} md={1}>
                                <IconButton
                                  size="large"
                                  type="button"
                                  color="error"
                                  disabled={index === 0}
                                  onClick={() => {
                                    remove(index);
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          ))}
                        <br />
                        <Button
                          size="small"
                          type="button"
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            push({
                              name: "",
                              value: "",
                              number: "",
                              description: "",
                            })
                          }
                        >
                          Add Field
                        </Button>
                      </div>
                    )}
                  </FieldArray>
                </Box>

                <br />
                <Button
                  disabled={isSubmitting}
                  startIcon={isSubmitting && <CircularProgress size={"1rem"} />}
                  type="submit"
                  size="large"
                  variant="contained"
                  color="success"
                >
                  {isSubmitting ? "Submitting" : "Update"}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </AdminLayout>
  );
}

export default UpdateSiteSetting;
