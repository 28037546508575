import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import InputLabel from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import { Formik, Form } from "formik";
import * as yup from "yup";
import axios from "axios";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import useSWR, { mutate } from "swr";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";
import PageHeading from "../components/PageHeading/PageHeading";
import { AppContext } from "../context";
import { checkExpiredAuthToken, getAuthToken, labelStyles } from "../utils";
import FullScreenError from "../components/FullScreenError/FullScreenError";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";

let bonusSchema = yup.object().shape({
  title: yup.string().required(),
  depositRound: yup.string().required().label("Deposit Round"),
  gameType: yup.string().required().label("Game Type"),
  betType: yup.string().required().label("Bet Type"),
  expiration: yup.string().required().label("Expiration"),
  winCount: yup.number().positive().min(1).required().label("Win Count"),
  minimumDeposit: yup
    .number()
    .positive()
    .min(1)
    .required()
    .label("Minimum Deposit"),
  prize: yup.number().positive().min(1).required().label("Prize"),
  gamePlayCount: yup
    .number()
    .positive()
    .min(1)
    .required()
    .label("Game Play Count"),
});

function EditBonusConfiguration() {
  const [endTime, setEndTime] = React.useState(new Date());
  const { bonusId } = useParams();
  const { handleOpenSnackbar } = React.useContext(AppContext);

  const url = `${process.env.API_URL}bonus/fetch-bonus/${bonusId}`;

  const fetcher = () => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      });
  };

  let initialValues = {
    title: "",
    depositRound: "",
    winCount: 1,
    gameType: "",
    betType: "",
    expiration: "",
    minimumDeposit: 1,
    prize: 1,
    gamePlayCount: 1,
    description: "",
    type: "",
    quantity: 0,
    unitCost: 0,
    attachTo: "",
    quantityToAttach: 0,
  };

  const handleSubmit = (values, helpers) => {
    checkExpiredAuthToken();
    const token = getAuthToken();
    // values.expiration = dayjs(values?.expiration).format("DD-MM-YYYY");

    axios
      .put(`${process.env.API_URL}bonus/update-bonus/${bonusId}`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        helpers.setSubmitting(false);
        helpers.resetForm(initialValues);
        mutate(url);
        if (res?.data?.data?.message) {
          return handleOpenSnackbar({
            message: res?.data?.data?.message,
            severity: "success",
          });
        }
        return handleOpenSnackbar({
          message: "Bonus Updated Successfully",
          severity: "success",
        });
      })
      .catch((err) => {
        console.log(err?.response?.data?.responsemessage);
        helpers.setSubmitting(false);
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        if (err?.response?.data?.responsemessage) {
          return handleOpenSnackbar({
            message: err?.response?.data?.responsemessage,
            severity: "error",
          });
        }
        handleOpenSnackbar({
          message: "Sorry, Bonus Could Not Be Updated",
          severity: "error",
        });
      });
  };

  const { data, error } = useSWR(url, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  if (error)
    return (
      <FullScreenError
        title=" Sorry, bonus cannot be retrieved at this momment"
        subtitle={error.message}
      />
    );

  if (!data) return <FullScreenLoader />;

  if (data?.title) {
    initialValues.title = data?.title;
    initialValues.depositRound = data?.depositRound;
    initialValues.winCount = data?.winCount;
    initialValues.gameType = data?.gameType;
    initialValues.betType = data?.betType;
    initialValues.expiration = data?.expiration;
    initialValues.minimumDeposit = data?.minimumDeposit;
    initialValues.prize = data?.prize;
    initialValues.gamePlayCount = data?.gamePlayCount;
    initialValues.description = data?.description;
    initialValues.type = data?.type;
    // initialValues.attachTo = data?.attachTo;
    initialValues.quantity = data?.quantity;
    initialValues.unitCost = data?.unitCost;
    // initialValues.quantityToAttach = data?.quantityToAttach;
  }

  return (
    <AdminLayout>
      <MetaDecorator title="Update New Bonus Configuration - Admin" />
      <Container maxWidth="xl">
        <PageHeading title="Update Bonus Configuration" />
        <Container maxWidth="xl">
          <div>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={bonusSchema}
              validateOnBlur={true}
              validateOnChange={true}
              //   onSubmit={(values) => console.log(values)}
              onSubmit={(values, helpers) => handleSubmit(values, helpers)}
            >
              {({
                handleChange,
                handleBlur,
                touched,
                errors,
                setFieldValue,
                isSubmitting,
                values,
              }) => (
                <Form noValidate>
                  <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStyles}>
                        Enter Bonus Title
                      </InputLabel>
                      <TextField
                        error={touched.title && errors.title}
                        value={values.title}
                        name="title"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.title && errors.title}
                      />
                    </FormControl>
                  </Box>
                  {/* <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStyles}>
                        Deposit Round
                      </InputLabel>
                      <TextField
                        name="depositRound"
                        value={values.depositRound}
                                             variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={touched.depositRound && errors.depositRound}
                        helperText={touched.depositRound && errors.depositRound}
                      />
                    </FormControl>
                  </Box> */}
                  {/* <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStyles}>
                        Game Type
                      </InputLabel>
                      <TextField
                        name="gameType"
                        value={values.gameType}
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={touched.gameType && errors.gameType}
                        helperText={touched.gameType && errors.gameType}
                      />
                    </FormControl>
                  </Box> */}
                  {/* <Box my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStyles}>
                        Bet Type
                      </InputLabel>
                      <TextField
                        name="betType"
                        value={values.betType}
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={touched.betType && errors.betType}
                        helperText={touched.betType && errors.betType}
                      />
                    </FormControl>
                  </Box> */}
                  <Box mt={2} mb={1}>
                    <Grid container spacing={2}>
                      <Grid xs={12} sm={6} item>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel variant="outlined" sx={labelStyles}>
                            Minimum Deposit
                          </InputLabel>
                          <TextField
                            name="minimumDeposit"
                            value={values.minimumDeposit}
                            type="number"
                            variant="outlined"
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={
                              touched.minimumDeposit && errors.minimumDeposit
                            }
                            helperText={
                              touched.minimumDeposit && errors.minimumDeposit
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid xs={12} sm={6} item>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel variant="outlined" sx={labelStyles}>
                            Prize
                          </InputLabel>
                          <TextField
                            name="prize"
                            value={values.prize}
                            type="number"
                            variant="outlined"
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={touched.prize && errors.prize}
                            helperText={touched.prize && errors.prize}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={2} mb={2}>
                    <Grid container spacing={2}>
                      <Grid xs={12} sm={6} item>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel variant="outlined" sx={labelStyles}>
                            Win Count
                          </InputLabel>
                          <TextField
                            name="winCount"
                            value={values.winCount}
                            type="number"
                            variant="outlined"
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={touched.winCount && errors.winCount}
                            helperText={touched.winCount && errors.winCount}
                          />
                        </FormControl>
                      </Grid>
                      <Grid xs={12} sm={6} item>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel variant="outlined" sx={labelStyles}>
                            Game Play Count
                          </InputLabel>
                          <TextField
                            name="gamePlayCount"
                            value={values.gamePlayCount}
                            type="number"
                            variant="outlined"
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={
                              touched.gamePlayCount && errors.gamePlayCount
                            }
                            helperText={
                              touched.gamePlayCount && errors.gamePlayCount
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                  {values.type === "bundle-bonus" ? (
                    <>
                      <Box mt={2} mb={2}>
                        <Grid container spacing={2}>
                          <Grid xs={12} sm={6} item>
                            <FormControl variant="outlined" fullWidth>
                              <TextField
                                label="Bundle Quantity"
                                value={values.quantity}
                                name="quantity"
                                type="number"
                                variant="outlined"
                                fullWidth
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={touched.quantity && errors.quantity}
                                helperText={touched.quantity && errors.quantity}
                              />
                            </FormControl>
                          </Grid>
                          <Grid xs={12} sm={6} item>
                            <FormControl variant="outlined" fullWidth>
                              <TextField
                                label="Unit Cost"
                                value={values.unitCost}
                                name="unitCost"
                                type="number"
                                variant="outlined"
                                fullWidth
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={touched.unitCost && errors.unitCost}
                                helperText={touched.unitCost && errors.unitCost}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Box>
                      {/* <Box mt={2} my={1}>
                        <Grid container spacing={2}>
                          <Grid xs={12} sm={6} item>
                            <FormControl variant="outlined" fullWidth>
                              <TextField
                                label="ID of Influencer"
                                value={values.attachTo}
                                name="attachTo"
                                variant="outlined"
                                fullWidth
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={touched.attachTo && errors.attachTo}
                                helperText={touched.attachTo && errors.attachTo}
                              />
                            </FormControl>
                          </Grid>
                          <Grid xs={12} sm={6} item>
                            <FormControl variant="outlined" fullWidth>
                              <TextField
                                label="Number of Quantity To Attach"
                                value={values.quantityToAttach}
                                name="quantityToAttach"
                                type="number"
                                variant="outlined"
                                fullWidth
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={
                                  touched.quantityToAttach &&
                                  errors.quantityToAttach
                                }
                                helperText={
                                  touched.quantityToAttach &&
                                  errors.quantityToAttach
                                }
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Box> */}
                    </>
                  ) : null}
                  <Box mt={2} my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStyles}>
                        Bonus Expiration Period (In Days)
                      </InputLabel>
                      <TextField
                        value={values.expiration}
                        name="expiration"
                        type="number"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={touched.expiration && errors.expiration}
                        helperText={touched.expiration && errors.expiration}
                      />
                    </FormControl>
                  </Box>
                  <Box mt={2} my={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel variant="outlined" sx={labelStyles}>
                        Description
                      </InputLabel>
                      <TextField
                        multiline
                        name="description"
                        variant="outlined"
                        fullWidth
                        value={values.description}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={touched.description && errors.description}
                        helperText={touched.description && errors.description}
                      />
                    </FormControl>
                  </Box>
                  <br />
                  <Button
                    startIcon={
                      isSubmitting && (
                        <CircularProgress severity="success" size={"1rem"} />
                      )
                    }
                    disabled={isSubmitting}
                    type="submit"
                    size="large"
                    variant="contained"
                    color="success"
                  >
                    {isSubmitting ? "Updating Bonus" : "Update Bonus"}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </Container>
    </AdminLayout>
  );
}

export default EditBonusConfiguration;
