import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import NumberFormat from "react-number-format";
import NoDataAvailable from "../../EmptyTable/EmptyTable";
import { downloadBoxStyles, headCellsTwo, renderDate } from "../data";
import DailySalesTotalRow from "./DailySalesTotalRow";
import DownloadCSVButton from "../DownloadCSVButton";
import { currencyFormater } from "../../../utils";

function LottoSalesTable({ data, value }) {
  return (
    <Box sx={{ padding: "10px 2px", marginBottom: "10px" }} component={Paper}>
      <Box mb={2} sx={downloadBoxStyles}>
        {data?.length > 0 && <DownloadCSVButton data={data} />}
      </Box>
      <Typography
        align="center"
        variant="h6"
        gutterBottom
        sx={{ color: "green" }}
      >
        LOTTO SALES REPORT
      </Typography>
      <TableContainer>
        <Table size="small" sx={{ minWidth: 300 }} aria-label="sales table">
          <TableHead>
            <TableRow>
              <TableCell
                component="th"
                sx={{
                  fontWeight: 600,
                }}
              >
                {renderDate(value)}
              </TableCell>
              {headCellsTwo.map((item, idx) => (
                <TableCell
                  key={item}
                  component="th"
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.length > 0 &&
              data?.map((row, idx) => (
                <TableRow key={`${idx}mmm`}>
                  <TableCell>{row?.createdOn}</TableCell>
                  <TableCell>
                    &#8358; {currencyFormater(row?.totalSold)}
                  </TableCell>
                  <TableCell>{row?.ticketCount}</TableCell>
                  <TableCell>
                    &#8358; {currencyFormater(row?.totalCommission)}
                  </TableCell>
                  <TableCell>{row?.commissionCount}</TableCell>
                  <TableCell>
                    &#8358; {currencyFormater(row?.totalWon)}
                  </TableCell>
                  <TableCell>&#8358;{currencyFormater(row?.profit)}</TableCell>
                </TableRow>
              ))}
            {data && data?.length > 0 && <DailySalesTotalRow data={data} />}
          </TableBody>
        </Table>
        {data && data?.length <= 0 && <NoDataAvailable />}
      </TableContainer>
    </Box>
  );
}

export default LottoSalesTable;
