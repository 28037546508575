/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import React from "react";
import { useTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AdminLayout from "../components/AdminLayout/AdminLayout";
import DashboardStatsCard from "../components/DashboardComponents/DashboardStatsCard";
import RecentUsers from "../components/DashboardComponents/RecentUsers";
import RecentGames from "../components/DashboardComponents/RecentGames";
import MetaDecorator from "../components/MetaDecorator/MetaDecorator";

export default function Dashboard() {
  const theme = useTheme();

  const headerStyles = {
    color: theme.palette.success.main,
    fontWeight: 600,
  };
  return (
    <AdminLayout>
      <MetaDecorator title="Admin Dashboard" />
      <Container maxWidth="xl">
        <DashboardStatsCard tickets={10} players={5} />
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <RecentUsers headerStyles={headerStyles} />
          </Grid>
          <Grid item xs={12} md={6}>
            <RecentGames headerStyles={headerStyles} />
          </Grid>
        </Grid>
      </Container>
    </AdminLayout>
  );
}
