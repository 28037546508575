import React from "react";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import TransactionsTable from "./TransactionsTable";
import TransactionsFilter from "./TransactionsFilter";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { getAuthToken, checkExpiredAuthToken } from "../../../utils";
import ClearFilters from "../../ClearFilters/ClearFilters";
import PreviewTransactionModal from "./PreviewTransactionModal";
import dayjs from "dayjs";
import ReverseFundDialog from "./ReverseFundDialog";
import HalfErrorScreen from "../../HalfErrorScreen/HalfErrorScreen";
import TableLoader from "../../TableLoader/TableLoader";

function Transactions() {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [status, setStatus] = React.useState("");
  const [transactionName, setTransactionName] = React.useState("");
  const [transactionType, setTransactionType] = React.useState("");
  const [isFiltering, setIsFiltering] = React.useState(false);
  const [hasFiltered, setHasFiltered] = React.useState(false);
  const [openReverseFundDialog, setOpenReverseFundDialog] =
    React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedTrans, setSelectedTrans] = React.useState({});
  const [showFilter, setShowFilter] = React.useState(false);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [minAmount, setMinAmount] = React.useState(0);
  const [maxAmount, setMaxAmount] = React.useState(0);

  let filterURL = `${process.env.API_URL}transactions/fetch-admin-transactions?limit=${rowsPerPage}&page=${page}`;

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const rowsPerPageOptions = [20, 50, 100, 200];

  // SessionStorage  Variable Declarations

  let sStatus = "status";
  let sTransactionName = "transactionName";
  let sTransactionType = "transactionType";
  let sStartDate = "startDate";
  let sEndDate = "endDate";
  let sMinAmount = "minAmount";
  let sMaxAmount = "maxAmount";

  // change methods for search filters
  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
    sessionStorage.setItem(sStatus, e.target.value);
  };

  const handleChangeTicketName = (e) => {
    setTransactionName(e.target.value);
    sessionStorage.setItem(sTransactionName, e.target.value);
  };

  const handleChangeTransactionType = (e) => {
    setTransactionType(e.target.value);
    sessionStorage.setItem(sTransactionType, e);
  };

  const handleChangeStartDate = (value) => {
    setStartDate(value);
    sessionStorage.setItem(sStartDate, value);
  };
  const handleChangeEndDate = (value) => {
    setEndDate(value);
    sessionStorage.setItem(sEndDate, value);
  };

  const handleChangeMinimumBal = (e) => {
    setMinAmount(e.target.value);
    sessionStorage.setItem(sMinAmount, e.target.value);
  };

  const handleChangeMaximumBal = (e) => {
    setMaxAmount(e.target.value);
    sessionStorage.setItem(sMaxAmount, e.target.value);
  };

  const handleClearSessionStorage = () => {
    sessionStorage.removeItem(sStatus);
    sessionStorage.removeItem(sTransactionName);
    sessionStorage.removeItem(sTransactionType);
    sessionStorage.removeItem(sStartDate);
    sessionStorage.removeItem(sEndDate);
    sessionStorage.removeItem(sMinAmount);
    sessionStorage.removeItem(sMaxAmount);
  };

  const handleSetSessionStorage = () => {
    let sSStatus = sessionStorage.getItem(sStatus);
    if (sSStatus) {
      setStatus(sSStatus);
      setHasFiltered(true);
    }
    let sSTransactionName = sessionStorage.getItem(sTransactionName);
    if (sSTransactionName) {
      setTransactionName(sSTransactionName);
      setHasFiltered(true);
    }
    let sSTransactionType = sessionStorage.getItem(sTransactionType);
    if (sSTransactionType) {
      setTransactionType(sSTransactionType);
      setHasFiltered(true);
    }
    let sSStartDate = sessionStorage.getItem(sStartDate);
    if (sSStartDate) {
      setStartDate(sSStartDate);
      setHasFiltered(true);
    }
    let sSEndDate = sessionStorage.getItem(sEndDate);
    if (sSEndDate) {
      setEndDate(sSEndDate);
      setHasFiltered(true);
    }
    let sSMinAmount = sessionStorage.getItem(sMinAmount);
    if (sSMinAmount) {
      setMinAmount(sSMinAmount);
      setHasFiltered(true);
    }
    let sSMaxAmount = sessionStorage.getItem(sMaxAmount);
    if (sSMaxAmount) {
      setMaxAmount(sSMaxAmount);
      setHasFiltered(true);
    }

    if (transactionName !== "") {
      if (!filterURL.includes(sTransactionName)) {
        filterURL = filterURL + `&searchId=${transactionName}`;
      }
    }

    if (status !== "") {
      if (!filterURL.includes(sStatus)) {
        filterURL = filterURL + `&status=${status}`;
      }
    }
    if (transactionType !== "") {
      if (!filterURL.includes(sTransactionType)) {
        filterURL = filterURL + `&transactionType=${transactionType}`;
      }
    }
    if (minAmount) {
      if (!filterURL.includes(sMinAmount)) {
        filterURL = filterURL + `&minAmount=${minAmount}`;
      }
    }
    if (maxAmount) {
      if (!filterURL.includes(sMaxAmount)) {
        filterURL = filterURL + `&maxAmount=${maxAmount}`;
      }
    }
    if (startDate) {
      if (!filterURL.includes(sStartDate)) {
        filterURL =
          filterURL + `&startDate=${dayjs(startDate).format("DD/MM/YYYY")}`;
      }
    }
    if (endDate) {
      if (!filterURL.includes(sEndDate)) {
        filterURL =
          filterURL + `&endDate=${dayjs(endDate).format("DD/MM/YYYY")}`;
      }
    }
  };
  const handleSelectTransaction = (trans) => setSelectedTrans(trans);
  const handleCloseDialog = () => setOpenDialog(false);
  const handleOpenDialog = () => setOpenDialog(true);

  const handleOpenReverseFundsDialog = () => setOpenReverseFundDialog(true);
  const handleCloseReverseFundDialog = () => setOpenReverseFundDialog(false);
  const handleChangeFilter = (event) => setShowFilter(event.target.checked);

  React.useEffect(() => {
    handleSetSessionStorage();
    return () => {
      handleClearSessionStorage();
    };
  }, []);
  const url = `${process.env.API_URL}transactions/fetch-transactions?page=${page}&limit=${rowsPerPage}`;

  const handleResetFields = () => {
    handleClearSessionStorage();
    setStatus((prev) => "");
    setTransactionName((prev) => "");
    setStartDate(null);
    setEndDate(null);
    setMinAmount(0);
    setMaxAmount(0);
  };

  const fetcher = () => {
    handleSetSessionStorage();
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data;
      });
  };

  const handleFiterSubmit = () =>
    mutate(filterURL, async () => {
      try {
        handleSetSessionStorage();
        checkExpiredAuthToken();
        const token = getAuthToken();
        setPage(1);
        setIsFiltering(true);
        const res = await axios.get(filterURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setIsFiltering(false);
        setHasFiltered(true);
        // await mutate(filterURL, res.data?.data);
        return res.data?.data;
      } catch (err) {
        setIsFiltering(false);
        return err;
      }
    });

  const handleChangePage = (event, newPage) => {
    setIsFiltering(true);
    handleSetSessionStorage();
    setPage(newPage + 1);
    checkExpiredAuthToken();
    const token = getAuthToken();
    return axios
      .get(filterURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsFiltering(false);
        mutate(filterURL, res?.data?.data);
      })
      .catch((err) => {
        setIsFiltering(false);
        console.log(err);
      });
  };

  // const handleCancelFilters = () => {
  //   handleClearSessionStorage();
  //   handleResetFields();
  //   setHasFiltered(false);
  //   checkExpiredAuthToken();
  //   const token = getAuthToken();
  //   return axios
  //     .get(url, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((res) => {
  //       return mutate(url, res.data?.data, false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const handleCancelFilters = React.useCallback(() => {
    mutate(filterURL, async (trans) => {
      handleClearSessionStorage();
      handleResetFields();
      setHasFiltered(false);
      checkExpiredAuthToken();
      const token = getAuthToken();
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return await res.data?.data;
    });
  }, []);
  // const handleCancelFilters = async () =>
  //   mutate(filterURL, async (trans) => {
  //     handleClearSessionStorage();
  //     handleResetFields();
  //     setHasFiltered(false);
  //     checkExpiredAuthToken();
  //     const token = getAuthToken();
  //     const res = await axios.get(url, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     console.log("yyyyyyyyy", res.data?.data);

  //     return await res.data?.data;
  //   });
  const { data, error } = useSWR(filterURL, fetcher);

  if (error)
    return (
      <HalfErrorScreen
        title=" Sorry, data cannot be retrieved at this moment"
        subtitle={error.message}
      />
    );

  if (!data) return <TableLoader />;
  return (
    <Container maxWidth="xl">
      {openReverseFundDialog ? (
        <ReverseFundDialog
          openDialog={openReverseFundDialog}
          handleCloseDialog={handleCloseReverseFundDialog}
          selectedTransaction={selectedTrans}
          transactionsURL={filterURL}
        />
      ) : null}
      {openDialog ? (
        <PreviewTransactionModal
          openDialog={openDialog}
          selectedTransaction={selectedTrans}
          handleCloseDialog={handleCloseDialog}
        />
      ) : null}

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <>{hasFiltered && <ClearFilters handleClick={handleCancelFilters} />}</>
        <FormControlLabel
          sx={{ marginLeft: "auto" }}
          control={
            <Switch
              color="success"
              checked={showFilter}
              onChange={handleChangeFilter}
              inputProps={{ "aria-label": "Show Filters" }}
            />
          }
          label="Show Filters"
        />
      </Box>
      {showFilter && (
        <TransactionsFilter
          status={status}
          transactionName={transactionName}
          transactionType={transactionType}
          startDate={startDate}
          endDate={endDate}
          minAmount={minAmount}
          maxAmount={maxAmount}
          handleChangeStartDate={handleChangeStartDate}
          handleChangeEndDate={handleChangeEndDate}
          handleChangeMinimumBal={handleChangeMinimumBal}
          handleChangeMaximumBal={handleChangeMaximumBal}
          handleChangeStatus={handleChangeStatus}
          handleChangeTicketName={handleChangeTicketName}
          handleChangeTransactionType={handleChangeTransactionType}
          handleFiterSubmit={handleFiterSubmit}
        />
      )}
      <TransactionsTable
        status={status}
        isFiltering={isFiltering}
        hasFiltered={hasFiltered}
        rowsPerPageOptions={rowsPerPageOptions}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSelectTransaction={handleSelectTransaction}
        handleOpenReverseFundsDialog={handleOpenReverseFundsDialog}
        transactionName={transactionName}
        transactionType={transactionType}
        handleOpenDialog={handleOpenDialog}
        page={page}
        rowsPerPage={rowsPerPage}
        data={data?.data}
        totalCount={data?.totalCount}
      />
    </Container>
  );
}

export default Transactions;
